import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import PageHeader from "@Components/PageHeader";
import { SearchFailed } from "@Components/form/FormSearch";
import { PatientItem } from "@Interface/patient";
import { PaginationExcludeTotal } from "@Interface/service";
import { GetVisitSelectProgramAPI } from "@Services/api/visit";
import { rowClassName } from "@Utils/utils";
import { _RES_validateError } from "@Utils/validate";
import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Pagination, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

type Props = {};

const Program = (props: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalPatientList, setTotalPatientList] = useState<number>(0); //200
  const [patientList, setPatientList] = useState<PatientItem[] | []>([]);
  const [pagination, setPagination] = useState<PaginationExcludeTotal>({
    page: 1,
    perpage: 10,
    startIndex: 1,
  });
  useEffect(() => {
    getPatientList();
    return () => {};
  }, [pagination]);
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const getPatientList = async (search?: SearchFailed) => {
    try {
      setIsLoading(true);
      const keyOnLoad = "getPatientList";
      const dataSearch = {
        page: pagination.page,
        limit: pagination.perpage,
        ...search,
      };
      const res = await GetVisitSelectProgramAPI(
        dataSearch,
        token["accessToken"]
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setPatientList(res.result[0]["data"]);
            setTotalPatientList(res.result[0]["total"]);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const viewVisitById = (id: number) => {
    navigate("/program/view-program/" + id);
  };
  const columns = [
    {
      title: "#",
      key: "id",
      width: 50,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{rowIndex + 1}</span>
      ),
    },
    {
      title: "เลขบัตรประชาชน",
      dataIndex: "idCard",
      key: "idCard",
    },
    {
      title: "เลข HN",
      dataIndex: "hn",
      key: "hn",
    },
    {
      title: "ชื่อ - นามสกุล",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "อายุ",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "จัดการ",
      key: "action",
      width: 250,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <Space>
          <Button
            type="ghost"
            className="flex-center btn-action"
            onClick={() => viewVisitById(record.id)}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <Row>
      <Col span={24}>
        <PageHeader title={"เลือกโปรแกรม"} subTitle="รายการเลือกโปรแกรม" />
      </Col>
      <Col span={24} className="content">
        {/* <FormSearch /> */}
        <Table
          size="small"
          rowKey="id"
          loading={isLoading}
          className="abus-table"
          pagination={false}
          rowClassName={rowClassName}
          scroll={{ x: 1300 }}
          dataSource={patientList}
          columns={columns}
        />
        <Row justify="end" style={{ marginTop: 20 }}>
          <Col>
            {totalPatientList / pagination.perpage > 1 && (
              <Pagination
                className="abus-pagination"
                total={totalPatientList}
                onChange={(page: number, pageSize: number) => {
                  setPagination({
                    ...pagination,
                    page: page,
                    perpage: pageSize,
                    startIndex: page * pageSize,
                  });
                }}
                defaultCurrent={pagination.page}
                defaultPageSize={pagination.perpage}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default Program;
