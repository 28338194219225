import Sider from 'antd/es/layout/Sider';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';
import pLimit from 'p-limit';
import React, { useEffect, useState } from 'react';
// Initialize cornerstone
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.configure({
  useWebWorkers: true,
});

interface Series {
  id: string;
  imageUrl: string;
  maxFrames?: number;
}

interface DicomState {
  id: string;
  loaded: boolean;
  image: string;
  imageId: string;
  maxFrames: number;
}

interface SidebarProps {
  series: Series[];
  onSelectSeries: (selectedSeries: any) => void;
  visible: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ series, onSelectSeries, visible }) => {
  const [dicomImages, setDicomImages] = useState<DicomState[]>([]);

  useEffect(() => {
    if (dicomImages.length === 0 && visible === true) {
      const initState = series.map((s) => {
        const format: DicomState = {
          id: s.id,
          imageId: '',
          loaded: true,
          image: '',
          maxFrames: 0,
        };
        return format;
      });
      setDicomImages(initState);
      fetchThumbnails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series, visible]);

  const fetchThumbnails = async () => {
    const limit = pLimit(2);
    const seriesPromises = async (s: Series): Promise<DicomState> => {
      const imageId = `wadouri:${s.imageUrl}`;
      const image = await cornerstone.loadAndCacheImage(imageId);
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      cornerstone.renderToCanvas(canvas, image);
      let maxFrames = 320;
      if (image) {
        const newImage: any = image;
        maxFrames = newImage.data.string('x00280008');
      }
      const format: DicomState = {
        id: s.id,
        loaded: false,
        imageId: imageId,
        image: canvas.toDataURL(),
        maxFrames: maxFrames,
      };
      setDicomImages((prevDicomImages: DicomState[]) =>
        prevDicomImages.map((dicom: DicomState) => {
          if (dicom.id === format.id) {
            dicom = format;
          }
          return dicom;
        })
      );
      return format;
    };
    await Promise.all(series.map((s) => limit(async () => seriesPromises(s))));
  };

  const siderStyle: React.CSSProperties = {
    overflow: 'auto',
    height: '70vh',
    // position: "fixed",
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    scrollbarWidth: 'thin',
    scrollbarColor: 'unset',
    backgroundColor: '#001529',
  };

  return (
    <Sider style={siderStyle}>
      {dicomImages.map((s) => (
        <div
          style={{ padding: '10px' }}
          key={s.id}
          onClick={() =>
            onSelectSeries({
              imageId: s?.imageId,
              maxFrames: s?.maxFrames,
              id: s?.id,
            })
          }
        >
          {s.loaded ? (
            <div
              style={{
                backgroundColor: 'white',
                padding: '4px',
                borderRadius: '5px',
              }}
            >
              <div
                style={{
                  height: '97px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'black',
                  borderRadius: '5px',
                }}
              >
                <p style={{ color: 'white' }}>Loading...</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'black',
                }}
              >
                {`Series ${s.id}`}
              </div>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: 'white',
                padding: '4px',
                borderRadius: '5px',
              }}
            >
              <img style={{ width: '100%' }} src={s?.image} alt={`Series ${s.id}`} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'black',
                }}
              >
                {`Series ${s.id}`}
              </div>
            </div>
          )}
        </div>
      ))}
    </Sider>
  );
};

export default Sidebar;
