import { Col, Row, InputNumber as Input, Form, FormInstance } from "antd";
import { _CustomItemQuestionItem } from "../../interface";
import "./styles/index.less";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  form?: FormInstance<any>;
  values: _CustomItemQuestionItem;
  disabled?: boolean;
  keyLoop?: any;
  isFormList?: string | number;
}

const InputNumber = ({ values, disabled, isFormList, keyLoop }: Props) => {
  const html = values["description"] || ``;
  const propsInputNumber = values.propsInputNumber;
  return (
    <div>
      <Row gutter={[6, 6]} align="middle">
        <Col span={24}>
          <Form.Item
            label={values["title"] || "ใส่ข้อมูลตัวเลข"}
            name={isFormList ? [isFormList, values["keyName"]] : values["keyName"]}
            rules={[
              {
                required: !!values.required,
                message: `กรุณาใส่ข้อมูล ${values["title"]}`,
              },
            ]}
          >
            <Input<number>
              min={propsInputNumber?.min}
              max={propsInputNumber?.max}
              disabled={disabled}
              addonAfter={values["unit"] || "ใส่ข้อมูลตัวเลข"}
              style={propsInputNumber?.style}
            />
          </Form.Item>
        </Col>
      </Row>
      {!!values["description"] && (
        <span dangerouslySetInnerHTML={{ __html: html }} />
      )}
    </div>
  );
};

export default InputNumber;

// ------ ex. --------

//  {
//   title: "ระยะเวลา",
//   keyName: "question14",
//   type: "inputNumber",
//   propsInputNumber: {
//     min: 0,
//     max: 100,
//     style: { width: "100%" },
//   },
//   unit: "เดือน",
// }
