import { Fetch, FetchFormData } from "@Utils/fetch/fetch";


export const getReceiptbyAPI = async <T = any>(
    id: number,
    token: string
) => {
    const res = await Fetch<T>({
        token: token,
        method: "GET",
        path: `/v1/receipt/${id}`,
    });
    return res;
};

export const CreateNewReceiptAPI = async <T = any>(
    data: any,
    token: string
) => {
    const res = await Fetch<T>(
        {
            token: token,
            method: "POST",
            path: "/v1/receipt",
            data,
        },
    );
    return res;
};


