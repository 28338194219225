import { BiLogOut } from "react-icons/bi";
import { RiProfileFill } from "react-icons/ri";
import { DownOutlined } from "@ant-design/icons";
import { useAuthContextState } from "@AuthContext/store";
import { useAuthContextDispatch } from "@AuthContext/store";
import { Menu, Dropdown, Avatar, Image, Col, Row, Space } from "antd";
import { useState } from "react";
import DrawerProfile from "@Components/DrawerProfile";

type Props = {};

const Profile = (props: Props) => {
  const { userInfo } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [onDrawer, setOnDrawer] = useState<boolean>(false);

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => setOnDrawer(true)}>
        <Space size="middle">
          <RiProfileFill className="color-primary" />
          <span>โปรไฟล์</span>
        </Space>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => _signOut()}>
        <Space size="middle">
          <BiLogOut className="color-primary" />
          <span>ออกจากระบบ</span>
        </Space>
      </Menu.Item>
    </Menu>
  );

  const onClickClose = (data: boolean) => {
    setOnDrawer(data);
  };
  return (
    <>
      <DrawerProfile onDrawer={onDrawer} onClickClose={onClickClose} />
      <Dropdown overlay={menu} trigger={["click"]}>
        <Row className="dropdown-profile">
          <Col style={{ marginRight: 20 }}>
            <Avatar
              // style={{ paddingRight: 10, width: 48 }}
              src={
                <Image
                  border-radius="50%"
                  preview={false}
                  src={`${userInfo.image || "/images/ProfileDefualt.svg"}`}
                />
              }
            />
          </Col>
          <Col className="flex-center profile">
            <Row
              style={{ paddingRight: 10 }}
              className="profile"
              align="middle"
            >
              <Col span={24} className="name" style={{ fontWeight: "bold" }}>
                {userInfo.fullName || ""}
              </Col>
              <Col span={24} className="role">
                {userInfo.role || ""}
              </Col>
            </Row>
          </Col>
          <Col>
            <DownOutlined />
          </Col>
        </Row>
      </Dropdown>
    </>
  );
};

export default Profile;
