import { Col, Row, Typography } from "antd";
import React from "react";
import "./styles/index.less";

const { Title, Text } = Typography;
type Props = {
  title: any;
  subTitle?: string | React.ReactNode;
  breadcumb?: string | React.ReactNode;
  setLayout?: any;
  action?: React.ReactNode | React.ReactNode[];
  status?: string;
  back?: any;
};

const PageHeader = (props: Props) => {
  return (
    <Row
      justify="space-between"
      align="middle"
      className="pageHeader"
      style={{ marginBottom: 24 }}
      gutter={[16, 16]}
    >
      <Col
        className="pageTitle"
        md={!!props.setLayout ? props.setLayout.md1 : 8}
        xxl={18}
      >
        {/*md={16} */}
        <Title level={3} className="title">
          {props.title}
        </Title>
        <Text type="secondary" className="subTitle">
          {props.subTitle}
        </Text>
        {props?.breadcumb}
      </Col>

      <Col
        className="action"
        md={!!props.setLayout ? props.setLayout.md2 : 16}
        xxl={6}
      >
        {/*md={8} */}
        {props.action}
      </Col>
    </Row>
  );
};

export default PageHeader;
