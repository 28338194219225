/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { _RES_validateError } from "@Utils/validate";
import { useAuthContextState } from "@AuthContext/store";
import { useAuthContextDispatch } from "@AuthContext/store";
import { PaginationExcludeTotal } from "@Interface/service";
import { rowClassName, _cleanObject } from "@Utils/utils";
import { RESGetPatientService, PatientItem } from "@Interface/patient";
import { DeleteVisitByIdAPI, GetVisitPendingAPI } from "@Services/api/visit";
import { Button, message, Pagination, Popconfirm, Table } from "antd";
import { Col, Row, Space } from "antd";

import PageHeader from "@Components/PageHeader";
import FormSearch, { SearchFailed } from "@Components/form/FormSearch";
import dayjs from "dayjs";
import "../styles/index.less";

type Props = {};

const UploadImageDataList = (props: Props) => {
  const navigate = useNavigate();
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [totalVisitList, setTotalVisitList] = useState<number>(0); //200
  const [visitPendingList, setVisitPendingList] = useState<PatientItem[] | []>(
    []
  );
  const [pagination, setPagination] = useState<PaginationExcludeTotal>({
    page: 1,
    perpage: 10,
  });
  useEffect(() => {
    getPatientList();

    return () => { };
  }, [pagination]);

  const getPatientList = async (search?: SearchFailed) => {
    setIsLoading(true);
    const keyOnLoad = "loginPatientList";
    const params = {
      page: pagination.page,
      limit: pagination.perpage,
      ...search,
    };
    const res = await GetVisitPendingAPI<RESGetPatientService>(
      params,
      token["accessToken"]
    );
    if (res.code === 401) {
      setIsLoading(false);
      message.error({ content: res.message, keyOnLoad, duration: 2 });
      _signOut();
      return false;
    }
    if (res.status === "fail") {
      setIsLoading(false);
      message.error({ content: res.message, keyOnLoad, duration: 2 });
      return false;
    }

    if (res.code === 200) {
      setVisitPendingList(res.result[0]["data"]);
      setTotalVisitList(res.result[0]["total"]);
      setIsLoading(false);
      return true;
    } else {
      setIsLoading(false);
      message.error({ content: res.message, keyOnLoad, duration: 2 });
      return false;
    }
  };

  const onFinish = (value: SearchFailed) => {
    getPatientList(_cleanObject.obj(value));
  };
  const onCancel = () => {
    getPatientList();
  };
  const removePatientById = async (visitId: number) => {
    try {
      const keyOnLoad = "removePatientById";
      setIsLoadingConfirm(true);
      const res = await DeleteVisitByIdAPI(visitId, token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "ลบข้อมูลรายชื่อผู้ป่วยสำเร็จ",
          runOption: () => {
            setIsLoading(false);
            setIsLoadingConfirm(false);
            setVisitPendingList((pervVisitPendingList) =>
              pervVisitPendingList.filter((p) => p.id !== visitId)
            );
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setIsLoadingConfirm(false);
    }
  };
  const columns = [
    {
      title: "#",
      key: "id",
      width: 50,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{rowIndex + 1}</span>
      ),
    },
    {
      title: "เลขบัตรประชาชน",
      dataIndex: "idCard",
      key: "idCard",
      // width: 200,
    },
    {
      title: "เลข HN",
      dataIndex: "hn",
      key: "hn",
      // width: 200,
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "createAt",
      key: "createAt",
      // width: 150,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{dayjs(text).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "fullName",
      key: "fullName",
      // width: 300,
    },
    {
      title: "ที่ตั้งของรถตรวจ",
      dataIndex: "location",
      key: "location",
      // width: 300,
    },
    {
      title: "จัดการ",
      key: "action",
      width: 250,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        // <Space>
        //   <Button type="ghost" className="flex-center btn-action">
        //     <MdDelete />
        //   </Button>
        // </Space>
        <Space>
          <Button
            type="ghost"
            className="flex-center btn-action"
            onClick={() => viewPatientInfoByVisitId(record.id)}
          >
            <EyeOutlined />
          </Button>
          <Popconfirm
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            title="ยืนยันการลบข้อมูลผู้ป่วย？"
            // placement="bottomRight"
            onConfirm={() => removePatientById(record.id)}
            okButtonProps={{ loading: isLoadingConfirm }}
          >
            <Button type="ghost" className="flex-center btn-action">
              <MdDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const viewPatientInfoByVisitId = (id: number) => {
    navigate("/upload/patient-info/" + id);
  };

  return (
    <Row>
      <Col span={24}>
        <PageHeader title="ข้อมูลผู้ป่วย" subTitle="ข้อมูลผู้ป่วยทั้งหมด" />
      </Col>
      <Col span={24} className="content">
        <FormSearch onFinish={onFinish} onCancel={onCancel} type={"default"} />
        <Table
          size="small"
          rowKey="id"
          loading={isLoading}
          columns={columns}
          className="abus-table"
          dataSource={visitPendingList}
          pagination={false}
          rowClassName={rowClassName}
          scroll={{ x: 1500 }}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                viewPatientInfoByVisitId(record.id);
              },
            };
          }}
        />
        <Row justify="end" style={{ marginTop: 20 }}>
          <Col>
            {totalVisitList / pagination.perpage > 1 && (
              <Pagination
                className="abus-pagination"
                total={totalVisitList}
                onChange={(page: number, pageSize: number) => {
                  setPagination({
                    ...pagination,
                    page: page,
                    perpage: pageSize,
                  });
                }}
                defaultCurrent={pagination.page}
                defaultPageSize={pagination.perpage}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UploadImageDataList;
