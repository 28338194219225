import { Params } from '@Interface/service';
import { Fetch } from '@Utils/fetch/fetch';

export const GetUserListApi = async <T = any>(params: Params, token: string) => {
  const res = await Fetch<T>({
    params: params,
    token: token,
    method: 'GET',
    path: '/v1/user',
  });
  return res;
};

export const PostUserApi = async <T = any>(data: any, token: string) => {
  const res = await Fetch<T>({
    token: token,
    method: 'POST',
    path: '/v1/user',
    data,
  });
  return res;
};

export const DeleteUserByIdAPI = async <T = any>(visitId: number, token: string) => {
  const res = await Fetch<T>({
    token: token,
    method: 'DELETE',
    path: `/v1/user/${visitId}`,
  });
  return res;
};

export const getDoctorNameAPI = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    token: token,
    method: 'GET',
    path: '/v1/user/doctor',
  });
  return res;
};

export const getProfileAPI = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    token: token,
    method: 'GET',
    path: '/v1/user/profile',
  });
  return res;
};

export const getUserByIdAPI = async <T = any>(visitId: string, token: string) => {
  const res = await Fetch<T>({
    token: token,
    method: 'GET',
    path: `/v1/user/${visitId}`,
  });
  return res;
};

export const PutUserByIdAPI = async <T = any>(data: any, visitId: string, token: string) => {
  const res = await Fetch<T>({
    token: token,
    method: 'PUT',
    path: `/v1/user/${visitId}`,
    data,
  });
  return res;
};

export const PutUserProfile = async <T = any>(data: any, token: string) => {
  const res = await Fetch<T>({
    token: token,
    method: 'PUT',
    path: `/v1/user/profile`,
    data,
  });
  return res;
};
