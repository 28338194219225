import { Affix, Col, Divider, Row, Typography } from 'antd';
import './styles/index.less';
const { Title, Text } = Typography;

type Props = {
  title: string;
  subTitle: string;
  children: React.ReactElement | React.ReactElement[];
  container: HTMLDivElement | null;
};

const TitleAffix = (props: Props) => {
  return (
    <Row className="TitleAffix">
      <Col span={24}>
        <Affix target={() => props.container}>
          <div style={{ background: '#fff', paddingTop: 10 }}>
            <Title level={3} className="title">
              {props.title}
            </Title>
            <Text type="secondary" className="subTitle">
              {props.subTitle}
            </Text>
            <Divider style={{ marginTop: 10, paddingBottom: 10 }} />
          </div>
        </Affix>
      </Col>
      <Col span={24}>{props.children}</Col>
    </Row>
  );
};

export default TitleAffix;
