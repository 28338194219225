import { useReducer, useContext, createContext } from 'react'

import { initialValue } from './initialState'
import { InitialState } from './interface/initialType'
import { CreateContext } from './interface/action';
import { setWidth, setCollapsed } from './action'

import Reducer from './reducer'

const StateContext = createContext<InitialState>(initialValue)
const DispatchContext = createContext<CreateContext>({ dispatch: () => null, _setWidth: () => null, _setCollapsed: () => null });


const ContextProvider = ({ children }: { children?: React.ReactNode; }) => {
  const [state, dispatch] = useReducer(Reducer, initialValue);
  const DispatchValue: CreateContext = {
    _setWidth: setWidth(dispatch),
    _setCollapsed: setCollapsed(dispatch),
  }
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider
        value={DispatchValue}
      >
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

const useCollapsedContextState = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useSpinState must be used within a SpinProvider");
  }
  return context;
};

const useCollapsedContextDispatch = () => {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useSpinDispatch must be used within a SpinProvider");
  }
  return context;
};

export { ContextProvider, useCollapsedContextDispatch, useCollapsedContextState };