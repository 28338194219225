import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import PageHeader from "@Components/PageHeader";
import PagePaper from "@Components/PagePaper";
import PatientStatus from "@Components/form/PatientStatus";
import { RESGetVisitInfoService, VisitField } from "@Interface/visit";
import { CreateNewInvoiceAPI } from "@Services/api/invoice";
import { getProgramAPI } from "@Services/api/program";
import _VisitService from "@Services/api/visit";
import { formatNumberWithCommaAndDecimal } from "@Utils/utils";
import { _RES_validateError } from "@Utils/validate";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Skeleton,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
type Props = {};

export interface ProgramItem {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  price: string;
  description: string;
  image: any;
}
export interface Invoice {
  programs: ProgramItem[];
  discount: string;
  discountType: "BATH" | "PERCENT";
}
const Viewsprogram = (props: Props) => {
  const params = useParams() as { id: string };
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();

  const [form] = Form.useForm();
  const [visitInfo, setVisitInfo] = useState<VisitField | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imgProfile, setImageProfile] = useState<any>();
  const [invoiceInfo, setInvoiceInfo] = useState<Invoice>();
  const [visitProgram, setVisitProgram] = useState<any[] | []>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [programCount, setProgram] = useState(0);
  const [programItem, setProgramItem] = useState<ProgramItem[] | []>([]);
  const [checkedProgram, setCheckedProgram] = useState<ProgramItem[] | []>([]);
  const [discountType, setDiscountType] = useState<"BATH" | "PERCENT">("BATH");

  useEffect(() => {
    getProgram();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  useState<boolean>(false);
  const [id, setId] = useState<number>();

  useLayoutEffect(() => {
    !!params.id && getVisitInfo(params.id);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleCheckboxChange = (
    e: { target: { checked: any } },
    value: ProgramItem
  ) => {
    if (e.target.checked) {
      const dataCheck = [
        ...checkedProgram,
        { ...value, qty: 1, programId: value.id },
      ];
      setCheckedProgram(dataCheck);
      setProgram(programCount + 1);
      setTotalCount(totalCount + 1500);
    } else {
      const data = checkedProgram?.filter((item) => item.id !== value.id);
      setCheckedProgram(data);
      setProgram(programCount - 1);
      setTotalCount(totalCount - 1500);
    }
  };
  const handleChange = (value: "BATH" | "PERCENT") => {
    setDiscountType(value);
  };
  const onChange = (value: number) => {
    if (discountType === "BATH") setDiscount(value);
    if (discountType === "PERCENT") setDiscount((totalCount * value) / 100);
  };

  const syncCheckedPrograms = () => {
    if (!visitProgram.length || !programItem.length) return;

    const checkedPrograms = visitProgram
      .map((visit) => {
        const program = programItem.find((p) => p.id === visit.programId);
        return program
          ? { ...program, qty: visit.qty || 1, programId: visit.programId }
          : null;
      })
      .filter((program) => program !== null);
    setCheckedProgram(checkedPrograms as ProgramItem[]);

    const preCheckedCount = checkedPrograms.length;
    setProgram(preCheckedCount);

    const preCheckedTotal = checkedPrograms.reduce((total, item) => {
      const price = Number(item?.price || "0") || 0;
      const qty = Number(item?.qty || "0") || 1;
      return total + price * qty;
    }, 0);

    setTotalCount(preCheckedTotal);
  };

  useEffect(() => {
    syncCheckedPrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitProgram, programItem]);

  const getProgram = async () => {
    try {
      const res = await getProgramAPI(token.accessToken);
      const data = res.result[0].data;
      setProgramItem(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateInvoiceInfoAndProceed = (callback: any) => {
    setInvoiceInfo((prevInvoiceInfo) => {
      const updatedInvoiceInfo = {
        ...prevInvoiceInfo,
        programs: checkedProgram,
        discount: String(discount),
        discountType: discountType,
      };

      // Execute the callback after state has been updated
      callback(updatedInvoiceInfo);
      return updatedInvoiceInfo;
    });
  };

  const getVisitInfo = async (visitId: string) => {
    try {
      const keyOnLoad = "getVisitInfo";
      setIsLoading(true);
      const res =
        await _VisitService.GetVisitInfoByIdAPI<RESGetVisitInfoService>(
          visitId,
          token.accessToken
        );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setVisitInfo(res.result[0]);
            setImageProfile(res.result[0].patient.imageUrl);
            setId(res.result[0].patient.id);
            setVisitProgram(res.result[0].visitProgram);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      return false;
    }
  };

  const onFinish = async () => {
    try {
      setIsLoading(true);

      updateInvoiceInfoAndProceed(async (updatedInvoiceInfo: any) => {
        try {
          const res = await CreateNewInvoiceAPI(
            { ...updatedInvoiceInfo, visitId: Number(params.id) },
            token.accessToken
          );

          navigate("/program/payment-program/" + res.result?.[0]?.visitId, {
            state: res.result[0].id,
          });
        } catch (error) {
          console.log("error", error);
        } finally {
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const checkClick = () => {
    if (invoiceInfo?.programs.length === 0 || checkedProgram.length === 0)
      return true;
  };

  return (
    <>
      <PagePaper>
        <PageHeader
          title={"เลือกโปรแกรม"}
          subTitle="รายการโปรแกรมรักษา"
          action={
            <Row gutter={16} justify="end">
              <Col>
                <Button
                  type="default" // Change to "default" for a back button style
                  size="large"
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ paddingLeft: 30, paddingRight: 30 }}
                >
                  กลับ
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => onFinish()}
                  style={{ paddingLeft: 30, paddingRight: 30 }}
                  disabled={checkClick()}
                >
                  ขำระค่าโปรแกรม
                </Button>
              </Col>
            </Row>
          }
        />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <PagePaper>
              <Skeleton active loading={isLoading}>
                <Form
                  form={form}
                  size="large"
                  layout="vertical"
                  className="FormUpload"
                >
                  <PatientStatus
                    visitInfo={visitInfo}
                    imgProfile={imgProfile}
                    id={id}
                  />
                  <Divider />
                </Form>
              </Skeleton>
            </PagePaper>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <React.Fragment>
              <Row gutter={16}>
                <Col lg={16} md={8}>
                  <Card
                    title="เลือกประเภทโปรแกรมรักษา"
                    style={{ width: "auto", height: 500 }}
                  >
                    <Row gutter={[16, 16]}>
                      {programItem.map((item, ind) => (
                        <Col span={8} key={ind}>
                          <Card
                            style={{ width: "auto", height: "40vh" }}
                            hoverable
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Checkbox
                                style={{ marginRight: 0 }}
                                onChange={(e) => handleCheckboxChange(e, item)}
                                checked={checkedProgram.some(
                                  (program) => program.id === item.id
                                )}
                              ></Checkbox>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                className=""
                                src="/images/abus.svg"
                                width={"30%"}
                                alt=""
                              ></img>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Title level={4}> {item.name}</Title>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                              <hr className="my-5" />
                              <Title level={4}> {item.price}</Title>
                            </div>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                </Col>
                <Col lg={8} md={16}>
                  <Card title="รายการชำระเงิน" style={{ width: 300 }}>
                    <Row className="mt-05" gutter={[16, 16]}>
                      <Col span={24}>
                        <span>โปรแกรมทั้งหมด: {programCount} รายการ</span>
                      </Col>
                      <Col span={24}>
                        <span>
                          ราคารวม: {formatNumberWithCommaAndDecimal(totalCount)}{" "}
                          บาท
                        </span>
                      </Col>
                      <Col span={24}>
                        <span>
                          ราคาสุทธิ:{" "}
                          {formatNumberWithCommaAndDecimal(totalCount)} บาท
                        </span>
                      </Col>
                      <Col span={24}>
                        <span>ส่วนลด:</span>
                        <InputNumber
                          defaultValue={0}
                          min={0}
                          onChange={() => onChange}
                          style={{ width: 60 }}
                        />
                        <Select
                          defaultValue="BATH"
                          style={{ width: 120 }}
                          onChange={handleChange}
                          options={[
                            { value: "PERCENT", label: "เปอร์เซ็นต์" },
                            { value: "BATH", label: "บาท" },
                          ]}
                        />
                      </Col>
                      <Col span={24}>
                        <span>
                          ราคารวมส่วนลด:{" "}
                          {formatNumberWithCommaAndDecimal(discount)} บาท{" "}
                        </span>
                      </Col>
                      {/* <Col>
                                <span>{""} บาท</span>
                            </Col> */}

                      <Col span={24}>
                        <span>
                          ยอดเงินที่ต้องชำระ{" "}
                          {formatNumberWithCommaAndDecimal(
                            totalCount - discount
                          )}{" "}
                          บาท
                        </span>
                      </Col>
                      {/* <Col>
                                <span>{""} บาท</span>
                            </Col> */}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </PagePaper>
    </>
  );
};
export default Viewsprogram;
