/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useState } from "react";
// import Drawer from "@Components/Drawer";
import { useNavigate } from "react-router-dom";
import { VisitField } from "@Interface/visit";
import { _RES_validateError } from "@Utils/validate";
import { useAuthContextState } from "@AuthContext/store";
import { useAuthContextDispatch } from "@AuthContext/store";
import { CreateVisitByPatientAPI } from "@Services/api/visit";
import {
  Col,
  Form,
  Row,
  Space,
  Drawer,
  Button,
  Input,
  InputNumber,
} from "antd";
import {
  longdo,
  map,
  LongdoMap,
  search,
} from "../../../utils/longdomap/LongdoMap.js";
import "./styles/index.less";
import _MapLongdo from "@Services/api/map";
import appConfig from "../../../config/app.config";

type Props = {
  isModalVisible: boolean;
  onClose: () => void;
  uuid?: string | null;
  onSuccess?: () => void;
  location?: any;
  setIsModalVisible: any;
};

const CreateVisit = (props: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataMap, setDataMap] = useState<any>();
  const [locationMap, setLocationMap] = useState<any>();
  const [showModalMap, setShowModalMap] = useState<boolean>(false);

  useLayoutEffect(() => {
    getLocationMaps(locationMap);
  }, [props.isModalVisible]);

  const onFinish = async (value: VisitField) => {
    try {
      const keyOnLoad = "CreateVisit";
      setIsLoading(true);
      const res = await CreateVisitByPatientAPI(
        {
          ...value,
          patient: props?.uuid || "",
          location: dataMap,
          bmi: Number(value.bmi),
        },
        token.accessToken
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "สร้างรายการ visit สำเร็จ",
          runOption: () => {
            setIsLoading(false);
            !!props.onSuccess && props.onSuccess();
            form.resetFields();
            // navigate(`/patient/history/${res.result[0].patient.id}`);
            navigate(`/patient/view-list`);
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const onReset = () => {
    form.resetFields();
    setShowModalMap(false);
    props.onClose();
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName === "weight" || fieldName === "height") {
      const weight = changedValues["weight"] || allValues["weight"] || 0;
      const height = changedValues["height"] || allValues["height"] || 0;
      if (!!weight && !!height) {
        const heightpow = (height / 100) ** 2;
        const sum = weight / heightpow;
        form.setFieldsValue({ bmi: sum.toFixed(2) });
      }
    }
  };

  const initMap = () => {
    // if(!!newLocation){
    //   map.location();
    //   var result = map.location();
    //   console.log(1)
    // }else{
    //   map.location(longdo.LocationMode.Geolocation);
    //   var result = map.location();
    //   console.log(2)
    // }
    map.location(longdo.LocationMode.Geolocation);

    var result = map.location();
    console.log(result, "start");
    setLocationMap(result);
    getLocationMaps(result);
  };

  const Mapsearch = () => {
    map.Search.search(search.value, {});
  };

  const onSubmitMap = () => {
    initMap();
    setShowModalMap(false);
  };

  const getLocationMaps = async (result: any) => {
    try {
      console.log(result, "map");
      if (!!result) {
        const data = {
          lat: result.lat,
          lon: result.lon,
        };
        const res = await _MapLongdo.testLongdoMapAPI(data);
        form.setFieldsValue({
          location:
            res.subdistrict +
            " " +
            res.district +
            " " +
            res.province +
            " " +
            res.country,
        });
        setDataMap(res);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onClickNewMap = () => {
    setShowModalMap(!showModalMap);
    // props.setIsModalVisible(false);
  };

  return (
    <>
      <Form form={form}>
        <Drawer
          className="drawer-create-visit"
          title="สร้าง Visit"
          closable={false}
          size="large"
          visible={props.isModalVisible}
          extra={
            <Space>
              <Button onClick={onReset}>ยกเลิก</Button>
              <Button
                type="primary"
                loading={isLoading}
                onClick={() => form.submit()}
              >
                บันทึก
              </Button>
            </Space>
          }
        >
          <Form
            form={form}
            size="large"
            layout="vertical"
            className="FormVisit"
            onFinish={onFinish}
            requiredMark="optional"
            initialValues={{ visit: "010365-xxxx" }}
            onValuesChange={onValuesChange}
          >
            <Row gutter={[16, 16]}>
              <Col flex={1}>
                <Form.Item
                  label={<span className="color-primary">ID visit</span>}
                  name="visit"
                >
                  <Input className="color-primary" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={21}>
                <Form.Item
                  label="location"
                  name="location"
                  rules={[
                    { required: true, message: "กรุณาใส่ข้อมูลlocation!" },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={3}>
                <Form.Item label=" " name="btn">
                  <Button
                    onClick={() => onClickNewMap()}
                    style={{ width: "100%" }}
                  >
                    Map
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {!!showModalMap && (
              <>
                <Row>
                  <Col md={24} lg={24} style={{}}>
                    <Row gutter={[16, 16]} justify="end">
                      <Col md={20} lg={20}>
                        {/* <Form.Item name="search" label="ค้นหา"> */}
                        <Input id="search"></Input>
                        {/* </Form.Item> */}
                      </Col>
                      <Col md={4} lg={4}>
                        {/* <Form.Item label=" "> */}
                        <Button
                          onClick={() => Mapsearch()}
                          style={{ width: "100%" }}
                        >
                          Search
                        </Button>
                        {/* </Form.Item> */}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={24}>
                    <Row>
                      <Col md={16} className="box-map">
                        <Row className="longdoMap-height">
                          <Col lg={24} className="longdo-map">
                            <LongdoMap
                              id="longdo-map"
                              mapKey={appConfig.keyLongdo}
                              callback={initMap}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        md={8}
                        lg={8}
                        style={{ minHeight: "90%", marginTop: "10px" }}
                      >
                        <Row justify="end">
                          <Col md={24} style={{ padding: "0px 10px" }}>
                            <div
                              id="result"
                              className="result-map height-resultMap"
                              style={{ textAlign: "center" }}
                            >
                              ไม่มีข้อมูล
                            </div>
                          </Col>
                          <Col md={24}>
                            <Button
                              onClick={onSubmitMap}
                              style={{ width: "100%" }}
                            >
                              submit
                            </Button>
                          </Col>
                        </Row>
                        {/* <Row justify="end" style={{ marginTop: "30px" }}>
                          <Col>
                            <Button onClick={onSubmitMap}>submit</Button>
                          </Col>
                        </Row> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <div>V/S information</div>
            <Row>
              <Col span={24} className="box">
                <Form.Item
                  label="น้ำหนัก (กก.)"
                  name="weight"
                  rules={[
                    { required: true, message: "กรุณาใส่ข้อมูลน้ำหนัก!" },
                  ]}
                >
                  {/* <Input placeholder="นำ้หนัก" /> */}
                  <InputNumber<number>
                    min={0}
                    max={200}
                    step={0.01}
                    // stringMode
                    placeholder="น้ำหนัก (กก.)"
                    className="w-100"
                  />
                </Form.Item>
                <Form.Item
                  label="ส่วนสูง (ซม.)"
                  name="height"
                  rules={[
                    { required: true, message: "กรุณาใส่ข้อมูลส่วนสูง!" },
                  ]}
                >
                  <InputNumber<number>
                    min={0}
                    max={200}
                    step={0.01}
                    // stringMode
                    placeholder="ส่วนสูง (ซม.)"
                    className="w-100"
                  />
                </Form.Item>
                <Form.Item
                  label="BMI (Auto)"
                  name="bmi"
                  rules={[{ required: true, message: "กรุณาใส่ข้อมูล BMI!" }]}
                >
                  <InputNumber<number>
                    min={0}
                    max={200}
                    step={0.01}
                    stringMode
                    placeholder="BMI (Auto)"
                    className="w-100"
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  label="ความดัน (mm/Hg)"
                  name="pressure"
                  rules={[
                    { required: true, message: "กรุณาใส่ข้อมูลความดัน!" },
                  ]}
                >
                  {/* <Input placeholder="ความดัน" /> */}
                  <Input
                    // min={20}
                    // max={500}
                    // step={1}
                    // stringMode
                    placeholder="ความดัน (mm/Hg)"
                    className="w-100"
                  />
                </Form.Item>
                <Form.Item
                  label="อุณหภูมิ (c)"
                  name="temperature"
                  rules={[
                    { required: true, message: "กรุณาใส่ข้อมูลอุณหภูมิ!" },
                  ]}
                >
                  <InputNumber<number>
                    min={0}
                    max={500}
                    step={0.01}
                    // stringMode
                    placeholder="อุณหภูมิ (c)"
                    className="w-100"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </Form>
      {!showModalMap && (
        <div className="form-print">
          <LongdoMap
            id="longdo-map"
            mapKey={appConfig.keyLongdo}
            callback={initMap}
          />
        </div>
      )}
      {/* {!newLocation ? (
        <div className="form-print">
          <LongdoMap
            id="longdo-map"
            mapKey={appConfig.keyLongdo}
            callback={initMap}
          />
        </div>
      ) : (
        // <Modal
        //   visible={showModalMap}
        //   style={{ minWidth: "60%" }}
        //   onCancel={() => setShowModalMap(false)}
        //   onOk={() => onSubmitMap()}
        // >
        //   <Row className="modalMap" gutter={[16, 16]}>
        //     <Col md={24} lg={16}>
        //       <Row className="longdoMap-height">
        //         <Col lg={24} className="longdo-map">
        //           <LongdoMap
        //             id="longdo-map"
        //             mapKey={appConfig.keyLongdo}
        //             callback={initMap}
        //           />
        //         </Col>
        //       </Row>
        //     </Col>
        //     <Col md={24} lg={8}>
        //       <Form form={form2}>
        //         <Row>
        //           <Col lg={24} style={{ height: "30px" }}>
        //             <Row gutter={[16, 16]} justify="end">
        //               <Col>ค้นหา</Col>
        //               <Col>
        //                 <Form.Item name="search">
        //                   <Input id="search"></Input>
        //                 </Form.Item>
        //               </Col>
        //               <Col>
        //                 <Button onClick={() => Mapsearch()}>Search</Button>
        //               </Col>
        //             </Row>
        //           </Col>
        //           
        //           <Col
        //             md={24}
        //             lg={24}
        //             style={{ minHeight: "90%", marginTop: "10px" }}
        //           >
        //             <div
        //               id="result"
        //               className="result-map height-resultMap"
        //             ></div>
        //           </Col>
        //         </Row>
        //       </Form>
        //     </Col>
        //   </Row>
        // </Modal>
        )} */}
    </>
  );
};

export default CreateVisit;
