import { Link } from "react-router-dom";
import { Button, Col, Image, Result, Row } from "antd";
const Page500 = () => {
  return (
    <Row justify="center" align="middle" style={{ height: "100%" }}>
      <Col xs={24} md={20} lg={15} xl={10}>
        <Result
          icon={
            <Image
              src={"/images/error500.png"}
              alt="error-500"
              preview={false}
            />
          }
          extra={
            <Link
              style={{ display: "block", margin: "1rem 0" }}
              to={`/`}
              key={"home"}
            >
              <Button
                type="primary"
                size="large"
                style={{ paddingLeft: 30, paddingRight: 30 }}
              >
                กลับหน้าหลัก
              </Button>
            </Link>
          }
        />
      </Col>
    </Row>
  );
};

export default Page500;
