/* eslint-disable react-hooks/exhaustive-deps */
import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import { RESGetVisitInfoService } from "@Interface/visit";
import _VisitService, { GetVisitNoteEmail } from "@Services/api/visit";
import { _RES_validateError } from "@Utils/validate";
import { Button, Col, Drawer, Form, Image, Input, Row, Space } from "antd";
import dayjs from "dayjs";
import QRCode from "qrcode.react";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";

type Props = {
  isModalVisible: boolean;
  onClose: () => void;
  uuid?: string | null;
  onSuccess?: () => void;
  componentToPrint: React.MutableRefObject<null>;
};

const CreateMail = (props: Props) => {
  const params = useParams() as { visitId: string };
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<any>(false);
  const [printData, setPrintData] = useState<any>({});
  const [html, sethtml] = useState<any>();
  const printRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    !!params.visitId && getVisitEmailID(params.visitId);
  }, []);

  const getVisitEmailID = async (visitId: string) => {
    const res = await GetVisitNoteEmail<RESGetVisitInfoService>(
      visitId,
      token.accessToken
    );
    const data: any = res.result[0];

    form.setFieldsValue({
      ...data,
      ...data.visit,
      dateOfBirth: dayjs(data?.dateOfBirth).format("DD/MM/YYYY"),
    });
    sethtml(res.result[0].html);
    setPrintData({ ...data, location: data.location });
  };

  const onReset = () => {
    // form.resetFields();
    props.onClose();
  };

  const onSendMail = async (id: string) => {
    try {
      setLoading(true);
      const keyOnLoad = "SendMail";
      const res = await _VisitService.SendMailAPI(id, token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "ส่งเมลสำเร็จ",
          runOption: () => {
            setLoading(false);
            // setIsLoading(false);
            // navigate(`/patient/view-edit/${res.result[0].id}`);
          },
        },
      });
      props.onClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Form form={form}>
        <Drawer
          visible={props.isModalVisible}
          title="ข้อมูลผู้ป่วย"
          closable={false}
          size="large"
          extra={
            <Space>
              <ReactToPrint
                trigger={() => (
                  <Button
                    type="primary"
                    size="large"
                    style={{
                      backgroundColor: "#475F7B",
                      borderColor: "#475F7B",
                    }}
                  >
                    <Row gutter={[16, 16]} justify={"center"} align={"middle"}>
                      <Col>
                        <Image
                          src={"/images/printIcon.svg"}
                          preview={false}
                          width={20}
                          height={20}
                        />
                      </Col>
                      <Col>พิมพ์</Col>
                    </Row>
                  </Button>
                )}
                content={() => printRef.current}
              />

              <Button type="ghost" size="large" onClick={onReset}>
                ยกเลิก
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() => onSendMail(params.visitId)}
                loading={loading}
              >
                <Row gutter={[16, 16]} justify={"center"}>
                  <Col style={{ display: "flex" }}>
                    <Image
                      preview={false}
                      src={"/images/send-mail.svg"}
                      width={20}
                      height={20}
                    />
                  </Col>
                  <Col style={{ display: "flex" }}> ส่งเมล</Col>
                </Row>
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            size="large"
            form={form}
            style={{ backgroundColor: "white" }}
          >
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <Form.Item label={<>Visit Date</>} name="visitNo">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item label={<>Location</>} name="location">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item label={<>เลขบัตรประชาชน</>} name="idCard">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item label={<>อีเมล์</>} name="email">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label={
                    <>
                      วันเดือนปีเกิด
                      {/* (Date picked) */}
                    </>
                  }
                  name="dateOfBirth"
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item label={<>เบอร์โทรศัพท์</>} name="phone">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col md={16}>
                <Form.Item label={<>ที่อยู่</>} name="address">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item label={<>รหัสผ่าน QR CODE</>} name="passCode">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item label={<>QR CODE PATIENT</>} name="qrCodePatient">
                  <QRCode
                    size={200}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={true}
                    value=""
                    renderAs="canvas"
                  />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item label={<>QR CODE DOCTOR</>} name="qrCodeDoctor">
                  <QRCode
                    size={200}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={true}
                    value=""
                    renderAs="canvas"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </Form>
      <div ref={printRef} className="form-print">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      {/* <FormPrint
            componentToPrint={props.componentToPrint}
            // form={form}
            printData={printData}
          /> */}
    </>
  );
};

export default CreateMail;
