/* eslint-disable react-hooks/exhaustive-deps */
import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import DoctorDiagnose from "@Components/form/DoctorDiagnose";
import PatientStatus from "@Components/form/PatientStatus";
import PageHeader from "@Components/PageHeader";
import PagePaper from "@Components/PagePaper";
import {
  RESGetVisitInfoService,
  VisitField,
  VisitFiles,
} from "@Interface/visit";
import { _VisitService } from "@Services/api/visit";
import { _cleanObject, _findObjectOfArrayByKeyName } from "@Utils/utils";
import { _RES_validateError } from "@Utils/validate";
import useZipDownload2 from "@Views/Diagnostics/FormDiagnostics/fileDownload";
import CreateMail from "@Views/UploadImageData/CreateMail";
import { Button, Col, Divider, Form, Row, Skeleton } from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export interface StatusDataForm {
  risk: string;
  description: string;
}

type Props = {};

const FormDoctor = (props: Props) => {
  const params = useParams() as { visitId: string };
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [form] = Form.useForm();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const componentToPrint = useRef(null);
  const [visitFiles, setVisitFiles] = useState<{
    [key: string]: VisitFiles[];
  } | null>(null);
  const [visitInfo, setVisitInfo] = useState<VisitField | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [valueSubmit, setValueSubmit] = useState<boolean>(true);
  const [isDiagnosticsLoadind, setIsDiagnosticsLoadind] =
    useState<boolean>(false);
  const [studyId, setStudyId] = useState<string | null>();
  const [id, setId] = useState<number>();
  // const [image, setImage] = useState<any>();
  const [dataDoctor, setDataDoctor] = useState<any>();
  const navigate = useNavigate();
  const [downloadAndZip] = useZipDownload2();

  useEffect(() => {
    !!params.visitId && getVisitInfo(params.visitId);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVisitInfo = async (visitId: string) => {
    try {
      const keyOnLoad = "getVisitWaitingInfo";
      setIsLoading(true);
      const res =
        await _VisitService.GetVisitInfoByIdAPI<RESGetVisitInfoService>(
          visitId,
          token.accessToken
        );
      setVisitInfo(res.result[0]);
      setDataDoctor(res.result[0].diagnose);

      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            if (res.result?.[0].diagnosisForm?.formData !== null) {
              form.setFieldsValue({
                ...res.result?.[0].diagnosisForm?.formData,
              });
            }

            const fileVisit = transformData(res.result[0]?.visitFiles);
            setVisitFiles(fileVisit);
            setStudyId(res.result[0].studyId);
            setVisitInfo(res.result[0]);
            setDataDoctor(res.result[0].diagnose);
            initVisitFilesUploads(res.result[0].visitFiles, dataSum);
            // setImage(res.result[0].patient.imageUrl);
            setId(res.result[0].patient.id);
            setIsLoading(false);
            return true;
          },
        },
      });
      const dataRes: any = res.result[0];
      const dataSet = {
        risk: dataRes?.risk,
        description: dataRes?.description,
      };
      const dataSet2 =
        dataRes?.diagnosis_form !== null && dataRes.diagnosis_form?.form_data;
      const dataSum = { ...dataSet, ...dataSet2 };
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      return false;
    }
  };

  const initVisitFilesUploads = (values: VisitFiles[] | [], dataSum: any) => {
    const data = {
      apLeft: _findObjectOfArrayByKeyName(values, "apLeft", "keyName"),
      ltLeft: _findObjectOfArrayByKeyName(values, "ltLeft", "keyName"),
      medLeft: _findObjectOfArrayByKeyName(values, "medLeft", "keyName"),
      apRight: _findObjectOfArrayByKeyName(values, "apRight", "keyName"),
      ltRight: _findObjectOfArrayByKeyName(values, "ltRight", "keyName"),
      medRight: _findObjectOfArrayByKeyName(values, "medRight", "keyName"),
      ...dataSum,
    };
    form.setFieldsValue(_cleanObject.obj(data));
  };

  const onDownload = async (visitId: string) => {
    const id = Number(visitId);
    const res = await _VisitService.visitDownloadFiles(id, token.accessToken);
    const data = res.result;
    // data.map((data: any) => {
    //   window.open(data.url);
    // });
    downloadAndZip(data);
  };

  const handleCreateMail = () => {
    // send API
    setIsModalVisible(true);
  };

  const onSubmitForm = async () => {
    const res = await _VisitService.visitBackToReserve(
      params.visitId,
      token.accessToken
    );
    const keyOnLoad = "onBackToReserve";
    _RES_validateError(res, keyOnLoad, {
      Code_401: { runOption: _signOut },
      Code_200: {
        runOption: () => {
          setIsLoading(false);
          return true;
        },
      },
    });
    navigate("/report/view-list");
  };

  const onCloseModalCreateVisit = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <CreateMail
        onClose={onCloseModalCreateVisit}
        isModalVisible={isModalVisible}
        componentToPrint={componentToPrint}
      />
      <PageHeader
        back={
          <Row gutter={[16, 16]} justify="end">
            <Col flex={4} md={12} lg={12}>
              <Button
                onClick={() => navigate(-1)}
                loading={isDiagnosticsLoadind}
                className="w-100"
                type="primary"
                size="large"
                style={{
                  background: "#475F7B",
                  color: "#fff",
                  borderColor: "#475F7B",
                }}
              >
                กลับ
              </Button>
            </Col>
          </Row>
        }
        status={visitInfo?.status}
        title={
          <>
            <Row justify="space-between">
              <Col md={12}>ข้อมูลวินิจฉัย</Col>
            </Row>
          </>
        }
        action={
          <Row gutter={[16, 16]} justify="end">
            <Col flex={2} md={12} lg={12}>
              <Button
                onClick={handleCreateMail}
                type="primary"
                size="large"
                // loading
                className="w-100"
                style={{
                  backgroundColor: "#4887f2",
                  borderColor: "#4887f2",
                }}
              >
                สร้างจดหมาย
              </Button>
            </Col>
            <Col flex={2} md={12} lg={12}>
              <Button
                type="primary"
                size="large"
                className="w-100"
                style={{
                  background: "#097EF8",
                  color: "#fff",
                  borderColor: "#097EF8",
                }}
                onClick={() => onSubmitForm()}
                loading={isDiagnosticsLoadind}
              >
                ส่งไปยังห้องแพทย์
              </Button>
            </Col>
          </Row>
        }
      />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <PagePaper>
            <Skeleton active loading={isLoading}>
              <Form
                scrollToFirstError
                requiredMark="optional"
                form={form}
                size="middle"
                layout="vertical"
                className="FormUpload"
                disabled
              >
                <PatientStatus
                  visitInfo={visitInfo}
                  testprops={""}
                  onDownload={onDownload}
                  id={id}
                  doctorInfo={dataDoctor}
                />
                <Divider />
                <div className="FormDoctor">
                  <DoctorDiagnose
                    dataForm={form}
                    visitFiles={visitFiles}
                    studyId={studyId}
                    visitInfo={visitInfo}
                  />
                </div>
              </Form>
            </Skeleton>
          </PagePaper>
        </Col>
      </Row>
    </>
  );
};

export default FormDoctor;

interface DataItem {
  id: number;
  name: string;
  keyName: string;
  fileUrl: string;
}

interface TransformedData {
  [key: string]: DataItem[];
}

function transformData(data: DataItem[]): TransformedData {
  const result: TransformedData = {};

  data.forEach((item) => {
    const keyName = item.keyName;
    if (!result[keyName]) {
      result[keyName] = [];
    }

    const transformedItem = {
      id: item.id,
      name: item.name,
      keyName: item.keyName,
      fileUrl: item.fileUrl,
    };

    result[keyName].push(transformedItem);
  });

  return result;
}
