/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuthContextDispatch, useAuthContextState } from '@AuthContext/store';
import { FormPatientFields } from '@Interface/patient';
import { DeletePatientByIdAPI, GetPatientByIdAPI, UpdateNewPatientAPI } from '@Services/api/patient';
import { _RES_validateError } from '@Utils/validate';
import { MdDelete } from 'react-icons/md';

import { Avatar, Button, Col, Divider, Form, Image, Popconfirm, Row, Space, Spin, Tag } from 'antd';

import { HistoryOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Breadcumb from '@Components/Breadcumb';
import PageHeader from '@Components/PageHeader';
import PagePaper from '@Components/PagePaper';
import { CreateNewMediaAPI } from '@Services/api/media';
import { removeKeyObj } from '@yim/util-tool-ts';
import moment from 'moment';
import FormPatient from '../components/FormPatient';
import DrawerCreateVisit from '../CreateVisit';

type Props = {};

const ViewEditPatient = (props: Props) => {
  const params = useParams() as { id: string };
  const navigate = useNavigate();
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingConfirmLoading, setIsLoadingConfirmLoading] = useState<boolean>(false);
  const [patientInfo, setPatientInfo] = useState<FormPatientFields>();
  const [patientUuid, setPatientUuid] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [profileImg, setProfileImg] = useState<any>();
  const [onEdit, setOnEdit] = useState<boolean>(false);
  // const [locationMap, setLocationMap] = useState<any>();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  useEffect(() => {
    params.id && getPatientById(params.id);
    return () => {};
  }, [params.id]);

  const getPatientById = async (patientId: string) => {
    try {
      setIsLoading(true);
      const keyOnLoad = 'getPatientById';
      const res = await GetPatientByIdAPI(patientId, token.accessToken);
      const dataUser = res.result[0];
      const dataformQuestion = dataUser.questionnaire.formQuestion;
      if (dataformQuestion.birthday) {
        dataformQuestion.birthday = moment(dataformQuestion.birthday);
      }
      form.setFieldsValue({
        ...dataUser,
        birthday: moment(dataUser.dateOfBirth),
        avatar: dataUser.imageUrl,
        ...dataformQuestion,
      });
      setProfileImg({ url: dataUser.imageUrl });
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            const patientInfoInitialValue = {
              hn: res.result[0]['hn'],
              id: res.result[0]['id'],
              uuid: res.result[0]['uuid'],
              phone: res.result[0]['phone'],
              email: res.result[0]['email'],
              idCard: res.result[0]['idCard'],
              address: res.result[0]['address'],
              birthday: res.result[0]['dateOfBirth'],
              fullName: res.result[0]['fullName'],
              ...res.result[0]['questionnaire'],
            };
            setPatientInfo(patientInfoInitialValue);
            setPatientUuid(res.result[0]['uuid']);
            setIsLoading(false);
          },
        },
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const removePatientById = async (patientId: string) => {
    try {
      const keyOnLoad = 'removePatientById';
      const res = await DeletePatientByIdAPI(Number(patientId), token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: 'ลบข้อมูลรายชื่อผู้ป่วยสำเร็จ',
          runOption: () => {
            setIsLoadingConfirmLoading(false);
          },
        },
      });
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  const onFinish = async (value: FormPatientFields) => {
    try {
      setIsLoading(true);
      setIsLoadingConfirmLoading(true);
      const keyOnLoad = 'EditPatient';
      const dataField = {
        hn: value.hn || '',
        // sn: value.sn || "",
        email: value.email,
        phone: value.phone.replaceAll('-', ''),
        idCard: value.idCard.replaceAll(' ', ''),
        address: value.address,
        fullName: value.fullName,
        dateOfBirth: moment(value.birthday).format('YYYY-MM-DD'),
        imageId: 0,
      };
      const dataAddFieldQuestionnaire = {
        ...dataField,
        questionnaire: JSON.stringify(removeKeyObj({ ...value }, ['avatar', 'birthday', ...Object.keys(dataField)])),
      };

      if (!!value.avatar && typeof value.avatar !== 'string') {
        const formData = new FormData();
        formData.append('file', value.avatar.originFileObj);
        const res = await CreateNewMediaAPI(formData, token.accessToken);
        dataAddFieldQuestionnaire['imageId'] = res.result[0].id;
      }
      // const FormData = convertJsonToFormData(dataAddFieldQuestionnaire);
      // if (!!value.avatar) {
      //   FormData.append("avatar", value.avatar.originFileObj || null);
      // }
      const res = await UpdateNewPatientAPI(params.id, dataAddFieldQuestionnaire, token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: 'แก้ไขรายชื่อผู้ป่วยสำเร็จ',
          runOption: () => {
            setIsLoading(false);
            setIsLoadingConfirmLoading(false);
            navigate(`/patient/view-list`);
          },
        },
      });
    } catch (error) {
      setIsLoading(false);
      setIsLoadingConfirmLoading(false);
    }
  };
  const onCloseModalCreateVisit = () => {
    setIsModalVisible(false);
  };

  const onCloseEdit = () => {
    setOnEdit(true);
  };

  return (
    <div className="h-100 flex-center flex-column" style={{ position: 'relative' }}>
      <DrawerCreateVisit
        isModalVisible={isModalVisible}
        onClose={onCloseModalCreateVisit}
        uuid={patientUuid}
        setIsModalVisible={setIsModalVisible}
      />
      <div className="w-100">
        <PageHeader
          title="ผู้ป่วย"
          // subTitle="ข้อมูลผู้ป่วย"
          setLayout={{ md1: 7, md2: 17 }}
          breadcumb={
            <Breadcumb
              value={[
                { title: 'ผู้ป่วย', path: '/patient/view-list' },
                {
                  title: 'ข้อมูลผู้ป่วย',
                  path: `/patient/view-edit/${params.id}`,
                },
              ]}
            />
          }
          action={
            <>
              {onEdit ? (
                <>
                  <Row justify="end" gutter={[16, 16]}>
                    <Col md={10}>
                      <Button
                        onClick={() => setOnEdit(false)}
                        size="large"
                        className="btn-update"
                        style={{
                          // paddingLeft: 50,
                          // paddingRight: 50,
                          width: '100%',
                        }}
                      >
                        ยกเลิก
                      </Button>
                    </Col>
                    <Col md={10}>
                      <Button
                        onClick={() => form.submit()}
                        type="primary"
                        size="large"
                        style={{ width: '100%' }}
                        loading={isLoadingConfirmLoading}
                      >
                        บันทึก
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  {/* <Space size={"middle"}> */}
                  <Row justify="end" align="middle" gutter={[16, 16]}>
                    <Col md={4}>
                      <Popconfirm
                        okText="ยืนยัน"
                        cancelText="ยกเลิก"
                        title="ยืนยันการลบข้อมูลผู้ป่วย？"
                        placement="bottom"
                        onConfirm={() => removePatientById(params.id)}
                        okButtonProps={{ loading: isLoadingConfirmLoading }}
                      >
                        <Button
                          type="ghost"
                          className="flex-center btn-action"
                          style={{
                            background: '#fff',
                          }}
                        >
                          <MdDelete />
                        </Button>
                      </Popconfirm>
                    </Col>
                    <Col md={9}>
                      <Button
                        onClick={() => onCloseEdit()}
                        size="large"
                        className="btn-update"
                        style={{
                          width: '100%',
                        }}
                      >
                        แก้ไข
                      </Button>
                    </Col>
                    <Col md={9}>
                      <Button
                        onClick={() => setIsModalVisible(true)}
                        type="primary"
                        size="large"
                        className="flex-center"
                        style={{
                          width: '100%',
                        }}
                        loading={isLoadingConfirmLoading}
                      >
                        <PlusCircleOutlined /> โปรแกรมตรวจ
                      </Button>
                    </Col>
                  </Row>
                  {/* </Space> */}
                </>
              )}
            </>
          }
        />
      </div>
      <div className="w-100 " style={{ flex: 1 }}>
        <Row className="h-100">
          <Col span={24}>
            <PagePaper>
              <Row justify="space-between">
                <Col>
                  <Space size="middle">
                    <div>
                      {isLoading ? (
                        <Spin indicator={antIcon} spinning={isLoading} />
                      ) : (
                        <Avatar
                          src={
                            <Image
                              border-radius="50%"
                              src={(!!profileImg && profileImg.url) || '/images/ProfileDefualt.svg'}
                              preview={false}
                              // loading={"lazy"}
                            />
                          }
                        />
                      )}
                    </div>
                    <div>
                      <div>{patientInfo?.fullName}</div>
                      <Tag color="#ff748e">
                        <span>HN : {patientInfo?.hn || 'ไม่พบข้อมูล'}</span>
                      </Tag>
                    </div>
                  </Space>
                </Col>
                <Col>
                  <Button
                    type="ghost"
                    size="large"
                    style={{ background: '#fff' }}
                    onClick={() => !!patientInfo?.id && navigate(`/patient/history/${patientInfo?.id}`)}
                  >
                    <Space>
                      <HistoryOutlined /> <span>ประวัติ</span>
                    </Space>
                  </Button>
                </Col>
              </Row>
              <Divider />
              <div
                style={{
                  height: 'calc(100vh - 200px - 150px)',
                  overflow: 'auto',
                }}
              >
                <FormPatient
                  form={form}
                  onFinish={onFinish}
                  isLoading={isLoading}
                  initialValues={patientInfo}
                  onEdit={onEdit}
                  profileImg={profileImg}
                />
              </div>
            </PagePaper>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewEditPatient;
