import { FormInstance } from "antd";
import { ColLayout, RowLayout } from "./_CustomItem/Layout";
import { FormItemType, _CustomItemQuestionItem } from "./interface";

import FormItemListRelative from "./_CustomItem/Layout/FormItemList";
import FormItemListRelativeQuestion2 from "./_CustomItem/Layout/FormItemList/index2";

import FormItemDoctor from "./_CustomItem/Layout/FormItemDoctor";

import RenderFormItem from "./_RenderFormItem";

import { VisitField, VisitFiles } from "@Interface/visit";
import Choice from "./_CustomItem/Choice";
import DicomMedia from "./_CustomItem/DicomMedia";
import Input from "./_CustomItem/Input";
import InputNumber from "./_CustomItem/InputNumber";
import FormItemDoctorMass from "./_CustomItem/Layout/FormItemDoctor/index2";
import MarkerImage from "./_CustomItem/MarkerImage";
import SelectOption from "./_CustomItem/SelectOption";
import TextArea from "./_CustomItem/TextArea";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  form: FormInstance<any>;
  values: _CustomItemQuestionItem;
  disabled?: boolean;
  keyLoop?: any;
  cusname?: any;
  isFormList?: string | number;
  openModal?: () => void;
  visitInfo?: VisitField | null;
  fileList?: VisitFiles[];
}

const CreateLayoutFormItem = (props: Props) => {
  return (
    <>
      {props.values.type === FormItemType.ROW ? (
        <RowLayout propsRow={props.values.propsRow}>
          <>
            {!!props.values.children && (
              <RenderFormItem
                form={props.form}
                values={props.values.children}
                disabled={props.disabled}
                keyLoop={props.keyLoop}
                isFormList={props.isFormList}
              />
            )}
          </>
        </RowLayout>
      ) : props.values.type === FormItemType.COL ? (
        <ColLayout colSpan={props.values.colSpan}>
          {!!props.values.children && (
            <RenderFormItem
              form={props.form}
              values={props.values.children}
              disabled={props.disabled}
              keyLoop={props.keyLoop}
              isFormList={props.isFormList}
            />
          )}
        </ColLayout>
      ) : props.values.type === FormItemType.INPUT_NUMBER ? (
        <InputNumber
          values={props.values}
          disabled={props.disabled}
          keyLoop={props.keyLoop}
          isFormList={props.isFormList}
        />
      ) : props.values.type === FormItemType.INPUT_TEXT ? (
        <Input
          values={props.values}
          disabled={props.disabled}
          keyLoop={props.keyLoop}
          isFormList={props.isFormList}
        />
      ) : props.values.type === FormItemType.TEXT_AREA ? (
        <TextArea
          values={props.values}
          disabled={props.disabled}
          isFormList={props.isFormList}
        />
      ) : props.values.type === FormItemType.SELECT ? (
        <SelectOption
          values={props.values}
          disabled={props.disabled}
          form={props.form}
          isFormList={props.isFormList}
        >
          {!!props.values.optionalOption && (
            <RenderFormItem
              form={props.form}
              values={props.values.optionalOption.children}
              disabled={props.disabled}
              isFormList={props.isFormList}
            />
          )}
        </SelectOption>
      ) : props.values.type === FormItemType.IMAGE_MARKER ? (
        <MarkerImage
          values={props.values}
          disabled={props.disabled}
          form={props.form}
          cusname={props.cusname}
        >
          {!!props.values.optionalOption && (
            <RenderFormItem
              form={props.form}
              values={props.values.optionalOption.children}
              disabled={props.disabled}
              isFormList={props.isFormList}
            />
          )}
        </MarkerImage>
      ) : props.values.type === FormItemType.MEDIA ? (
        <DicomMedia
          visitInfo={props.visitInfo}
          params={{ visitId: 0 }}
          form={props.form}
          disabled={props.disabled}
          cusname={props.cusname}
          value={props.values}
          fileList={props.fileList}
        />
      ) : props.values.type === FormItemType.CHOICE ? (
        <Choice
          values={props.values}
          form={props.form}
          disabled={props.disabled}
          isFormList={props.isFormList}
        >
          {!!props.values.children && (
            <>
              <RenderFormItem
                form={props.form}
                values={props.values.children}
                disabled={props.disabled}
                isFormList={props.isFormList}
              />
            </>
          )}
          {/* </>;
          }} */}
        </Choice>
      ) : props.values.type === FormItemType.FORM_LIST_RelativeName ? (
        <FormItemListRelative
          values={props.values}
          form={props.form}
          disabled={props.disabled}
        >
          {!!props.values.children && (
            <RenderFormItem
              form={props.form}
              values={props.values.children}
              disabled={props.disabled}
              isFormList={props.isFormList}
            />
          )}
        </FormItemListRelative>
      ) : props.values.type ===
        FormItemType.FORM_LIST_RelativeName_Question2 ? (
        <FormItemListRelativeQuestion2
          values={props.values}
          form={props.form}
          disabled={props.disabled}
        >
          {!!props.values.children && (
            <RenderFormItem
              form={props.form}
              values={props.values.children}
              disabled={props.disabled}
              isFormList={props.isFormList}
            />
          )}
        </FormItemListRelativeQuestion2>
      ) : props.values.type === FormItemType.FORM_LIST_RelativeDoctor ? (
        <FormItemDoctor
          values={props.values}
          form={props.form}
          visitInfo={props.visitInfo}
          fileList={props.fileList}
        >
          {!!props.values.children && (
            <RenderFormItem
              form={props.form}
              values={props.values.children}
              disabled={props.disabled}
              isFormList={props.isFormList}
            />
          )}
        </FormItemDoctor>
      ) : props.values.type === FormItemType.FORM_LIST_RelativeDoctorMass ? (
        <FormItemDoctorMass
          values={props.values}
          form={props.form}
          visitInfo={props.visitInfo}
          fileList={props.fileList}
        >
          {!!props.values.children && (
            <RenderFormItem
              form={props.form}
              values={props.values.children}
              disabled={props.disabled}
              isFormList={props.isFormList}
            />
          )}
        </FormItemDoctorMass>
      ) : (
        <></>
      )}
    </>
  );
};

export default CreateLayoutFormItem;
