import type { IStatus } from '@Components/CCard/CustomCard'
import type { IFileInfo } from './FileItem';
import CustomCard from '@Components/CCard/CustomCard'

import React from 'react'
import FileItem from './FileItem';
import { Col, Empty, Row, Tooltip } from 'antd';

interface Props {
  title: string;
  status?: IStatus[]
  subTitle?: React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
  onDownload?: VoidFunction;
  onViewDICOM?: VoidFunction;
  extraAction?: React.ReactNode[];
  fileList?: IFileInfo[]
}

const ViewFileInfo = ({ fileList, title, subTitle, status, onDownload, onViewDICOM, extraAction }: Props) => {
  const onDelete = (i: any): void => {

  }

  return (
    <CustomCard
      title={title || ""}
      subTitle={subTitle}
      status={status}
      onDownload={!!fileList?.length ? onDownload : undefined}
      onViewDICOM={onViewDICOM}
      extraAction={extraAction}
    >
      {!!fileList?.length ? (
        <Row gutter={[6, 6]}>
          {
            fileList?.map((item) => (
              <Col span={12} key={item.id}>
                <Tooltip placement="topLeft" title={item.name}>
                  <FileItem onDelete={onDelete} value={item} />
                </Tooltip>
              </Col>
            ))
          }
        </Row>
      ) : (
        <Row gutter={[6, 6]} justify='center'>
          <Col><Empty /></Col>
        </Row>
      )
      }
    </CustomCard>
  )
}

export default ViewFileInfo
