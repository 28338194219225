import { Col, Layout, Row } from "antd";
import { Outlet } from "react-router-dom";
import Copyright from "@Components/Footer/Copyright";
import RenderLogo from "@Components/Logo/_RenderLogo";
import useCheckMediaQuery from "@Layout/plugin/Responsive/useCheckMediaQuery";
import "./styles/index.less";
const { Header, Content, Footer } = Layout;

const DefaultLayout = () => {
  const { isFullHD, isDesktop, isLargeDesktop } = useCheckMediaQuery();

  return (
    <Layout id="defaultLayout-layout-custom-trigger">
      <Header className="header">
        <Row
          justify={isFullHD || isDesktop || isLargeDesktop ? "start" : "center"}
        >
          <Col>
            <RenderLogo
              src={{
                sm: "/images/logo-dashboard-sm.png",
                lg: "/images/logo-dashboard.png",
              }}
            />
          </Col>
        </Row>
      </Header>
      <Content style={{ padding: "0 50px", height: "100%" }}>
        <Outlet />
      </Content>
      <Footer style={{ textAlign: "center" }}>
        <Copyright />
      </Footer>
    </Layout>
  );
};

export default DefaultLayout;
