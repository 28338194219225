import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuthContextDispatch, useAuthContextState } from '@AuthContext/store';
import { FormPatientFields } from '@Interface/patient';
import { GetPatientByIdAPI } from '@Services/api/patient';
import { _RES_validateError } from '@Utils/validate';

import { Avatar, Col, Divider, Form, Image, Row, Space, Spin, Tag } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import Breadcumb from '@Components/Breadcumb';
import PageHeader from '@Components/PageHeader';
import PagePaper from '@Components/PagePaper';
import moment from 'moment';
import FormPatient from '../components/FormPatient';

type Props = { patiantId?: number };

const ViewPatient = (props: Props) => {
  const params = useParams() as { id: string };
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingConfirmLoading, setIsLoadingConfirmLoading] = useState<boolean>(false);
  const [patientInfo, setPatientInfo] = useState<FormPatientFields>();
  const [patientUuid, setPatientUuid] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [profileImg, setProfileImg] = useState<any>();
  const [onEdit, setOnEdit] = useState<boolean>(false);
  // const [locationMap, setLocationMap] = useState<any>();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  useEffect(() => {
    props.patiantId && getPatientById(String(props.patiantId));
    return () => {};
  }, [props.patiantId]);

  const getPatientById = async (patientId: string) => {
    try {
      setIsLoading(true);
      const keyOnLoad = 'getPatientById';
      const res = await GetPatientByIdAPI(patientId, token.accessToken);
      const dataUser = res.result[0];
      const dataformQuestion = dataUser.questionnaire.formQuestion;

      if (dataformQuestion.birthday) {
        dataformQuestion.birthday = moment(dataformQuestion.birthday);
      }

      form.setFieldsValue({
        ...dataUser,
        birthday: dataUser.dateOfBirth ? moment(dataUser.dateOfBirth) : dataUser.dateOfBirth,
        avatar: dataUser.imageUrl,
        ...dataformQuestion,
      });
      setProfileImg({ url: dataUser.imageUrl });
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            const patientInfoInitialValue = {
              hn: res.result[0]['hn'],
              id: res.result[0]['id'],
              uuid: res.result[0]['uuid'],
              phone: res.result[0]['phone'],
              email: res.result[0]['email'],
              idCard: res.result[0]['idCard'],
              address: res.result[0]['address'],
              birthday: res.result[0]['dateOfBirth'],
              fullName: res.result[0]['fullName'],
              ...res.result[0]['questionnaire'],
            };
            setPatientInfo(patientInfoInitialValue);
            setPatientUuid(res.result[0]['uuid']);
            setIsLoading(false);
          },
        },
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const formdata = new FormData();
  const onFinish = async (value: FormPatientFields) => {
    try {
      // setIsLoading(true);
      // setIsLoadingConfirmLoading(true);
      // const keyOnLoad = "EditPatient";
      // const dataField = {
      //     hn: value.hn || "",
      //     // sn: value.sn || "",
      //     email: value.email,
      //     phone: value.phone.replaceAll("-", ""),
      //     idCard: value.idCard.replaceAll(" ", ""),
      //     address: value.address,
      //     fullName: value.fullName,
      //     dateOfBirth: moment(value.birthday, 'YYYY-MM-DD'),
      //     imageId: 0
      // };
      // const dataAddFieldQuestionnaire = {
      //     ...dataField,
      //     questionnaire: JSON.stringify(
      //         removeKeyObj({ ...value }, ["avatar", "birthday", ...Object.keys(dataField)])
      //     ),
      // };
    } catch (error) {
      setIsLoading(false);
      setIsLoadingConfirmLoading(false);
    }
  };

  return (
    <div className="h-100 flex-center flex-column" style={{ position: 'relative' }}>
      <div className="w-100">
        <PageHeader
          title="ผู้ป่วย"
          // subTitle="ข้อมูลผู้ป่วย"
          setLayout={{ md1: 7, md2: 17 }}
          breadcumb={
            <Breadcumb
              value={[
                {
                  title: 'ข้อมูลผู้ป่วย',
                  path: `/patient/view-edit/${params.id}`,
                },
              ]}
            />
          }
        />
      </div>
      <div className="w-100 " style={{ flex: 1 }}>
        <Row className="h-100">
          <Col span={24}>
            <PagePaper>
              <Row justify="space-between">
                <Col>
                  <Space size="middle">
                    <div>
                      {isLoading ? (
                        <Spin indicator={antIcon} spinning={isLoading} />
                      ) : (
                        <Avatar
                          src={
                            <Image
                              border-radius="50%"
                              src={(!!profileImg && profileImg.url) || '/images/ProfileDefualt.svg'}
                              preview={false}
                              // loading={"lazy"}
                            />
                          }
                        />
                      )}
                    </div>
                    <div>
                      <div>{patientInfo?.fullName}</div>
                      <Tag color="#ff748e">
                        <span>HN : {patientInfo?.hn || 'ไม่พบข้อมูล'}</span>
                      </Tag>
                    </div>
                  </Space>
                </Col>
              </Row>
              <Divider />
              <FormPatient
                form={form}
                onFinish={onFinish}
                isLoading={isLoading}
                initialValues={patientInfo}
                onEdit={onEdit}
                profileImg={profileImg}
              />
            </PagePaper>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewPatient;
