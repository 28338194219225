import { Drawer } from 'antd';
import { useCollapsedContextState, useCollapsedContextDispatch } from '@Layout/plugin/SidebarCollapsed/context/store';

// import RenderLogo from './_RenderLogo';
// import RenderMenu from './_RenderMenu';
// import useBreakpoint from '@Lib/useBreakpoint';

type Props = {
  collapsed?: boolean
}

const SideBarMobile = (props: Props) => {
  const { width, collapsed } = useCollapsedContextState()
  const { _setCollapsed } = useCollapsedContextDispatch()

  return (
    <Drawer
      title="AICEDA :: ABUS"
      width={width}
      closable={false}
      onClose={() => _setCollapsed(!collapsed)}
      visible={collapsed}
    >

    </Drawer>
  )
}

export default SideBarMobile

