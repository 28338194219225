import { MenuOutlined } from '@ant-design/icons';
import { useCollapsedContextDispatch, useCollapsedContextState } from '@Layout/plugin/SidebarCollapsed/context/store';
import { Col, Layout, Row } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';
import './styles/index.less';

import useBreakpoint from '@Lib/useBreakpoint';
import Profile from './Profile';
import RenderSideBar from './RenderSideBar';
import SideBarMobile from './SideBarMobile';

const { Header, Content } = Layout;
const ABusDashboardLayout = () => {
  const screen = useBreakpoint();
  const { collapsed } = useCollapsedContextState();
  const { _setCollapsed } = useCollapsedContextDispatch();
  const toggle = () => {
    _setCollapsed(!collapsed);
  };

  return (
    <Layout id="ABUS-layout-custom-trigger">
      {screen.includes('xs') && <SideBarMobile />}
      {!screen.includes('xs') && <RenderSideBar />}
      <Layout className="site-layout">
        <Header
          className={
            !!collapsed
              ? 'site-layout-background header header-Collap'
              : 'site-layout-background header header-NoCollap'
          }
          style={{
            overflow: 'hidden',
            height: '100vh',
            position: 'fixed',
            zIndex: 1,
            left: !!collapsed ? 80 : 280,
            top: 0,
            bottom: 0,
            width: !!collapsed ? 'calc( 100vw - 80px)' : 'calc( 100vw - 280px)',
          }}
        >
          <Row justify="space-between" style={{ width: '100%' }}>
            <Col>
              {React.createElement(MenuOutlined, {
                className: 'trigger',
                onClick: toggle,
              })}
            </Col>
            <Col>
              <Profile />
            </Col>
          </Row>
        </Header>

        <Content
          className="site-layout-content"
          style={{
            marginTop: '5rem',
            marginLeft: !!collapsed ? '5.5rem' : '18.8rem',
            // transition: "1s",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default ABusDashboardLayout;
