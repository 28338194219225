import config from "@Config/app.config";
import { RES } from "@Interface/service";
import { message } from "antd";
type Config = {
  message?: string;
  runOption: () => void;
};
export const _RES_validateError = (
  res: RES | null,
  keyOnLoad: String,
  callback: {
    Error?: Config;
    Code_401?: Config;
    Code_200?: Config;
    Code_400?: Config;
  }
) => {
  if (!res) {
    message.error({
      content: "การติดต่อกับเซิร์ฟเวอร์มีปัญหาขัดข้อง",
      key: keyOnLoad,
      duration: config.duration || 5,
    });
    throw new Error(`Code null:การติดต่อกับเซิร์ฟเวอร์มีปัญหาขัดข้อง`);
  }
  if (res.code === 401) {
    !!res?.message &&
      message.error({
        content: callback.Code_401?.message || res.message || "...error 401",
        key: keyOnLoad,
        duration: config.duration || 5,
      });
    callback.Code_401?.runOption();
    throw new Error(`Code ${res.code}:${res.message}`);
  }
  if (res.code === 400) {
    !!res?.message &&
      message.error({
        content:
          callback.Code_400?.message || res.message || "...error message",
        key: keyOnLoad,
        duration: config.duration || 5,
      });
    callback.Code_400?.runOption();
    throw new Error(`Code ${res.code}:${res.message}`);
  }

  if (res.code === 200) {
    !!callback.Code_200 &&
      (callback.Code_200?.message || !!res?.message) &&
      message.success({
        content:
          callback.Code_200?.message || res.message || "...success message",
        key: keyOnLoad,
        duration: config.duration || 5,
      });
    callback?.Code_200?.runOption();
    return true;
  } else {
    !!res?.message
      ? message.error({
          content: callback.Error?.message || res.message || "...error",
          key: keyOnLoad,
          duration: config.duration || 5,
        })
      : message.error("การติดต่อกับเซิร์ฟเวอร์มีปัญหาขัดข้อง");
    callback.Error?.runOption();
    throw new Error(`Code ${res.code}:${res.message}`);
  }
};
