import { _CustomItemQuestionItem } from "@Components/form/CreateFormItem/interface";
import { VisitField, VisitFiles } from "@Interface/visit";
import { determineRegion } from "@Utils/utils";
import { Col, Form, FormInstance, Input, Radio, Row } from "antd";
import React, { useEffect } from "react";
import RenderFormItem from "../../../_RenderFormItem";
// const { Option } = Select;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  form: FormInstance<any>;
  values: _CustomItemQuestionItem;
  disabled?: boolean;
  visitInfo?: VisitField | null;
  fileList?: VisitFiles[];
}

const FormItemDoctor = ({ values, disabled, fileList, ...props }: Props) => {
  const watchForm = Form.useWatch(
    "diagnosisFormGroup1_question1_location",
    props.form
  );
  const valueForm = Form.useWatch(values["keyName"], props.form);

  useEffect(() => {
    if (watchForm?.length > 0) {
      const newFields = watchForm?.map((e: any) => {
        const location = determineRegion(e.top, e.left);
        const key = `${e.top}_${e.left}`;
        const resultDiagnosisForm =
          props.visitInfo?.diagnosisForm?.formData?.[values["keyName"]] || [];
        const newValueForm = [...(valueForm || []), ...resultDiagnosisForm];
        const findOldContent = newValueForm?.find(
          (el: any) => el?.name === key
        );
        if (findOldContent) {
          return findOldContent;
        } else {
          return {
            name: key,
            location: location,
          };
        }
      });
      // Set preset values for the fields
      props.form.setFieldsValue({
        [values["keyName"]]: [...newFields],
      });
    } else {
      props.form.setFieldsValue({
        [values["keyName"]]: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form, watchForm, valueForm]);

  return (
    <>
      <Form.List name={values["keyName"]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const plot = `( Plot No : ${key + 1} )`;
              const choice = props.form.getFieldValue([
                values["keyName"],
                name?.toString(),
                values?.choice?.[0]?.keyName,
              ]);
              return (
                <div
                  className="box"
                  key={key}
                  style={{ padding: 20, minHeight: "100px" }}
                >
                  <Row align="middle" justify="space-between">
                    <Col>
                      <span>{values.title}</span>
                      <span>{plot}</span>
                    </Col>
                    {/* <Col>
                      <Button
                        type="link"
                        size="middle"
                        onClick={() => remove(name)}
                        icon={<MinusCircleOutlined />}
                        style={{ backgroundColor: "#ff748e", color: "white" }}
                      >
                        Delete
                      </Button>
                    </Col> */}
                    <Col>
                      {!!values.choice && (
                        <>
                          <RenderFormItem
                            form={props.form}
                            values={values.choice}
                            disabled={disabled}
                            isFormList={String(name)}
                          />
                        </>
                      )}
                    </Col>
                  </Row>
                  {choice?.toLowerCase() === "yes" ? (
                    <>
                      <Row
                        key={key}
                        align="middle"
                        justify="space-between"
                        gutter={[16, 16]}
                      >
                        <Col span={24}>
                          {!!values.children && (
                            <RenderFormItem
                              form={props.form}
                              values={values.children}
                              isFormList={String(name)}
                              disabled={disabled}
                            />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        {!!values.media && (
                          <>
                            <RenderFormItem
                              form={props.form}
                              values={values.media}
                              disabled={disabled}
                              cusname={String(name)}
                              visitInfo={props.visitInfo}
                              fileList={fileList}
                            />
                          </>
                        )}
                      </Row>

                      {!!values.children &&
                        values.children.map((item, ind: number) => {
                          if (item.index === "end") {
                            return (
                              <Row key={ind} gutter={[16, 16]} align="middle">
                                <Col>{item.title}</Col>
                                <Col>
                                  <Form.Item
                                    name={[name, item["keyName"]]}
                                    key={item["keyName"]}
                                  >
                                    <Input
                                      style={{ marginTop: "25px" }}
                                      disabled={disabled}
                                      className="form-masscyst"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            );
                          } else return null;
                        })}
                    </>
                  ) : null}
                </div>
              );
            })}
            {fields.length === 0 && (
              <Form.Item label={values.title}>
                <Radio checked disabled className="content-radio">
                  None
                </Radio>
              </Form.Item>
            )}
            {/* <Form.Item>
              <Button
                type="default"
                size="large"
                onClick={() => add()}
                icon={<PlusCircleOutlined />}
                style={{ backgroundColor: "#ff748e", color: "white" }}
              >
                Add Cyst
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item> */}
          </>
        )}
      </Form.List>
    </>
  );
};

export default FormItemDoctor;
