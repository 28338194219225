import { Col, Row, Space, Image, Tag, Avatar } from "antd";
import { Typography } from "antd";
import { VisitField } from "@Interface/visit";

const { Title } = Typography;
type Props = {
  visitInfo?: VisitField | null;
  testprops?: string;
  onDownload?: any;
  download?: any;
  imgProfile?: string | any;
  id?: number;
  formdoctor?: boolean;
  doctorInfo?: any;
  isDoctor?: boolean;
};

const PatientStatus = (props: Props) => {
  const visitProgram = props.visitInfo?.visitProgram
    ?.map((e) => e.program?.name)
    .filter((item, ind, arr) => item && arr.indexOf(item) === ind);

  return (
    <Row justify="space-between">
      <Col>
        <Row gutter={[20, 20]}>
          <Col>
            <Space size="middle">
              <div>
                <Avatar
                  src={
                    <Image
                      preview={false}
                      border-radius="50%"
                      src={
                        !!props.doctorInfo
                          ? props.doctorInfo[0].doctor.image ||
                            "/images/ProfileDefualt.svg"
                          : (!!props.imgProfile && props.imgProfile) ||
                            "/images/ProfileDefualt.svg"
                      }
                    />
                  }
                />
              </div>
              <div>
                <Title level={5}>
                  {!!props.doctorInfo
                    ? props.doctorInfo[0].doctor.fullName || "ไม่พบข้อมูล"
                    : props.visitInfo?.patient?.fullName || "ไม่พบข้อมูล"}
                </Title>
                {!!props.doctorInfo ? (
                  <Tag color="#000000">
                    {props.doctorInfo[0].doctor.role.name}
                  </Tag>
                ) : (
                  <Tag
                    className="background-tag"
                    style={{ color: "#f0f2f5", border: "unset", minWidth: 70 }}
                  >
                    <span>
                      HN: {props.visitInfo?.patient?.hn || "ไม่พบข้อมูล"}
                    </span>
                  </Tag>
                )}
                {!!props.doctorInfo ? (
                  <></>
                ) : (
                  <span className="status">{/* โปรแกรม */}</span>
                )}

                {/* {(props.visitInfo?.status !== "RESERVED") && (
              <span>
                {props.visitInfo?.taskId && (
                  <Tag color="#767676">AI กำลังประมวลผล</Tag>
                )}
                <Tag color="#ff748e">{props.visitInfo?.statusAi}</Tag>

              </span>
            )} */}
              </div>
            </Space>
          </Col>
          {(!!props.doctorInfo || !!props.isDoctor) && (
            <Col
              style={
                props.isDoctor
                  ? {
                      display: "flex",
                      alignItems: "center",
                      borderLeft: "1px solid #608DF5",
                    }
                  : { borderLeft: "1px solid #608DF5" }
              }
            >
              <Space size="middle">
                {props.isDoctor ? null : (
                  <div>
                    <Avatar
                      src={
                        <Image
                          preview={false}
                          border-radius="50%"
                          src={
                            (!!props.imgProfile && props.imgProfile) ||
                            "/images/ProfileDefualt.svg"
                          }
                        />
                      }
                    />
                  </div>
                )}
                <div>
                  {props.isDoctor ? null : (
                    <Title level={5}>
                      {props.visitInfo?.patient?.fullName || "ไม่พบข้อมูล"}
                    </Title>
                  )}
                  {props.isDoctor ? null : (
                    <Tag
                      className="background-tag"
                      style={{
                        color: "#f0f2f5",
                        border: "unset",
                        minWidth: 70,
                      }}
                    >
                      <span>
                        HN: {props.visitInfo?.patient?.hn || "ไม่พบข้อมูล"}
                      </span>
                    </Tag>
                  )}
                  {!!props.doctorInfo || !!props.isDoctor ? (
                    <span className="status">
                      {/* โปรแกรม */}
                      <Space size="small">
                        {visitProgram?.map((item, ind) => (
                          <Tag key={ind} color="#0066FF">
                            {item || "ไม่พบข้อมูล"}
                          </Tag>
                        ))}
                      </Space>
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </Space>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default PatientStatus;
