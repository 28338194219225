import { Fetch } from "@Utils/fetch/fetch";

export const getRoleAPI = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    token: token,
    method: "GET",
    path: "/v1/role",
  });
  return res;
};

export const _RoleService = {
  getRoleAPI,
};

export default _RoleService;
