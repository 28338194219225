import { FetchFormData } from "@Utils/fetch/fetch";

export const CreateNewMediaAPI = async <T = any>(
    data: FormData,
    token: string
) => {
    const res = await FetchFormData<T>(
        {
            token: token,
            method: "POST",
            path: "/v1/media-object/media",
        },
        data
    );
    return res;
};