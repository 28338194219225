import { Col, Row, RowProps, ColProps } from "antd";

interface PropsRowLayout extends React.HTMLAttributes<HTMLDivElement> {
  propsRow?: RowProps | null;
}

interface PropsColLayout extends React.HTMLAttributes<HTMLDivElement> {
  colSpan?: ColProps | null;
}

export const RowLayout = (props: PropsRowLayout) => {
  return <Row {...props.propsRow}>{props.children}</Row>;
};

export const ColLayout = (props: PropsColLayout) => {
  return <Col {...props.colSpan}>{props.children}</Col>;
};

//----- example -----
// questions: [
//       {
//         title: "",
//         keyName: "row1",
//         type: "row",
//         propsRow: { justify: "center" },
//         children: [
//           {
//             title: "",
//             keyName: "column1",
//             type: "column",
//             colSpan: { md: 24 },
//             children: [],
//           },
//           {
//             title: "",
//             keyName: "column2",
//             type: "column",
//             colSpan: { md: 12 },
//             children: [],
//           },
//         ],
//       }]
