import { Grid } from "antd";
const { useBreakpoint } = Grid;

// type ScreenBreakpoint = Array<"xs" | "sm" | "md" | "ld" | "xl" | "xxl">;

const UseBreakpoint = () => {
  const screens = useBreakpoint();
  return Object.entries(screens)
    .filter((screen) => screen[1])
    .map((screen) => screen[0]);
};
export default UseBreakpoint;
