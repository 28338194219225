/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { VisitField } from "@Interface/visit";
import VisitFormItem from "@Components/form/VisitFormItem";
import { useLayoutEffect } from "react";

type Props = {
  initialValues?: VisitField | null;
};

const ViewInfo = (props: Props) => {
  const [form] = Form.useForm();
  useLayoutEffect(() => {
    !!props.initialValues &&
      form.setFieldsValue({
        visitNo: props.initialValues.visitNo,
        lat: props.initialValues?.lat,
        long: props.initialValues?.long,
        weight: props.initialValues?.weight,
        height: props.initialValues?.height,
        bmi: props.initialValues?.bmi,
        pressure: props.initialValues?.pressure,
        temperature: props.initialValues?.temperature,
      });
  }, [props.initialValues]);

  return (
    <Form
      form={form}
      size="large"
      layout="vertical"
      className="FormUpload"
      requiredMark="optional"
    >
      <VisitFormItem location={false} viewer />
    </Form>
  );
};

export default ViewInfo;
