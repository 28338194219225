import React from 'react';
import { ContextProvider } from './context/store';

type Props = {
  children: React.ReactNode | React.ReactElement;
}

const SidebarCollapsedProvider = (props: Props) => {
  return (
    <ContextProvider>{props.children}</ContextProvider>
  )
}

export default SidebarCollapsedProvider;