import React from "react";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { UserOutlined } from "@ant-design/icons";
import "./styles/index.less";
interface Props {
  disbled?: boolean;
  value?: any;
  valueImage?: any;
  onChange?: (file: any) => void;
}

const ImageUpload = ({ value, onChange, valueImage, disbled }: Props) => {
  const [fileLists, setFileLists] = React.useState<any>([]);

  React.useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, valueImage]);

  const initial = () => {
    if (!!valueImage && !!valueImage?.url) {
      setFileLists([
        {
          uid: valueImage.uuid || "",
          name: valueImage.fileName || "",
          status: "done",
          url: valueImage.url,
        },
      ]);
    } else {
      if (typeof value === "object") return;
      setFileLists([]);
    }
  };

  const handleOnChange = (newFileList: any) => {
    const { file } = newFileList;
    if (!!onChange) {
      onChange(file);
    }
    setFileLists(newFileList.fileList || "/images/ProfileDefualt.svg");
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);

    if (imgWindow) {
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  return (
    <div>
      <ImgCrop grid>
        <Upload
          disabled={disbled || false}
          name="image"
          fileList={fileLists}
          onPreview={onPreview}
          listType="picture-card"
          onChange={handleOnChange}
          className="ImageUpload"
          customRequest={({ _, onSuccess }: any) => onSuccess("ok")}
        >
          {(fileLists === undefined || fileLists.length < 1) && (
            <UserOutlined style={{ fontSize: "35px", color: "gray" }} />
          )}
        </Upload>
      </ImgCrop>
      {/* <Button onClick={() => {}} type="link" style={{ marginTop: 10 }}> */}
      <span className="text-underline color-primary">อัพโหลดรูปภาพ</span>
      {/* </Button> */}
    </div>
  );
};
export default ImageUpload;
