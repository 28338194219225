/* eslint-disable @typescript-eslint/no-unused-vars */
import { _dataTypeOf } from "@yim/util-tool-ts";

export const _findObjectOfArrayById = (
  array: { id: string | number; [key: string]: any }[],
  id: number | string
): { [key: string]: any } | null => {
  const obj = array.find((x) => x.id.toString() === id.toString());
  return !!obj ? obj : null;
};
export const _findObjectOfArrayByKeyName = (
  array: { [key: string]: any }[],
  value: number | string,
  keyName: string
): { [key: string]: any } | null => {
  const obj = array.find((x) => x[keyName] === value);
  return !!obj ? obj : null;
};

export const _findIndexOfArrayById = (
  array: { id: string | number; [key: string]: any }[],
  id: number | string
): number => {
  const index = array
    .map((x) => {
      return x.id;
    })
    .indexOf(id);
  return index > 0 ? index : 0;
};

export const isTokenExpired = (token: string) => {
  if (!token.length) return true;
  const payloadBase64 = token.split(".")[1];
  const decodedJson = Buffer.from(payloadBase64, "base64").toString();
  const decoded = JSON.parse(decodedJson);
  const exp = decoded.exp;
  const expired = Date.now() >= exp * 1000;
  return expired;
};

export const rowClassName = (record: any, index: number) => {
  let className = index % 2 ? "deep-gray" : "shallow-gray";
  return className;
};

function deepFreeze<T>(obj: T) {
  var propNames = Object.getOwnPropertyNames(obj);
  for (let name of propNames) {
    let value = (obj as any)[name];
    if (value && typeof value === "object") {
      deepFreeze(value);
    }
  }
  return Object.freeze(obj);
}

export const _cleanObject = {
  keysOfValueFileType: (obj: object) => {
    return Object.keys(obj).filter((key) => {
      let values = (obj as any)[key];
      return typeof values.file?.name === "string";
    });
  },
  keys: (obj: object) => {
    return Object.keys(obj).filter((key) => {
      let values = (obj as any)[key];
      return !!values;
    });
  },
  obj: (obj: object) => {
    return Object.keys(obj)
      .filter((key) => {
        let values = (obj as any)[key];
        return !!values;
      })
      .reduce((previousValue: any, currentValue) => {
        return { [currentValue]: (obj as any)[currentValue], ...previousValue };
      }, {});
  },
};

export const convertJsonToFormData = (data: any, keydata?: string) => {
  try {
    const formData = new FormData();
    const _appendDataArray = (keys: string | null, dataArray: []) => {
      if (!keys) throw new Error("_appendDataArray : No key to append data");
      dataArray.forEach((data: any, index: number) => {
        _appendData(`${keys}[${index}]`, data);
      });
    };
    const _appendDataObject = (
      keyObject: string | null,
      dataAObject: { [keys: string]: any }
    ) => {
      Object.keys(dataAObject).forEach((key) => {
        _appendData(keyObject ? `${keyObject}[${key}]` : key, dataAObject[key]);
      });
    };
    const _appendDataArrayOfObject = (
      keys: string | null,
      dataArray: { [keys: string]: any }
    ) => {
      if (!keys)
        throw new Error("_appendDataArrayOfObject : No key to append data");
      dataArray.forEach((data: any, index: number) => {
        if (_dataTypeOf(data) === "object")
          _appendDataObject(`${keys}[${index}]`, data);
        else if (_dataTypeOf(data) === "array")
          _appendDataArray(`${keys}[${index}]`, data);
        else formData.append(keys, data);
      });
    };
    const _appendData = (keys: string, data: any) => {
      if (_dataTypeOf(data) === "array of object")
        _appendDataArrayOfObject(keys, data);
      else if (_dataTypeOf(data) === "object") _appendDataObject(keys, data);
      else if (_dataTypeOf(data) === "array") _appendDataArray(keys, data);
      else formData.append(keys, data);
    };

    if (_dataTypeOf(data) === "object") _appendDataObject(null, data);
    else if (_dataTypeOf(data) === "array") _appendDataArray(null, data);
    else if (_dataTypeOf(data) === "array of object")
      _appendDataArrayOfObject(null, data);
    else keydata && formData.append(keydata, data);
    return formData;
  } catch (error) {
    throw new Error("_appendFormData : can't append data");
  }
};

export const formatNumberWithCommaAndDecimal = (num: number): string => {
  const hasDecimal = num % 1 !== 0;
  const formattedNumber = num.toLocaleString();
  return hasDecimal ? formattedNumber : `${formattedNumber}.00`;
};

export const determineRegion = (top: any, left: any) => {
  top = Number(top.toFixed(1));
  left = Number(left.toFixed(1));
  //RIGHT
  if (top >= 17.5 && top < 33.7 && left >= 10.2 && left < 18.4) {
    return "Right Upper Outer";
  } else if (top >= 12.5 && top < 33.5 && left >= 18.9 && left < 32.5) {
    return "Right Middle Upper";
  } else if (top >= 18.3 && top < 33.7 && left >= 32.2 && left < 41.1) {
    return "Right Upper Inner";
  } else if (top >= 36.2 && top < 62.4 && left >= 8.0 && left < 18.2) {
    return "Right Outer";
  } else if (top >= 35.3 && top < 63 && left >= 19.0 && left < 32.5) {
    return "Right Middle";
  } else if (top >= 35.3 && top < 63 && left >= 33.0 && left < 43.4) {
    return "Right Inner";
  } else if (top >= 64.6 && top < 80.2 && left >= 10.1 && left < 18.2) {
    return "Right Lower Outer";
  } else if (top >= 64.4 && top < 83.6 && left >= 19.0 && left < 32.4) {
    return "Right Middle Lower";
  } else if (top >= 64.5 && top < 81.1 && left >= 32.2 && left < 41.5) {
    return "Right Lower Inner";
  }
  //LEFT
  else if (top >= 18.5 && top < 34.0 && left >= 54.3 && left < 62.4) {
    return "Left Upper Inner";
  } else if (top >= 15.1 && top < 33.4 && left >= 62.8 && left < 76.5) {
    return "Left Middle Upper";
  } else if (top >= 18.3 && top < 33.7 && left >= 77.1 && left < 85.2) {
    return "Left Upper Outer";
  } else if (top >= 35.9 && top < 63.0 && left >= 52.0 && left < 62.4) {
    return "Left Inner";
  } else if (top >= 35.9 && top < 63 && left >= 62.8 && left < 76.5) {
    return "Left Middle";
  } else if (top >= 35.5 && top < 63.3 && left >= 77.0 && left < 87.2) {
    return "Left Outer";
  } else if (top >= 64.6 && top < 81.1 && left >= 54.0 && left < 62.6) {
    return "Left Lower Inner";
  } else if (top >= 64.9 && top < 83.8 && left >= 63.1 && left < 76.5) {
    return "Left Middle Lower";
  } else if (top >= 64.8 && top < 80.2 && left >= 77.1 && left < 85.5) {
    return "Left Lower Outer";
  } else {
    return "";
  }
};
