/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { MdDelete } from "react-icons/md";
import { ImDownload3 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { useAuthContextState } from "@AuthContext/store";
import { useAuthContextDispatch } from "@AuthContext/store";
import { PaginationExcludeTotal } from "@Interface/service";

import { _RES_validateError } from "@Utils/validate";
import { rowClassName, _cleanObject } from "@Utils/utils";

import { EyeOutlined } from "@ant-design/icons";
import { PatientItem } from "@Interface/patient";
import { Popconfirm, Space } from "antd";
import { Button, Col, Pagination, Row, Table } from "antd";

import PageHeader from "@Components/PageHeader";
import FormSearch, { SearchFailed } from "@Components/form/FormSearch";
import dayjs from "dayjs";

import "../styles/index.less";
import _DiagnosticsService from "@Services/api/diagnostics";
import _VisitService from "@Services/api/visit";
import useZipDownload2 from "../FormDiagnostics/fileDownload";

type Props = {};

const DiagnosticsList = (props: Props) => {
  const navigate = useNavigate();
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [totalDiagnosticsList, setTotalDiagnosticsList] = useState<number>(0);
  const [downloadAndZip] = useZipDownload2();
  const [diagnostics, setDiagnosticsList] = useState<PatientItem[] | []>([]);
  const [pagination, setPagination] = useState<PaginationExcludeTotal>({
    page: 1,
    perpage: 10,
    startIndex: 1,
  });

  useEffect(() => {
    getDiagnosticsList();

    return () => {};
  }, [pagination]);

  const getDiagnosticsList = async (search?: SearchFailed) => {
    try {
      setIsLoading(true);
      const keyOnLoad = "getDiagnosticsList";
      const params = {
        page: pagination.page,
        limit: pagination.perpage,
        ...search,
      };
      const res = await _DiagnosticsService.GetDiagnosticsAllAPI(
        params,
        token["accessToken"]
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setDiagnosticsList(res.result[0]["data"]);
            setTotalDiagnosticsList(res.result[0]["total"]);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const onCancel = () => {
    getDiagnosticsList();
  };
  const onFinish = (value: SearchFailed) => {
    getDiagnosticsList(_cleanObject.obj(value));
  };

  const onDowload = async (id: number) => {
    const res = await _VisitService.visitDownloadFiles(id, token.accessToken);
    const data = res.result;
    // data.map((data: any) => {
    //   window.open(data.url);
    // });
    downloadAndZip(data);
  };

  const removePatientById = async (visitId: number) => {
    try {
      const keyOnLoad = "removePatientById";
      setIsLoadingConfirm(true);
      const res = await _DiagnosticsService.DeleteDiagnosticsByIdAPI(
        visitId,
        token.accessToken
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "ลบข้อมูลรายชื่อผู้ป่วยสำเร็จ",
          runOption: () => {
            setIsLoading(false);
            setIsLoadingConfirm(false);
            setDiagnosticsList((pervDiagnostics) =>
              pervDiagnostics.filter((p) => p.id !== visitId)
            );
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setIsLoadingConfirm(false);
    }
  };
  const viewDiagnosticsByVisitId = (id: number) => {
    navigate("/diagnostics/view-info/" + id);
  };

  const columns = [
    {
      title: "#",
      key: "id",
      width: 50,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{rowIndex + 1}</span>
      ),
    },
    {
      title: "เลขบัตรประชาชน",
      dataIndex: "idCard",
      key: "idCard",
      // width: 150,
    },
    {
      title: "เลข HN",
      dataIndex: "hn",
      key: "hn",
      // width: 150,
    },
    {
      title: "วันที่",
      dataIndex: "createAt",
      key: "createAt",
      // width: 150,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{dayjs(text).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "fullName",
      key: "fullName",
      // width: 150,
    },
    {
      title: "รายชื่อหมอ",
      dataIndex: "fullName",
      key: "fullName",
      // width: 150,
    },
    {
      title: "ท่ีตั้งของรถตรวจ",
      dataIndex: "location",
      key: "location",
      width: 220,
      render: (data: any, record: PatientItem, rowIndex: number) => (
        <span className="data-webkitline"> {data}</span>
      ),
    },
    {
      title: "จัดการ",
      key: "action",
      width: 250,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <Space>
          <Button
            type="ghost"
            className="flex-center btn-action"
            onClick={() => viewDiagnosticsByVisitId(record.id)}
          >
            <EyeOutlined />
          </Button>
          <Button
            type="link"
            className="flex-center btn-action"
            onClick={() => onDowload(record.id)}
            style={{
              color: "#0980FD",
              background: "#fff",
              border: "1px solid #0980FD",
              width: 42,
              height: 42,
              borderRadius: "50%",
            }}
          >
            <ImDownload3 />
          </Button>
          <Popconfirm
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            title="ยืนยันการลบข้อมูลผู้ป่วย？"
            onConfirm={() => removePatientById(record.id)}
            okButtonProps={{ loading: isLoadingConfirm }}
          >
            <Button type="ghost" className="flex-center btn-action">
              <MdDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <Row>
      <Col span={24}>
        <PageHeader
          title="ข้อมูลรอวินิจฉัย"
          subTitle="ข้อมูลรอวินิจฉัยทั้งหมด"
        />
      </Col>
      <Col span={24} className="content">
        <FormSearch onFinish={onFinish} onCancel={onCancel} type={"default"} />
        <Table
          size="small"
          rowKey="id"
          loading={isLoading}
          columns={columns}
          className="abus-table"
          dataSource={diagnostics}
          pagination={false}
          rowClassName={rowClassName}
          scroll={{ x: 1500 }}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                viewDiagnosticsByVisitId(record.id);
              },
            };
          }}
        />
        <Row justify="end" style={{ marginTop: 20 }}>
          <Col>
            {totalDiagnosticsList / pagination.perpage > 1 && (
              <Pagination
                className="abus-pagination"
                total={totalDiagnosticsList}
                onChange={(page: number, pageSize: number) => {
                  setPagination({
                    ...pagination,
                    page: page,
                    perpage: pageSize,
                    startIndex: page * pageSize,
                  });
                }}
                defaultCurrent={pagination.page}
                defaultPageSize={pagination.perpage}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DiagnosticsList;
