import { CameraOutlined } from '@ant-design/icons';
import { Token } from '@AuthContext/interface/auth';
import { Button } from 'antd';

const header = ({
  currentImg,
  visitId,
  token,
  captureImage,
}: {
  currentImg: string;
  visitId: string;
  token: Token;
  captureImage?: (currentImg: string) => void;
}) => {
  // function dataURLToBlob(dataURL: string): Blob {
  //   const byteString = atob(dataURL.split(',')[1]);
  //   const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  //   const ab = new ArrayBuffer(byteString.length);
  //   const ia = new Uint8Array(ab);
  //   for (let i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }
  //   return new Blob([ab], { type: mimeString });
  // }

  // const captureImage = () => {
  //   const link = document.createElement('a');
  //   link.download = 'abus-ultrasound-capture.png';
  //   link.href = currentImg || '';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);

  //   const formData = new FormData();
  //   const keyName = 'report';
  //   const blob = dataURLToBlob(currentImg);
  //   formData.append('id', visitId);
  //   formData.append(keyName, blob);

  //   _VisitService.UploadAPI(formData, token.accessToken);
  // };

  return (
    // <Header style={headerStyle}>
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '50px',
        backgroundColor: '#001529',
      }}
    >
      {/* <Popconfirm title={test}> */}
      <Button onClick={() => captureImage?.(currentImg)}>
        <CameraOutlined />
      </Button>
      {/* </Popconfirm> */}
    </div>
    // </Header>
  );
};
export default header;
