import CreateFormItem from "@Components/form/CreateFormItem/_RenderFormItem";
import type { ITypeQuestionProp } from "@Interface/patient";
import type { FormInstance } from "antd";

interface Props {
  dataForm?: FormInstance<any> | any;
  questionsForm?: ITypeQuestionProp | null;
}

const IndicationForm = ({ questionsForm, dataForm }: Props) => {
  return (
    <div className="box container-box">
      <div className="card">
        <div className="card-header">Indication</div>
        <div className="card-body">
          {!!questionsForm?.indication &&
            questionsForm?.indication?.map((item: any, ind: number) => (
              <CreateFormItem
                key={ind}
                values={item.questions}
                form={dataForm}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default IndicationForm;
