import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import CustomCard from '@Components/CCard/CustomCard';
import { Col, Image, Modal, Row } from 'antd';
import { useState } from 'react';

interface Props {
  values?: { file: File; fileUrl: string }[];
  onDelete?: (index: number) => void;
}

const CardImage = (props: Props) => {
  return (
    <CustomCard title="Uploads">
      <Row gutter={[10, 10]}>
        {props?.values?.map((item, index) => {
          return (
            <Col sm={12} md={8} lg={8} xl={6} xxl={4} key={index}>
              <ImageItem src={item?.fileUrl} onDelete={() => props?.onDelete?.(index)} />
            </Col>
          );
        })}
      </Row>
    </CustomCard>
  );
};

const ImageItem = ({ src, onDelete }: { src?: string; onDelete?: () => void }) => {
  const [visible, setVisible] = useState(false);
  const toggle = () => {
    setVisible(!visible);
  };

  return (
    <div className="image-wrapper">
      <Image
        preview={false}
        src={src}
        // src={'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'}
      />
      <div className="action-button" id="delete-mask">
        <EyeOutlined className="size-image" onClick={toggle} />
        <DeleteOutlined className="size-image" onClick={onDelete} />
      </div>
      <PreviewImage src={src} visible={visible} toggle={toggle} />
    </div>
  );
};

interface PreviewImageProps {
  src?: string;
  visible: boolean;
  toggle: () => void;
}

const PreviewImage = ({ src, visible, toggle }: PreviewImageProps) => {
  return (
    <Modal visible={visible} onCancel={toggle} width={600} footer={false} maskClosable={false} forceRender>
      <div style={{ padding: 20 }}>
        <Image src={src} preview={false} />
      </div>
    </Modal>
  );
};

export default CardImage;
