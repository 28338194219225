/* eslint-disable react-hooks/exhaustive-deps */
import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import { Button, Col, Form, Popconfirm, Row } from "antd";
import { useNavigate } from "react-router-dom";

import { Role } from "@Components/DrawerProfile";
import PageHeader from "@Components/PageHeader";
import PagePaper from "@Components/PagePaper";
import { CreateNewMediaAPI } from "@Services/api/media";
import { getRoleAPI } from "@Services/api/role";
import {
  DeleteUserByIdAPI,
  getUserByIdAPI,
  PutUserByIdAPI,
} from "@Services/api/user";
import { _RES_validateError } from "@Utils/validate";
import { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useParams } from "react-router-dom";
import FormUser from "../FromUser/index";
import "../styles/index.less";

type Props = {};

export interface UrlImage {
  url: string;
}

const EditUser = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams() as { visitId: string };
  const [form] = Form.useForm();
  const { token } = useAuthContextState();
  const [role, setRole] = useState<Role[]>();
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const { _signOut } = useAuthContextDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingConfirmLoading, setIsLoadingConfirmLoading] =
    useState<boolean>(false);
  const [userImg, setUserImg] = useState<UrlImage>();
  useEffect(() => {
    getRoledata();
    getDataUser();
    return () => {};
  }, []);

  const getRoledata = async () => {
    try {
      const res = await getRoleAPI(token.accessToken);
      setRole(res.result);
    } catch (error) {}
  };

  const getDataUser = async () => {
    try {
      const res = await getUserByIdAPI(params.visitId, token.accessToken);
      const data = res.result[0];
      form.setFieldsValue({ ...data, role: data.role.id });
      setUserImg({ url: data.image });
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (value: any) => {
    try {
      const keyOnLoad = "putUserById";

      const data = {
        phone: value.phone.replaceAll("-", ""),
        email: value.email,
        imageId: 0,
        roleId: value.role,
        fullName: value.fullName,
        agency: value.agency,
        expertise: value.expertise,
        password: value.password || "",
        medicalLicense: value.medicalLicense,
        currentPassword: value.currentPassword || "",
        // relativeInformation: value.relativeInformation,
        // personalInformation: value.personalInformation,
      };

      if (!!value.avatar) {
        const formData = new FormData();
        formData.append("file", value.avatar.originFileObj);
        const res = await CreateNewMediaAPI(formData, token.accessToken);
        data["imageId"] = res.result[0].id;
      }

      // const FormData = convertJsonToFormData(data);
      // if (!!value.avatar) {
      //   FormData.append("avatar", value.avatar.originFileObj);
      // }
      // FormData.append("avatar", value.avatar.originFileObj);

      const res = await PutUserByIdAPI(
        // FormData,
        data,
        params.visitId,
        token.accessToken
      );

      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "แก้ไขข้อมูลรายชื่อผู้ใช้สำเร็จ",
          runOption: () => {
            setIsLoadingConfirmLoading(false);
            navigate("/user-management/view-list");
          },
        },
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const removeUserById = async (userId: string) => {
    try {
      const keyOnLoad = "removeUserById";
      const res = await DeleteUserByIdAPI(Number(userId), token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "ลบข้อมูลรายชื่อผู้ใช้สำเร็จ",
          runOption: () => {
            setIsLoadingConfirmLoading(false);
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="h-100 flex-center flex-column">
      {/* // <div className="flex-center flex-column" style={{ height: "85vh" }}> */}
      <div className="w-100">
        <Row>
          <Col span={24}>
            <PageHeader
              title="รายละเอียดผู้ใช้"
              subTitle="ข้อมูลผู้ใช้"
              action={
                <>
                  {!onEdit ? (
                    <>
                      <Row justify="end" gutter={[16, 16]} align="middle">
                        <Col>
                          <Popconfirm
                            okText="ยืนยัน"
                            cancelText="ยกเลิก"
                            title="ยืนยันการลบข้อมูลผู้ใช้？"
                            placement="bottom"
                            onConfirm={() => removeUserById(params.visitId)}
                            okButtonProps={{ loading: isLoadingConfirmLoading }}
                          >
                            <Button
                              type="ghost"
                              className="flex-center btn-action"
                              style={{ background: "#fff" }}
                            >
                              <MdDelete />
                            </Button>
                          </Popconfirm>
                        </Col>
                        <Col>
                          <Button
                            onClick={() => setOnEdit(!onEdit)}
                            type="primary"
                            size="large"
                            style={{ paddingLeft: 50, paddingRight: 50 }}
                          >
                            แก้ไข
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row justify="end">
                        <Col>
                          <Button
                            onClick={() => setOnEdit(!onEdit)}
                            size="large"
                            style={{
                              paddingLeft: 50,
                              paddingRight: 50,
                              marginRight: 10,
                            }}
                          >
                            ยกเลิก
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            onClick={() => form.submit()}
                            type="primary"
                            size="large"
                            loading={isLoading}
                            style={{ paddingLeft: 50, paddingRight: 50 }}
                          >
                            บันทึก
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              }
            />
          </Col>
        </Row>
      </div>
      <div className="w-100 h-100">
        {/* <Row className="h-100"> */}
        <Row style={{ height: "75vh" }}>
          <Col span={24}>
            <PagePaper>
              <PageHeader title="ข้อมูลผู้ใช้" />
              <div className="h-100 flex-column">
                {/* <div className="h-100  flex-column"> */}
                <div className="w-100">
                  <FormUser
                    form={form}
                    onSubmit={onFinish}
                    role={role}
                    onEdit={onEdit}
                    userImg={userImg}
                  />
                </div>
              </div>
            </PagePaper>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditUser;
