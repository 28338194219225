/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthContextState } from "@AuthContext/store";
import { getDoctorNameAPI } from "@Services/api/user";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
const { RangePicker } = DatePicker;
const { Option } = Select;
type Props = {
  type?: string;
  onCancel: () => void;
  onFinish: (props: SearchFailed) => void;
  data?: any;
};
export interface SearchFailed {
  hn?: string;
  idCard?: string;
  fullName?: string;
  dateRange?: Date[];
  doctorname?: string;
}

export interface Doctor {
  id: number;
  fullName: string;
}

const FormSearch = (props: Props) => {
  const [form] = Form.useForm();
  const { token } = useAuthContextState();
  const [doctorName, setDoctorName] = useState<Doctor[]>();

  useEffect(() => {
    getDoctorData();
    return () => {};
  }, []);

  const getDoctorData = async () => {
    try {
      const res = await getDoctorNameAPI(token.accessToken);
      setDoctorName(res.result);
    } catch (error) {}
  };

  const onReset = () => {
    props.onCancel();
    form.resetFields();
  };

  return (
    <div
      className="form-search"
      style={{ padding: "24px 16px", paddingBottom: 5, background: "#fff" }}
    >
      <Form
        form={form}
        name="search"
        initialValues={{ remember: true }}
        onFinish={props.onFinish}
        autoComplete="off"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col md={16} lg={15}>
            {!!props.type && props.type === "report" && (
              <Row gutter={[24, 24]}>
                <Col md={9} lg={8}>
                  <Form.Item name="doctorname">
                    <Select
                      size="large"
                      showSearch
                      placeholder="ค้นหาชื่อหมอ"
                      optionFilterProp="children"
                      className="Select-role"
                    >
                      {doctorName?.map((data: Doctor) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.fullName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={15} lg={10}>
                  <Form.Item
                    className="label-date"
                    label="วันที่ :"
                    name="dateRange"
                    rules={[
                      { required: false, message: "กรุณาใส่เลขบัตรประชาชน" },
                    ]}
                  >
                    <RangePicker size="large" />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {props.type === "default" && (
              <Row gutter={[24, 24]}>
                <Col md={8} lg={8}>
                  <Form.Item
                    name="idCard"
                    rules={[
                      { required: false, message: "กรุณาใส่เลขบัตรประชาชน" },
                    ]}
                  >
                    <Input size="large" placeholder="เลขบัตรประชาชน" />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8}>
                  <Form.Item
                    name="hn"
                    rules={[
                      { required: false, message: "กรุณาใส่เลข HN / VN" },
                    ]}
                  >
                    <Input size="large" placeholder="เลข HN / VN" />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8}>
                  <Form.Item
                    name="fullName"
                    rules={[
                      { required: false, message: "กรุณาใส่ ชื่อ - สกุล" },
                    ]}
                  >
                    <Input size="large" placeholder="ชื่อ - สกุล" />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {props.type === "user" && (
              <Row gutter={[24, 24]}>
                <Col md={8} lg={8}>
                  <Form.Item
                    name="search"
                    rules={[
                      { required: false, message: "กรุณาใส่ ชื่อ - สกุล" },
                    ]}
                  >
                    <Input size="large" placeholder="ชื่อ - สกุล" />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8}>
                  <Form.Item
                    name="email"
                    rules={[{ required: false, message: "กรุณาใส่อีเมล" }]}
                  >
                    <Input size="large" placeholder="อีเมล" />
                  </Form.Item>
                </Col>
                <Col md={8} lg={8}>
                  <Form.Item
                    name="roleId"
                    rules={[{ required: false, message: "กรุณาเลือกบทบาท" }]}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="บทบาท"
                      optionFilterProp="children"
                      // defaultValue={1}
                      className="Select-role"
                    >
                      {props.data?.map((data: any) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>

          <Col md={8} lg={6}>
            <Form.Item>
              <Row gutter={[12, 12]} justify="end">
                <Col md={12} lg={6}>
                  <Button
                    size="large"
                    htmlType="button"
                    className="btn btn-secondary"
                    // style={{ paddingLeft: 20, paddingRight: 20 }}
                    style={{ width: "100%" }}
                    onClick={onReset}
                  >
                    ล้าง
                  </Button>
                </Col>
                <Col md={12} lg={8}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    // style={{ paddingLeft: 30, paddingRight: 30 }}
                    style={{ width: "100%" }}
                  >
                    ค้นหา
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FormSearch;
