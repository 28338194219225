import SidebarCollapsed from '@Layout/plugin/SidebarCollapsed';
import RenderRoute from './_RenderLayout'
import './styles/index.less'

const ABusDashboardLayout = () => {
  return (
    <SidebarCollapsed>
      <RenderRoute />
    </SidebarCollapsed>
  )


};
export default ABusDashboardLayout;
