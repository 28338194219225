import { Dispatch } from 'react';
import { AuthPayload } from './interface/auth';
import { DispatchAuth } from './interface/context';
import { Types } from './type';

export const signIn = (dispatch: Dispatch<DispatchAuth>) => async (payload: AuthPayload[Types.SingIn]) => {
  const token = {
    accessToken: payload.token.accessToken || '',
    // rtoken: payload.token.rtoken || "",
  };
  const userInfo = payload.userInfo;
  localStorage.setItem('token', JSON.stringify(token));
  // localStorage.setItem("userInfo", JSON.stringify(userInfo));
  dispatch({
    type: Types.SingIn,
    payload: { token, userInfo },
  });
};

export const onReLoad = (dispatch: Dispatch<DispatchAuth>) => async () => {};

export const signOut = (dispatch: Dispatch<DispatchAuth>) => async () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userInfo');
  dispatch({
    type: Types.SingOut,
    payload: {
      token: {
        accessToken: '',
      },
      userInfo: {
        fullName: '',
        image: '',
        role: '',
        permission: [],
      },
    },
  });
};
