/* eslint-disable react-hooks/exhaustive-deps */
import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import { Button, Col, Form, Row } from "antd";
import { useNavigate } from "react-router-dom";

import { Role } from "@Components/DrawerProfile";
import PageHeader from "@Components/PageHeader";
import PagePaper from "@Components/PagePaper";
import { CreateNewMediaAPI } from "@Services/api/media";
import { getRoleAPI } from "@Services/api/role";
import { PostUserApi } from "@Services/api/user";
import { _RES_validateError } from "@Utils/validate";
import { useEffect, useState } from "react";
import FormUser from "../FromUser/index";
import "../styles/index.less";

type Props = {};

const CreateUser = (props: Props) => {
  const navigate = useNavigate();
  const { _signOut } = useAuthContextDispatch();
  const [form] = Form.useForm();
  const { token } = useAuthContextState();
  const [role, setRole] = useState<Role[]>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    getRoledata();
    return () => {};
  }, []);

  const getRoledata = async () => {
    try {
      const res = await getRoleAPI(token.accessToken);
      setRole(res.result);
    } catch (error) {}
  };

  const onFinish = async (value: any) => {
    try {
      const keyOnLoad = "CreateUser";
      setLoading(true);
      const data = {
        phone: value.phone.replaceAll("-", ""),
        email: value.email,
        roleId: value.role,
        fullName: value.fullName,
        agency: value.agency,
        expertise: value.expertise,
        password: value.password,
        medicalLicense: value.medicalLicense,
        imageId: null,
      };

      if (!!value.avatar) {
        const formData = new FormData();
        formData.append("file", value.avatar.originFileObj);
        const res = await CreateNewMediaAPI(formData, token.accessToken);
        data["imageId"] = res.result[0].id;
      }

      // const FormData = convertJsonToFormData(data);
      // if (!!value.avatar) {
      //   FormData.append("avatar", value.avatar.originFileObj);
      // }
      const res = await PostUserApi(data, token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "สร้างผู้ใช้สำเร็จ",
          runOption: () => {
            setLoading(false);
            navigate("/user-management/view-list");
          },
        },
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="h-100 flex-center flex-column">
      <div className="w-100">
        <Row>
          <Col span={24}>
            <PageHeader
              title="สร้างผู้ใช้"
              subTitle="เพิ่มผู้ใช้"
              action={
                <Row justify="end">
                  <Button
                    onClick={() => navigate("/user-management/view-list")}
                    size="large"
                    style={{
                      paddingLeft: 50,
                      paddingRight: 50,
                      marginRight: 10,
                    }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    onClick={() => form.submit()}
                    type="primary"
                    size="large"
                    loading={loading}
                    style={{ paddingLeft: 50, paddingRight: 50 }}
                  >
                    บันทึก
                  </Button>
                </Row>
              }
            />
          </Col>
        </Row>
      </div>
      <div className="w-100 " style={{ flex: 1 }}>
        <Row className="h-100">
          <Col span={24}>
            <PagePaper>
              <PageHeader title="ข้อมูลผู้ใช้" />
              <div className="h-100 flex-center flex-column">
                <div className="w-100">
                  <FormUser form={form} onSubmit={onFinish} role={role} />
                </div>
              </div>
            </PagePaper>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateUser;
