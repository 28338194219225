import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileFilled,
  SnippetsFilled,
  SyncOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { useAuthContextDispatch, useAuthContextState } from '@AuthContext/store';
import { IStatus } from '@Components/CCard/CustomCard';
import { IFileInfo } from '@Components/CFilesInfo/FileItem';
import ModalViewer from '@Components/form/CreateFormItem/_CustomItem/DicomMedia/viewer';
import ModalReport3D, { IResultReport3D } from '@Components/form/DoctorDiagnose/ModalReport3D';
import PatientStatus from '@Components/form/PatientStatus';
import VisitViewInfo from '@Components/form/VisitFormItem/ViewInfo';
import PageHeader from '@Components/PageHeader';
import PagePaper from '@Components/PagePaper';
import { FormPatientFields } from '@Interface/patient';
import { RESGetVisitInfoService, TypeFile, VisitField, VisitFile } from '@Interface/visit';
import _VisitService, { visitResultDetection } from '@Services/api/visit';
import { _RES_validateError } from '@Utils/validate';
import ViewPatient from '@Views/Patient/ViewPatient';
import { Button, Col, Divider, Drawer, Empty, Form, Modal, Row, Skeleton, Space, Tabs, Tag, Typography } from 'antd';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useZipDownload2 from './fileDownload';

type Props = {};

const Viewsdiagnostics = (props: Props) => {
  const params = useParams() as { visitId: string };
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();

  const [visitInfo, setVisitInfo] = useState<VisitField | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imgProfile, setProfileImg] = useState<any>();
  const [patientInfo, setPatientInfo] = useState<FormPatientFields>();
  const [patientUuid, setPatientUuid] = useState<string>('');
  const [downloadAndZip, { downloading }] = useZipDownload2();
  const [isDiagnosticsLoadind, setIsDiagnosticsLoadind] = useState<boolean>(false);
  const [id, setId] = useState<number>();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const componentToPrint = useRef(null);
  const [open, setOpen] = useState(false);
  const [openVisit, setOpenVisit] = useState(false);
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [visibleViewReport, setVisibleViewReport] = useState(false);

  const [report3D, setReport3D] = useState<IResultReport3D[]>([]);

  useLayoutEffect(() => {
    !!params.visitId && getVisitInfo(params.visitId);
    return () => {};

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    const keyOnLoad = 'getVisitResultDetection';
    const res = await visitResultDetection(params.visitId, token.accessToken);
    _RES_validateError(res, keyOnLoad, {
      Code_401: { runOption: _signOut },
      Code_200: {
        runOption: () => {
          setReport3D(res?.result);
          console.log('res', res);
          return true;
        },
      },
    });
  };

  const getVisitInfo = async (visitId: string) => {
    try {
      const keyOnLoad = 'getVisitWaitingInfo';
      setIsLoading(true);
      const res = await _VisitService.GetVisitInfoByIdAPI<RESGetVisitInfoService>(visitId, token.accessToken);

      console.log(res.result[0].patient.id);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setVisitInfo(res.result[0]);
            setProfileImg(res.result[0].patient.imageUrl);
            setId(res.result[0].patient.id);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
      return false;
    }
  };

  const onFinish = () => {};

  const onReserveDiagnostics = async (visitId: string) => {
    try {
      const keyOnLoad = 'onSendReserveDiagnosis';
      setIsDiagnosticsLoadind(true);
      const res = await _VisitService.SendVisitToReserve(visitId, token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_400: {
          runOption: () => {},
        },
        Code_200: {
          message: 'ส่งข้อมูลผู้ป่วยเพื่อรอวินิจฉัยสำเร็จ',
          runOption: () => {
            setIsDiagnosticsLoadind(false);

            return true;
          },
        },
      });
      navigate('/diagnostics/view-list');
    } catch (error) {
      console.log('error', error);
      setIsDiagnosticsLoadind(false);
      return false;
    }
  };
  const handleCreateMail = () => {
    // send API
    setIsModalVisible(true);
  };
  const onCloseModalCreateVisit = () => {
    setIsModalVisible(false);
  };

  const handleDownload = async (type: string) => {
    const id = Number(params.visitId);
    const res = await _VisitService.visitDownloadFiles(id, token.accessToken, type as TypeFile);
    const data = res.result;
    downloadAndZip(data);
  };

  const handleViewDICOM = () => {
    if (visitInfo?.studyId) return;
    // setVisibleViewDicom(!visibleViewDicom);
    // window.location.replace(`http://127.0.0.1:8080/viewer?StudyInstanceUIDs=${visitInfo?.studyId}`);
  };

  const abus = visitInfo?.visitFiles?.filter((e: any) => e.keyName === 'A_BUS');
  const mammogram = visitInfo?.visitFiles?.filter((e: any) => e.keyName === 'MAMMOGRAM');

  return (
    <div>
      {/* <CreateMail
        onClose={onCloseModalCreateVisit}
        isModalVisible={isModalVisible}
        componentToPrint={componentToPrint}
      /> */}
      <Modal centered visible={open} footer={null} width={1200} onCancel={() => setOpen(false)}>
        <ViewPatient patiantId={id} />
      </Modal>
      <Drawer title="ข้อมูลVisit" visible={openVisit} footer={null} onClose={() => setOpenVisit(false)}>
        <Row>
          <Col span={24}>
            <PagePaper>
              <Skeleton active loading={isLoading}>
                <VisitViewInfo initialValues={visitInfo} />
              </Skeleton>
            </PagePaper>
          </Col>
        </Row>
      </Drawer>
      <ModalReport3D
        onOk={() => setVisibleViewReport(!visibleViewReport)}
        visible={visibleViewReport}
        onCancel={() => setVisibleViewReport(!visibleViewReport)}
        values={report3D}
      />

      <PageHeader
        title="ข้อมูลรอวินิจฉัย"
        subTitle="รายละเอียดรายการรอวินิจฉัย"
        action={
          <Row gutter={[16, 16]} justify="end">
            <Col>
              <Button
                onClick={() => navigate(-1)}
                type="primary"
                size="large"
                style={{ backgroundColor: '#4887f2', borderColor: '#4887f2' }}
              >
                กลับ
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                size="large"
                onClick={() => onReserveDiagnostics(params.visitId)}
                loading={isDiagnosticsLoadind}
              >
                Reserve
              </Button>
            </Col>
          </Row>
        }
      />
      <Skeleton active loading={isLoading}>
        <PagePaper>
          <div className="FormUpload">
            <Row justify="space-between" style={{ margin: '0px', overflow: 'hidden' }}>
              <Col>
                <PatientStatus
                  visitInfo={visitInfo}
                  onDownload={handleDownload}
                  download={downloading}
                  imgProfile={imgProfile}
                  id={id}
                />
              </Col>
              <Col>
                <Row gutter={[16, 16]}>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => setOpen(true)}
                      style={{ paddingLeft: 30, paddingRight: 30 }}
                    >
                      ข้อมูลผู้ป่วย
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="default"
                      size="large"
                      onClick={() => setOpenVisit(true)}
                      style={{ paddingLeft: 30, paddingRight: 30 }}
                    >
                      ข้อมูล Visit
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Divider />
              <Col span={24} style={{ margin: '0px 0px 16px 0px', overflow: 'hidden' }}>
                <Tabs type="card" size="middle">
                  <Tabs.TabPane tab="ABUS" key="item-1">
                    <ContentTab
                      title="ข้อมูลภาพ ABUS"
                      files={abus}
                      handleDownload={() => handleDownload('A_BUS')}
                      handleViewDICOM={handleViewDICOM}
                      type="ABUS"
                      setVisibleViewReport={(v) => setVisibleViewReport(v)}
                      status={visitInfo?.statusAi}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Mammogram" key="item-2">
                    <ContentTab
                      title="ข้อมูลภาพ Memmogram"
                      files={mammogram}
                      handleDownload={() => handleDownload('MAMMOGRAM')}
                      handleViewDICOM={handleViewDICOM}
                      type="MAMMOGRAM"
                    />
                  </Tabs.TabPane>
                </Tabs>
              </Col>
            </Row>

            {/* <UploadImageFormItem diagnostPage={true} /> */}
            {/* <ShowAI /> */}
          </div>
        </PagePaper>
      </Skeleton>
      {/* modal */}
    </div>
  );
};

const ContentTab = ({
  title,
  files,
  handleDownload,
  handleViewDICOM,
  setVisibleViewReport,
  type,
  status,
}: {
  title: string;
  files?: IFileInfo[];
  handleDownload?: () => void;
  handleViewDICOM?: () => void;
  setVisibleViewReport?: (v: boolean) => void;
  type: 'ABUS' | 'MAMMOGRAM';
  status?: string;
}) => {
  const [items, setItems] = useState(files);
  const [visibleViewDicom, setVisibleViewDicom] = useState(false);

  const toggle = () => {
    setVisibleViewDicom(!visibleViewDicom);
  };

  const onDelete = (key: number) => {
    console.log(key);
    const remove = items?.filter((e, i) => i !== key);
    setItems(remove);
  };

  const Item = ({ name, keyItem }: { name: string; keyItem: number }) => {
    return (
      <Row
        style={{
          borderRadius: 5,
          border: '1px solid #F0F2F5',
          padding: '8px 14px 8px 14px',
        }}
        align="middle"
      >
        <Col span={2}>
          <FileFilled style={{ fontSize: 30, color: '#1C5AE8' }} />
        </Col>
        <Col span={20}>
          <div style={{ display: 'flex' }}>
            <Typography.Text ellipsis={{ tooltip: name }} style={{ color: '#1C5AE8' }}>
              {name}
            </Typography.Text>
            <div style={{ color: '#ACBEE8', marginLeft: 6 }}>
              <CheckCircleFilled />
            </div>
          </div>
        </Col>
        <Col
          span={2}
          style={{
            display: 'flex',
            color: '#F0F2F5',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <DeleteFilled onClick={() => onDelete(keyItem)} />
        </Col>
      </Row>
    );
  };

  const geStatus = (status: string) => {
    switch (status) {
      case 'รอประมวลผล AI':
        return { status: 'processing', name: 'รอประมวลผล AI' };
      case 'ยังไม่ประมวลผล AI':
        return { status: 'warning', name: 'ยังไม่ประมวลผล AI' };
      case 'ประมวลผล AI เสร็จสิ้น':
        return { status: 'success', name: 'ประมวณผล AI เสร็จสิ้น' };
      default:
        return { status: 'warning', name: 'ยังไม่ประมวลผล AI' };
    }
  };

  const _renderTagStatus = (item: IStatus) => {
    switch (item.status) {
      case 'success':
        return (
          <Tag icon={<CheckCircleOutlined />} color="#87d068">
            {' '}
            {item.name}
          </Tag>
        );
      case 'processing':
        return (
          <Tag icon={<SyncOutlined spin />} color="#108ee9">
            {' '}
            {item.name}
          </Tag>
        );
      case 'error':
        return (
          <Tag icon={<CloseCircleOutlined />} color="#f50">
            {' '}
            {item.name}
          </Tag>
        );
      case 'warning':
        return (
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            {' '}
            {item.name}
          </Tag>
        );
      default:
        break;
    }
  };

  return (
    <div className="card custom-card" style={{ overflow: 'hidden' }}>
      <div
        className="card-header"
        style={{
          background: '#FFFFFF',
          margin: '20px 10px 0px 10px',
          padding: '10px 30px',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          border: '1px solid #e7e7e7',
        }}
      >
        <Row justify="space-between" align="middle">
          <ModalViewer
            haveCapture={false}
            visible={visibleViewDicom}
            onCancel={toggle}
            urls={(files || []) as VisitFile[]}
            visitId={''}
          />
          <Col>
            <Row gutter={[10, 10]}>
              <Col style={{ color: '#707070' }}>{title}</Col>
              <Col style={{ borderLeft: '1px solid #e7e7e7' }}>
                {/* <Tag icon={<CheckCircleOutlined />} color="#87d068"> */}
                {/* {' '}
                  ประมวณผล AI เสร็จสิ้น */}
                {_renderTagStatus(geStatus(status || ''))}
                {/* </Tag> */}
              </Col>
            </Row>
          </Col>
          <Col>
            <Space>
              <Button
                hidden={!items?.length}
                icon={<VerticalAlignBottomOutlined />}
                size="middle"
                onClick={() => handleDownload?.()}
              />
              <Button
                disabled={type !== 'ABUS'}
                type="primary"
                icon={<SnippetsFilled />}
                size="middle"
                onClick={() => setVisibleViewReport?.(true)}
              >
                Report 3D {type === 'ABUS' ? 'ABUS' : 'Memmogram'}
              </Button>
              <Button icon={<EyeOutlined />} size="middle" onClick={toggle}>
                DICOM VIEWER
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
      <div
        className="card-body"
        style={{
          background: '#FFFFFF',
          margin: '0px 10px 20px 10px',
          padding: '10px 30px',
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
          borderLeft: '1px solid #e7e7e7',
          borderRight: '1px solid #e7e7e7',
          borderBottom: '1px solid #e7e7e7',
        }}
      >
        <Row gutter={[6, 6]}>
          {items?.length ? (
            items?.map((e, i) => {
              return (
                <Col span={12} key={i}>
                  <Item keyItem={i} name={e.name} />
                </Col>
              );
            })
          ) : (
            <Row justify="center" className="w-100">
              <Col span={24}>
                <Empty />
              </Col>
            </Row>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Viewsdiagnostics;
