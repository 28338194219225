import React, { useEffect, useState } from "react";
import type { RouteObject } from "react-router-dom";

import { routes } from "@Routes/route.access";
import {
  ElementRender,
  Permission,
  RouteCustom,
} from "@Routes/route.interface";
import { Navigate, useRoutes } from "react-router-dom";

import RequireAuth from "@Routes/RequireAuth";

import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import { getProfileAPI } from "@Services/api/user";

const BankComponent = () => {
  return <div />;
};

const RenderRoute = () => {
  const { userInfo, token } = useAuthContextState();
  const { _signIn, _signOut } = useAuthContextDispatch();
  const permissionAllow = userInfo.permission as Permission[];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token.accessToken) {
      getProfile()
        .catch(() => {
          _signOut();
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.accessToken]);

  const getProfile = async () => {
    try {
      const res = await getProfileAPI(token.accessToken);

      const data = res.result[0];
      if (data) {
        _signIn({
          token: { accessToken: token.accessToken },
          userInfo: {
            role: data.role.name,
            image: data.image,
            fullName: data.fullName,
            permission: data.role.permission,
          },
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const renderElementComponent = ({ obj }: ElementRender): RouteObject => {
    const Component: React.FC = obj.element || BankComponent;

    if (!obj.element && !!obj.navigateElement)
      return { ...obj, element: <Navigate to={obj.navigateElement.to} /> };
    if (!!obj.requireAuth)
      return {
        ...obj,
        element: (
          <RequireAuth route={obj} permissionAllow={permissionAllow}>
            <Component />
          </RequireAuth>
        ),
      };
    else return { ...obj, element: <Component /> };
  };
  const mapElementRoutesOfChildren = (
    routeChildren: RouteCustom[]
  ): RouteObject[] => {
    const newChildren: RouteObject[] = routeChildren.map((route) => {
      let newChild: RouteObject = route;
      if (!!route.navigateElement || !!route.element) {
        newChild = renderElementComponent({ obj: route });
      }
      if (!!route.children?.length) {
        newChild = {
          ...newChild,
          children: mapElementRoutesOfChildren(route.children),
        };
      }
      return newChild;
    });
    return newChildren;
  };
  const mapRoutes: RouteObject[] = routes.map((route) => {
    let newRoute: RouteObject = route;
    if (!!route.navigateElement || !!route.element) {
      newRoute = renderElementComponent({ obj: route });
    }
    if (!!route.children?.length) {
      newRoute = {
        ...newRoute,
        children: mapElementRoutesOfChildren(route.children),
      };
    }
    return newRoute;
  });

  const element = useRoutes(loading ? [] : mapRoutes);
  return element;
};

export default RenderRoute;
