/* eslint-disable @typescript-eslint/no-unused-vars */
import { FetchConfig } from "./interface";
import appConfig from "../../config/app.config";

export const Fetch = async <T>(fetchConfig: FetchConfig): Promise<T> => {
  try {
    const path = !!fetchConfig.params
      ? `${fetchConfig.path}${convertSearchParams(fetchConfig.params)}`
      : fetchConfig.path;
    const url = `${appConfig.apiURL}${path}`;
    const res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: fetchConfig.isBasic
          ? "Basic " + btoa("ABUSUSER:ABUSPASS")
          : `Bearer ${fetchConfig.token}`,
      },
      method: fetchConfig.method,
      body:
        fetchConfig.method === "POST" || fetchConfig.method === "PUT"
          ? JSON.stringify(fetchConfig.data)
          : null,
    });
    const dataResponse = await res.json();
    return dataResponse;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};

const convertSearchParams = (param: { [key: string]: any }) => {
  if (!param || Object.keys(param).length === 0 || param.constructor !== Object)
    return "";
  const newParam = new URLSearchParams(Object.entries(param)).toString();
  return "?" + newParam;
};

export const FetchFormData = async <T>(
  fetchConfig: FetchConfig,
  formData: FormData
): Promise<T> => {
  try {
    const path = !!fetchConfig.params
      ? `${fetchConfig.path}${convertSearchParams(fetchConfig.params)}`
      : fetchConfig.path;
    const url = `${appConfig.apiURL}${path}`;
    const res = await fetch(url, {
      body: formData,
      method: fetchConfig.method,
      headers: {
        Authorization: `Bearer ${fetchConfig.token}`,
      },
    });
    const dataResponse = await res.json();
    return dataResponse;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};

///test excel///
export const FetchPDF = async (
  fetchConfig: FetchConfig
  // method, path, data = ""
) => {
  try {
    const path = !!fetchConfig.params
      ? `${fetchConfig.path}${convertSearchParams(fetchConfig.params)}`
      : fetchConfig.path;
    const url = `${appConfig.apiURL}${path}`;
    // const token = localStorage.getItem("token");
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${fetchConfig.token}`,
      },
      method: fetchConfig.method,
      // method: method || "POST",
    });
    // return res.blob()
    if (res.status === 200) {
      return { code: 200, data: await res.blob() };
    } else {
      return { code: 400, message: res.statusText };
    }
  } catch (err: any) {
    return { success: false, message: err.message };
  }
};

//test

export const FetchMaps = async <T>(fetchConfig: any) => {
  try {
    const res = await fetch(
      `https://api.longdo.com/map/services/address?key=${appConfig.keyLongdo}&lon=${fetchConfig.lon}&lat=${fetchConfig.lat}`
    );
    const dataResponse = await res.json();
    return dataResponse;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
