import { useNavigate } from "react-router-dom";
import { Button, Col, Image, Result, Row } from 'antd';
const Page404 = () => {
  const navigate = useNavigate();
  return (
    <Row justify="center" align="middle" style={{ height: '100%' }}>
      <Col xs={24} md={20} lg={15} xl={10}>
        <Result
          icon={<Image src={"/images/error404.png"} alt="error-404" preview={false} />}
          extra={<Button type="primary" size="large" style={{ paddingLeft: 30, paddingRight: 30 }}
            onClick={() => {
              navigate("/");
            }}>กลับหน้าหลัก</Button>}
        />
      </Col>
    </Row>
  );
};

export default Page404;
