import { Col, Modal, Row, Image } from 'antd';
interface Props {
  visible: boolean;
  onCancel: () => void;
}

const ModalViewImg = ({ visible, onCancel }: Props) => {
  return (
    <Modal
      title="Views Image"
      centered
      visible={visible}
      onCancel={onCancel}
      width={900}
      footer={false}
    >
      <Row>
        <Col span={12}> <Image src="/images/dicom/LCC_0.png" /> </Col>
        <Col span={12}> <Image src="/images/dicom/LMLO_0.png" /> </Col>
        <Col span={12}> <Image src="/images/dicom/RCC_0.png" /> </Col>
        <Col span={12}> <Image src="/images/dicom/RMLO_0.png" /> </Col>
      </Row>
    </Modal>
  )
}

export default ModalViewImg
