/* eslint-disable react-hooks/exhaustive-deps */
import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import ImageUpload from "@Components/ImageUpload";
import { CreateNewMediaAPI } from "@Services/api/media";
import { getRoleAPI } from "@Services/api/role";
import { getProfileAPI, PutUserProfile } from "@Services/api/user";
import { _RES_validateError } from "@Utils/validate";
import { removeKeyObj } from "@yim/util-tool-ts";
import { Button, Col, Drawer, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
const { Option } = Select;

export interface Role {
  id: number;
  name: string;
}

export interface Profile {
  fullName: string;
  phone: string;
  email: string;
  agency: string;
  expertise: string;
  password: string;
  role: number;
  currentPassword: string;
  avatar: any;
  medicalLicense: string;
}

type Props = {
  onDrawer: boolean;
  onClickClose: (data: boolean) => void;
};

const DrawerProfile = (props: Props) => {
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [form] = Form.useForm();
  const [role, setRole] = useState<Role[]>();
  const [imageProfile, setImageProfile] = useState<Object>();
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    getProfile();
    getRole();
    return () => {};
  }, []);

  const getProfile = async () => {
    try {
      const res = await getProfileAPI(token.accessToken);
      setImageProfile({ url: res.result[0].image || "" });
      const data = res.result[0];
      const test = removeKeyObj({ ...data }, ["image"]);
      form.setFieldsValue({ ...test, role: data.role?.id });
    } catch (error) {
      console.log(error);
    }
  };

  const getRole = async () => {
    try {
      const res = await getRoleAPI(token.accessToken);
      setRole(res.result[0].data);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (value: Profile) => {
    try {
      const keyOnLoad = "putUserProfile";
      setLoading(true);
      const dataSubmit = {
        fullName: value.fullName,
        phone: value.phone,
        email: value.email,
        agency: value.agency,
        expertise: value.expertise,
        password: value.password || "",
        role: value.role,
        currentPassword: value.currentPassword || "",
        medicalLicense: value.medicalLicense,
        imageId: undefined,
      };

      if (!!value.avatar) {
        const formData = new FormData();
        formData.append("file", value.avatar.originFileObj);
        const res = await CreateNewMediaAPI(formData, token.accessToken);
        dataSubmit["imageId"] = res.result[0].id;
      }

      const res = await PutUserProfile(dataSubmit, token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "แก้ไขข้อมูลรายชื่อผู้ใช้สำเร็จ",
          runOption: () => {
            setLoading(false);
            setOnEdit(false);
          },
        },
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Form layout="horizontal" form={form} name="profile">
      <Drawer
        title={"โปรไฟล์"}
        size="large"
        closable={false}
        visible={props.onDrawer}
        extra={
          <Row gutter={[20, 20]}>
            {onEdit ? (
              <>
                <Col>
                  <Button
                    type="primary"
                    // loading={isLoading}
                    onClick={() => setOnEdit(false)}
                  >
                    ยกเลิก
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={() => form.submit()}
                  >
                    บันทึก
                  </Button>
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <Button
                    type="primary"
                    // loading={isLoading}
                    onClick={() => setOnEdit(true)}
                  >
                    แก้ไข
                  </Button>
                </Col>
                <Col
                  onClick={() => props.onClickClose(false)}
                  style={{ fontSize: "14pt", cursor: "pointer" }}
                >
                  <div>X</div>
                </Col>
              </>
            )}
          </Row>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row justify="center">
            <Col md={8}>
              <Form.Item
                // label="โปรไฟล์ผู้ใช้"
                name="avatar"
                valuePropName="fileList"
                // getValueFromEvent={normFile}
                // rules={[
                //   { required: true, message: "กรุณาใส่โปรไฟล์ผู้ใช้" },
                // ]}
              >
                <ImageUpload
                  disbled={!onEdit}
                  valueImage={imageProfile || "/ProfileDefualt.svg"}
                />
                {/* <Image src={imageProfile} width={250} /> */}
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Col md={24}>
              <Row gutter={[16, 20]} style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <Form.Item label="ชื่อ-นามสกุล" name="fullName">
                    <Input disabled={!onEdit ? true : false} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item label="เบอร์โทรศัพท์" name="phone">
                    <Input disabled={!onEdit ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col md={12}>
                  <Form.Item label="อีเมล" name="email">
                    <Input disabled={!onEdit ? true : false} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item label="ตำแหน่ง" name="role">
                    <Select disabled={!onEdit ? true : false}>
                      {role?.map((data: Role) => {
                        return (
                          <Option key={data.id} value={data.id}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <Form.Item label="หน่วยงาน" name="agency">
                    <Input disabled={!onEdit ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <Form.Item label="ความเชี่ยวชาญ" name="expertise">
                    <Input disabled={!onEdit ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <Form.Item
                    label="ใบอนุญาตประกอบวิชาชีพเวชกรรม เลขที่"
                    name="medicalLicense"
                  >
                    <Input placeholder="" disabled={!onEdit ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* <Row className="" justify="center" align="middle"> */}
            {/* <Col md={6} className="color-primary ">
                เปลี่ยนรหัสผ่าน
              </Col> */}
            {onEdit ? (
              <Col md={24}>
                <Row gutter={[16, 16]}>
                  <Col md={12}>
                    <Form.Item
                      label={<span className="">รหัสผ่านเก่า</span>}
                      name="currentPassword"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "กรุณาใส่รหัสผ่านเก่า!",
                      //   },
                      // ]}
                    >
                      <Input.Password placeholder="" className="" />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      label="รหัสผ่านใหม่"
                      name="password"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "กรุณาใส่รหัสผ่าน!",
                      //   },
                      // ]}
                    >
                      <Input.Password placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            ) : (
              ""
            )}
          </Row>
          {/* </Row> */}
        </Form>
      </Drawer>
    </Form>
  );
};

export default DrawerProfile;
