/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthContextDispatch, useAuthContextState } from '@AuthContext/store';
import Breadcumb from '@Components/Breadcumb';
import PageHeader from '@Components/PageHeader';
import PagePaper from '@Components/PagePaper';
import FileUpload from '@Components/fileUpload';
import PatientStatus from '@Components/form/PatientStatus';
import { RESGetVisitInfoService, VisitField } from '@Interface/visit';
import { _VisitService, DeleteVisitFileAPI } from '@Services/api/visit';
import { _RES_validateError } from '@Utils/validate';
import { Button, Col, Divider, Form, Input, Row, Skeleton } from 'antd';
import pLimit from 'p-limit';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CreateMail from '../CreateMail/index';
import DetailInformation from './detail-infomation';

type Props = {};

export interface UploadImageForm {
  apLeft?: UploadImageFieldItem;
  ltLeft?: UploadImageFieldItem;
  medLeft?: UploadImageFieldItem;

  apRight?: UploadImageFieldItem;
  ltRight?: UploadImageFieldItem;
  medRight?: UploadImageFieldItem;
}
export interface UploadImageFieldItem {
  file?: File | { name: string };
  percent?: number | null;
  status?: string | null;
}

const FormPatientInfo = (props: Props) => {
  const params = useParams() as { visitId: string };
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visitInfo, setVisitInfo] = useState<VisitField | null>(null);
  const [isLoadingPendingDiagnosis, setIsLoadingPendingDiagnosis] = useState<boolean>(false);
  const [isLoadingDetections, setIsLoadingDetections] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const componentToPrint = useRef(null);
  const [imgProfile, setImageProfile] = useState<string | any>();
  const [files, setFiles] = useState<any[]>();
  const [id, setId] = useState<number>();
  const navigate = useNavigate();

  const controller = useMemo(() => {
    return new AbortController();
  }, []);

  useLayoutEffect(() => {
    !!params.visitId && getVisitInfo(params.visitId);
    return () => {};
  }, []);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const getVisitInfo = async (visitId: string, loading?: boolean) => {
    try {
      const keyOnLoad = 'getVisitInfo';
      if (!(loading === false)) setIsLoading(true);
      const res = await _VisitService.GetVisitInfoByIdAPI<RESGetVisitInfoService>(visitId, token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setVisitInfo(res.result[0]);
            initialValues(res.result[0]);
            setImageProfile(res.result[0].patient.imageUrl);
            setId(res.result[0].patient.id);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
      return false;
    }
  };

  const initialValues = (values: any) => {
    const abus = values.visitFiles.filter((e: any) => e.keyName === 'A_BUS');
    const mammogram = values.visitFiles.filter((e: any) => e.keyName === 'MAMMOGRAM');
    form.setFieldsValue({ ...values, abus, mammogram });
  };

  const onSendPendingDiagnosis = async (visitId: string) => {
    try {
      const keyOnLoad = 'onSendPendingDiagnosis';
      setIsLoadingPendingDiagnosis(true);
      const res = await _VisitService.AddWaitingDiagnosisAIP(visitId, token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: 'ส่งข้อมูลผู้ป่วยเพื่อรอวินิจฉัยสำเร็จ',
          runOption: () => {
            setIsLoadingPendingDiagnosis(false);
            return true;
          },
        },
      });
      navigate('/upload/view-list');
    } catch (error) {
      console.log('error', error);
      setIsLoadingPendingDiagnosis(false);
      return false;
    }
  };

  const sendDetections = async () => {
    onSendDetections(params.visitId);
  };

  const onSendDetections = async (visitId: string) => {
    try {
      const keyOnLoad = 'onSendDetections';
      setIsLoadingDetections(true);
      const res = await _VisitService.AddDetectionsAPI(visitId, token.accessToken);
      const dataAi = { visit_no: visitInfo?.visitNo, url: visitInfo?.visitFiles.map((e) => e?.fileUrl) };

      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: 'รอผลวินิจฉัย AI',
          runOption: async () => {
            await fetch('http://localhost:5000/detection', {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              method: 'POST',
              body: JSON.stringify(dataAi),
            });

            const newVisitInfo = visitInfo ? { ...visitInfo, statusAi: 'รอประมวลผล AI' } : null;
            setVisitInfo(newVisitInfo);
            setIsLoadingDetections(false);
            return true;
          },
        },
      });
      // navigate('/upload/view-list');
    } catch (error) {
      console.log('error', error);
      setIsLoadingDetections(false);
      return false;
    }
  };

  const handleCreateMail = () => {
    // send API
    setIsModalVisible(true);
  };

  const onCloseModalCreateVisit = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values: any) => {
    const onUpload = {
      Success: (keyName: string) => {
        const progress = JSON.parse(form.getFieldValue('progress'));
        const map = progress.map((progress: any) => {
          if (!(progress.keyName === keyName)) return progress;
          return { ...progress, success: true };
        });
        const json = JSON.stringify(map);
        return form.setFieldsValue({ progress: json });
      },
      Error: (keyName: string) => {
        const progress = JSON.parse(form.getFieldValue('progress'));
        const map = progress.map((progress: any) => {
          if (!(progress.keyName === keyName)) return progress;
          return { ...progress, success: false };
        });
        const json = JSON.stringify(map);
        return form.setFieldsValue({ progress: json });
      },
    };

    const abus =
      values?.abus
        ?.map((e: any, i: number) => {
          return { keyName: 'A_BUS', file: e, number: i };
        })
        ?.filter((e: any) => !e.file?.id) || [];

    const mammogram =
      values?.mammogram
        ?.map((e: any, i: number) => {
          return { keyName: 'MAMMOGRAM', file: e, number: i };
        })
        ?.filter((e: any) => !e.file?.id) || [];

    const files = abus.concat(mammogram).filter((e: any) => e);

    const uploadPromises = async (e: any) => {
      const formData = new FormData();
      const keyName = e.keyName === 'A_BUS' ? 'dicom' : 'mammogram';

      formData.append('id', params.visitId);
      formData.append(keyName, e.file);

      return _VisitService
        .UploadAPI(formData, token.accessToken, {
          signal: controller.signal,
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.round((loaded * 100) / total);
            const value = { percent, keyName: e.keyName + e.number };
            const progress = form.getFieldValue('progress');

            if (!progress) {
              const json = JSON.stringify([value]);
              return form.setFieldsValue({ progress: json });
            }

            const progressParsed = JSON.parse(progress);
            const find = progressParsed.find((item: any) => item.keyName === value.keyName);

            if (find) {
              const map = progressParsed.map((progress: any) => {
                if (!(progress.keyName === value.keyName)) return progress;
                return { ...progress, percent };
              });
              const json = JSON.stringify(map);
              return form.setFieldsValue({ progress: json });
            }

            const json = JSON.stringify([...progressParsed, value]);
            return form.setFieldsValue({ progress: json });
          },
        })
        .then(() => onUpload.Success(e.keyName + e.number))
        .catch(function (error) {
          const keyOnLoad = `${keyName}uploadPromises`;
          if (error?.response?.data) {
            onUpload.Error(e.keyName + e.number);
          }
          _RES_validateError(error.response.data, keyOnLoad, {
            Code_401: { runOption: _signOut },
            Code_400: {
              message: `${keyName}: ${error.response?.data?.message}`,
              runOption: () => onUpload.Error(e.keyName + e.number),
            },
            Error: {
              message: `${keyName}: ${error.response?.data?.message}`,
              runOption: () => onUpload.Error(e.keyName + e.number),
            },
          });
        });
    };
    const limit = pLimit(2);
    Promise.all(files?.map((e: any) => limit(() => uploadPromises(e))))
      .then((files) => {
        getVisitInfo(params.visitId, false);
        setFiles([]);
        // console.log("Promise files : success", files);
      })
      .catch(function (error) {
        // console.log("Promise", error);
      });
  };

  const onDeleteFile = async (id: number) => {
    try {
      const keyOnLoad = 'removeFile';
      const res = await DeleteVisitFileAPI(id, token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            //
          },
        },
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const onValuesChange = (changedValues: any, values: any) => {
    if (changedValues.abus || changedValues.mammogram) {
      const newFilesABUS = changedValues?.abus?.filter((e: any) => !e.id);
      const newFilesMammogram = changedValues?.mammogram?.filter((e: any) => !e.id);
      setFiles([...(files || []), ...(newFilesABUS || []), ...(newFilesMammogram || [])]);
    }
  };

  const disableButton = !visitInfo?.visitFiles.length || !!visitInfo.studyId;
  const hiddenAbus = !visitInfo?.visitProgram.some((e) => e.program?.name === 'ABUS');
  const hiddenMammogram = !visitInfo?.visitProgram.some((e) => e.program?.name === 'MAMMOGRAM');

  return (
    <div>
      <CreateMail
        onClose={onCloseModalCreateVisit}
        isModalVisible={isModalVisible}
        componentToPrint={componentToPrint}
      />
      <PageHeader
        setLayout={{ md1: 14, md2: 10 }}
        title="ข้อมูลผู้ป่วย"
        breadcumb={
          <Breadcumb
            value={[
              { title: 'รายการอัปโหลดไฟล์', path: '/upload/view-list' },
              {
                title: `HN : ${visitInfo?.patient.hn}`,
              },
            ]}
          />
        }
        action={
          <Row gutter={[6, 6]} justify="end">
            <Col flex={2} md={0} lg={9}>
              <Button
                onClick={() => navigate(-1)}
                className="w-100"
                size="large"
                style={{
                  background: '#FFFFFF',
                  color: '#777777',
                  borderColor: '#E7E7E7',
                }}
                loading={isLoadingPendingDiagnosis}
              >
                กลับ
              </Button>
            </Col>
            <Col flex={2} md={0} lg={9}>
              <Button
                onClick={handleCreateMail}
                className="w-100"
                size="large"
                // style={{
                //   background: disableButton ? '#E7E7E7' : '#475F7B',
                //   color: disableButton ? '#777777' : '#FFFFFF',
                //   borderColor: disableButton ? '#E7E7E7' : '#475F7B',
                // }}
                loading={isLoadingPendingDiagnosis}
                // disabled={disableButton}
              >
                สร้างจดหมาย
              </Button>
            </Col>
            <Col flex={2} md={0} lg={9}>
              <Button
                onClick={() => onSendPendingDiagnosis(params.visitId)}
                className="w-100"
                size="large"
                style={{
                  background: disableButton ? '#E7E7E7' : '#1C5AE8',
                  color: disableButton ? '#777777' : '#FFFFFF',
                  borderColor: disableButton ? '#E7E7E7' : '#1C5AE8',
                }}
                loading={isLoadingPendingDiagnosis}
                disabled={disableButton}
              >
                ส่งรอวินิจฉัย
              </Button>
            </Col>
          </Row>
        }
      />
      <Skeleton active loading={isLoading}>
        <Form
          form={form}
          size="large"
          layout="vertical"
          className="FormUpload"
          onFinish={onFinish}
          requiredMark="optional"
          onValuesChange={onValuesChange}
        >
          <div
            style={{
              height: 'calc(100vh - 210px)',
              overflow: 'hidden',
            }}
          >
            <PagePaper
              style={{
                height: '100px',
                minHeight: '100px',
                padding: 0,
                zIndex: 100,
              }}
              className="box-shadow-card"
            >
              <Row justify="space-between" className="p-1">
                <Col span={18}>
                  <PatientStatus visitInfo={visitInfo} imgProfile={imgProfile} id={id} isDoctor />
                </Col>
                <Col span={6}>
                  <Row gutter={[6, 6]}>
                    <Col md={12}>
                      <Form.Item noStyle>
                        <Button
                          type="ghost"
                          style={{ width: '100%' }}
                          onClick={() => {
                            getVisitInfo(params.visitId, false);
                            form.setFieldsValue({ progress: undefined });
                          }}
                        >
                          ยกเลิก
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item noStyle>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }} disabled={!files?.length}>
                          อัปโหลดไฟล์
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Divider />
              </Row>
            </PagePaper>
            <div
              style={{
                height: 'calc(100vh - 210px - 100px)',
                overflow: 'auto',
                backgroundColor: 'white',
              }}
            >
              <PagePaper style={{ padding: '20px 2rem 20px 2rem' }}>
                <DetailInformation />
                <Divider />
                <div>ข้อมูลภาพ</div>
                {hiddenAbus ? null : (
                  <Form.Item label name="abus">
                    <FileUpload
                      title="ABUS"
                      subTitle="รองรับไฟล์ .dcm มากสุดจำนวน 6 ไฟล์"
                      maxLength={6}
                      keyName="A_BUS"
                      isLoadingDetections={isLoadingDetections}
                      onSendDetections={disableButton ? undefined : sendDetections}
                      onDeleteFile={onDeleteFile}
                      status={visitInfo?.statusAi}
                    />
                  </Form.Item>
                )}
                {hiddenMammogram ? null : (
                  <Form.Item label name="mammogram">
                    <FileUpload
                      title="MAMMOGRAM"
                      subTitle="รองรับไฟล์ .dcm มากสุดจำนวน 6 ไฟล์"
                      maxLength={6}
                      keyName="MAMMOGRAM"
                      onDeleteFile={onDeleteFile}
                      status={visitInfo?.statusAi}
                    />
                  </Form.Item>
                )}
                <Form.Item name="progress" hidden>
                  <Input />
                </Form.Item>
              </PagePaper>
            </div>
          </div>
        </Form>
      </Skeleton>
    </div>
  );
};

export default FormPatientInfo;
