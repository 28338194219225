import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
  FileFilled,
  SettingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { IStatus } from '@Components/CCard/CustomCard';
import { Button, Col, Form, Popconfirm, Progress, Row, Tag, Typography } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';

interface Props {
  title: string;
  subTitle?: string;
  maxLength?: number;
  value?: any;
  onChange?: (file: any) => void;
  onDeleteFile: (id: number) => void;
  keyName?: string;
  onSendDetections?: () => void;
  isLoadingDetections?: boolean;
  status?: string;
}

const FileUpload: FC<Props> = ({
  title,
  subTitle,
  maxLength,
  value,
  onChange,
  keyName,
  onSendDetections,
  isLoadingDetections,
  onDeleteFile,
  status,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<any[]>(value || []);

  useEffect(() => {
    setFile(value);
  }, [value]);

  const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) return;

    const fileArr = Object.values(files).map((e) => e);
    setFile([...file, ...fileArr]);
    onChange?.([...file, ...fileArr]);
  };

  const onClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = '';
  };

  const openFilePickerDialog = () => {
    ref.current?.click();
  };

  const onDelete = (key: number, id?: number) => {
    const filter = file.filter((_, i) => i !== key);

    setFile(filter);
    onChange?.(filter);
    if (id) {
      onDeleteFile(id);
    }
  };

  const confirm = (key: number, id?: number) => {
    onDelete(key, id);
  };

  const geStatus = (status: string) => {
    switch (status) {
      case 'รอประมวลผล AI':
        return { status: 'processing', name: 'รอประมวลผล AI' };
      case 'ยังไม่ประมวลผล AI':
        return { status: 'warning', name: 'ยังไม่ประมวลผล AI' };
      case 'ประมวลผล AI เสร็จสิ้น':
        return { status: 'success', name: 'ประมวณผล AI เสร็จสิ้น' };
      default:
        return { status: 'warning', name: 'ยังไม่ประมวลผล AI' };
    }
  };

  const _renderTagStatus = (item: IStatus) => {
    switch (item.status) {
      case 'success':
        return (
          <Tag icon={<CheckCircleOutlined />} color="#87d068">
            {' '}
            {item.name}
          </Tag>
        );
      case 'processing':
        return (
          <Tag icon={<SyncOutlined spin />} color="#108ee9">
            {' '}
            {item.name}
          </Tag>
        );
      case 'error':
        return (
          <Tag icon={<CloseCircleOutlined />} color="#f50">
            {' '}
            {item.name}
          </Tag>
        );
      case 'warning':
        return (
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            {' '}
            {item.name}
          </Tag>
        );
      default:
        break;
    }
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <Row
        justify="space-between"
        style={{
          background: '#F0F2F5',
          padding: 10,
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ paddingRight: 5 }}>{title}</span> {_renderTagStatus(geStatus(status || ''))}
        </div>
        <Button
          className="flex-center"
          style={{
            backgroundColor: !onSendDetections ? '#E7E7E7' : '#FF748E',
            height: 35,
            width: 35,
            border: `1px solid ${!onSendDetections ? '#E7E7E7' : '#FF748E'}`,
            color: '#FFFFFF',
          }}
          loading={isLoadingDetections}
          onClick={() => onSendDetections?.()}
        >
          <SettingOutlined hidden={isLoadingDetections} />
        </Button>
      </Row>
      <div
        style={{
          height: 'auto',
          width: '100%',
          border: '1px solid #E7E7E7',
          padding: '2rem',
        }}
      >
        <div style={{ textAlign: 'center', fontSize: 12, color: '#2F2E2E' }}>{subTitle}</div>
        <div style={{ textAlign: 'center' }} className="mt-1">
          <Button type="primary" onClick={openFilePickerDialog} disabled={file?.length >= (maxLength || 6)}>
            + อัปโหลด
          </Button>
        </div>
        <input
          accept=".dcm"
          multiple={true}
          type="file"
          ref={ref}
          style={{ display: 'none' }}
          onChange={onFileSelect}
          onClick={onClick}
        />
        <Row gutter={[16, 16]} className="mt-2">
          {file?.map((e, i) => {
            return (
              <Col span={12} key={i}>
                <Form.Item shouldUpdate noStyle>
                  {(form) => {
                    const progress = form.getFieldValue('progress');
                    let obj = undefined as any;
                    if (progress) {
                      obj = JSON.parse(progress).find((obj: any) => obj.keyName === `${keyName}` + i);
                    }

                    return (
                      <Row
                        style={{
                          borderRadius: 5,
                          border: `1px solid ${obj?.success === false ? '#DE1A1A' : '#F0F2F5'}`,
                          padding: '8px 14px 8px 14px',
                        }}
                        align="middle"
                      >
                        <Col span={2}>
                          <FileFilled style={{ fontSize: 30, color: '#1C5AE8' }} />
                        </Col>
                        <Col span={20}>
                          <div
                            style={{
                              display: 'flex',
                              height: obj && obj?.percent !== 100 ? undefined : 47.14,
                              alignItems: 'center',
                            }}
                          >
                            <Typography.Text
                              ellipsis={{ tooltip: e.name }}
                              style={{
                                color: obj?.success === false ? '#DE1A1A' : '#1C5AE8',
                              }}
                            >
                              {e.name}
                            </Typography.Text>
                            {obj && obj?.success ? (
                              <div style={{ color: '#ACBEE8', marginLeft: 6 }}>
                                <CheckCircleFilled />
                              </div>
                            ) : null}
                            {obj && obj?.success === false ? (
                              <div style={{ color: '#DE1A1A', marginLeft: 6 }}>
                                <CloseCircleFilled />
                              </div>
                            ) : null}
                          </div>
                          {obj && obj?.percent !== 100 ? <Progress percent={obj?.percent} /> : null}
                        </Col>
                        <Col
                          span={2}
                          style={{
                            display: 'flex',
                            color: obj?.success === false ? '#DE1A1A' : '#F0F2F5',
                            alignItems: 'center',
                            justifyContent: 'end',
                          }}
                        >
                          <Popconfirm
                            title="คุณแน่ใจหรือไม่ว่าจะลบไฟล์นี้?"
                            onConfirm={() => confirm(i, e.id)}
                            onCancel={() => {}}
                            okText="ใช่"
                            cancelText="ไม่ใช่"
                            placement="topRight"
                          >
                            <DeleteFilled />
                          </Popconfirm>
                        </Col>
                      </Row>
                    );
                  }}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default FileUpload;
