import { Col, Row } from 'antd'
import { Trans } from 'react-i18next'

const Copyright = () => {
  return (
    <Row justify="center" >
      <Col>
        <span className="copyright">
          <Trans i18nKey="copyright"><code>&copy;</code> Copyright <code>&copy;</code> 2022 AICEDA 3D-ABUS, All rights reserved.</Trans>
        </span>
      </Col>
    </Row>
  )
}

export default Copyright