import React from 'react'
import { CheckCircleFilled, DeleteFilled, FileFilled } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';

interface Props {
  onDelete: (id: number) => void;
  value?: IFileInfo;
}

export interface IFileInfo {
  id: number;
  name: string;
}
const FileItem = ({ onDelete, value }: Props) => {
  return (
    <Row
      style={{
        borderRadius: 5,
        border: "1px solid #F0F2F5",
        padding: "8px 14px 8px 14px",
      }}
      align="middle"
    >
      <Col span={2}>
        <FileFilled style={{ fontSize: 30, color: "#1C5AE8" }} />
      </Col>
      <Col span={20}>
        <div style={{ display: "flex" }}>
          <Typography.Text
            ellipsis={{ tooltip: value?.name }}
            style={{ color: "#1C5AE8" }}
          >
            {value?.name}
          </Typography.Text>
          <div style={{ color: "#ACBEE8", marginLeft: 6 }}>
            <CheckCircleFilled />
          </div>
        </div>
      </Col>
      <Col
        span={2}
        style={{
          display: "flex",
          color: "#F0F2F5",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <DeleteFilled onClick={() => onDelete(0)} />
      </Col>
    </Row>
  )
}

export default FileItem
