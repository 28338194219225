import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeKeyObj } from "@yim/util-tool-ts";
import { FormPatientFields } from "@Interface/patient";
import { CreateNewPatientAPI } from "@Services/api/patient";
import { _RES_validateError } from "@Utils/validate";
import { useAuthContextState } from "@AuthContext/store";
import { useAuthContextDispatch } from "@AuthContext/store";
import { Button, Col, Row, Space, Form } from "antd";
import moment from "moment";
import PagePaper from "@Components/PagePaper";
import PageHeader from "@Components/PageHeader";
import FormPatient from "../components/FormPatient";
import "../styles/index.less";
import { CreateNewMediaAPI } from "@Services/api/media";
import { CreateVisitByPatientAPI } from "@Services/api/visit";

type Props = {};

const CreatePatient = (props: Props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { _signOut } = useAuthContextDispatch();
  const { token } = useAuthContextState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [onEdit] = useState<boolean>(true);

  const onFinish = async (value: FormPatientFields) => {
    try {
      setIsLoading(true);
      const keyOnLoad = "CreateNewPatient";
      const dataField = {
        hn: value.hn || "",
        email: value.email,
        phone: value.phone.replaceAll("-", ""),
        idCard: value.idCard.replaceAll(" ", ""),
        address: value.address,
        fullName: value.fullName,
        dateOfBirth: moment(value.birthday).format("YYYY-MM-DD"),
        imageId: 0,
      };
      const dataAddFieldQuestionnaire = {
        ...dataField,
        questionnaire: JSON.stringify(
          removeKeyObj({ ...value }, [
            "avatar",
            "birthday",
            ...Object.keys(dataField),
          ])
        ),
      };

      if (!!value.avatar) {
        const formData = new FormData();
        formData.append("file", value.avatar.originFileObj);
        const res = await CreateNewMediaAPI(formData, token.accessToken);
        dataAddFieldQuestionnaire["imageId"] = res.result?.[0]?.id;
      }
      const res = await CreateNewPatientAPI(
        dataAddFieldQuestionnaire,
        token.accessToken
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "สร้างรายชื่อผู้ป่วยสำเร็จ",
          runOption: async () => {
            const visit = await CreateVisitByPatientAPI(
              { patient: res.result?.[0]?.uuid },
              token.accessToken
            );
            setIsLoading(false);
            navigate("/program/view-program/" + visit.result?.[0]?.id);
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="h-100 flex-center flex-column">
      <div className="w-100">
        <Row>
          <Col span={24}>
            <PageHeader
              title="สร้างผู้ป่วย"
              subTitle="เพิ่มผู้ป่วย"
              action={
                <Row justify="end">
                  <Col>
                    <Space>
                      <Button
                        onClick={() => navigate("/patient/view-list")}
                        size="large"
                        style={{
                          paddingLeft: 50,
                          paddingRight: 50,
                          marginRight: 10,
                        }}
                      >
                        ยกเลิก
                      </Button>
                      <Button
                        onClick={() => form.submit()}
                        type="primary"
                        size="large"
                        style={{ paddingLeft: 50, paddingRight: 50 }}
                        loading={isLoading}
                      >
                        บันทึก
                      </Button>
                    </Space>
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row>
      </div>
      <div className="w-100 " style={{ flex: 1 }}>
        <Row className="h-100">
          <Col span={24}>
            <PagePaper>
              <FormPatient
                form={form}
                onFinish={onFinish}
                isLoading={isLoading}
                onEdit={onEdit}
              />
            </PagePaper>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreatePatient;
