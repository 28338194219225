import { Token } from '@AuthContext/interface/auth';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';
import { useEffect, useRef, useState } from 'react';
import Header from './header';

// Initialize cornerstone
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.configure({
  useWebWorkers: true,
});

interface DicomViewerProps {
  imageId: string;
  maxFrames: number;
  currentFrame: number;
  setCurrentFrame: (frame: number) => void;
  visitId: string;
  token: Token;
  haveCapture?: boolean;
  captureImage?: (currentImg: any) => void;
}

const DicomViewer: React.FC<DicomViewerProps> = ({
  imageId,
  maxFrames,
  currentFrame,
  setCurrentFrame,
  visitId,
  token,
  haveCapture = true,
  captureImage,
}) => {
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentImg, setCurrentImg] = useState<string>('');

  useEffect(() => {
    setCurrentFrame(0);
    loadImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageId]);

  useEffect(() => {
    window.addEventListener('wheel', handleScroll);
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFrame, imageId, maxFrames]);

  const loadImage = async () => {
    try {
      const element = canvasRef.current;
      if (element) {
        cornerstone.enable(element);
        const image = await cornerstone.loadAndCacheImage(imageId);
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        cornerstone.renderToCanvas(canvas, image);
        setCurrentImg(canvas.toDataURL());
      }

      setLoading(false);
    } catch (error) {
      console.error('Error loading DICOM image:', error);
      setLoading(false);
    }
  };

  const handleScroll = (event: WheelEvent) => {
    if (imageId) {
      const newFrame = Math.max(0, Math.min(currentFrame + (event.deltaY > 0 ? 1 : -1), maxFrames - 1));
      setCurrentFrame(newFrame);
      renderFrame(newFrame);
    }
  };

  const renderFrame = async (frame: number) => {
    const element = canvasRef.current;
    if (imageId && element) {
      const image = await cornerstone.loadAndCacheImage(`${imageId}?frame=${frame}`);
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      cornerstone.renderToCanvas(canvas, image);
      setCurrentImg(canvas.toDataURL());
      // cornerstone.displayImage(element, image);
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '50px' }}>
        {haveCapture && <Header currentImg={currentImg} visitId={visitId} token={token} captureImage={captureImage} />}
      </div>
      <div style={{ height: 'calc(70vh - 24px - 50px)', display: 'flex', alignItems: 'center', overflow: 'auto' }}>
        <img ref={canvasRef} style={{ width: '100%' }} src={currentImg} alt={``} />
        {loading && <p>Loading DICOM image...</p>}
      </div>
    </div>
  );
};

export default DicomViewer;
