/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { useAuthContextDispatch, useAuthContextState } from '@AuthContext/store';
import { QuestionProp } from '@Interface/patient';
import { GetPatientQuestionFormApi } from '@Services/api/patient';
import { Col, DatePicker, Form, FormInstance, Input, message, Row, Skeleton } from 'antd';

import ImageUpload from '@Components/ImageUpload';
import TitleAffix from '@Components/TitleAffix';
import CreateFormItem from '@Components/form/CreateFormItem/_RenderFormItem';
import { _RES_validateError } from '@Utils/validate';
import InputMask from 'react-input-mask';
import { useLocation } from 'react-router';
import './styles/index.less';

type Props = {
  form: FormInstance<any>;
  onEdit?: boolean;
  onFinish: (e: any) => void;
  isLoading?: boolean;
  initialValues?: any;
  profileImg?: any;
};

const FormPatient = (props: Props) => {
  const location = useLocation();
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [questionFormPatient, setQuestionFormPatient] = useState<QuestionProp[] | null>(null);

  useEffect(() => {
    getQuestion();
    return () => {};
  }, [props.initialValues]);

  const getQuestion = async () => {
    try {
      setIsLoading(true);
      const keyOnLoad = 'getPatientForm';
      const res = await GetPatientQuestionFormApi(token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setQuestionFormPatient(res.result);
            setInitialValues(props.initialValues || {});
            setIsLoading(false);
          },
        },
      });
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  const setInitialValues = (initialValues: { [key: string]: any }) => {
    // console.log(initialValues, "initialValues");
    //("")
  };

  return (
    <div ref={setContainer} className={location.pathname === '/patient/create' ? 'FormPatient-create' : 'FormPatient'}>
      <Row justify="center">
        <Col lg={20} xxl={14}>
          <Form
            scrollToFirstError
            size="large"
            form={props.form}
            layout="vertical"
            requiredMark="optional"
            onFinishFailed={() => message.error('ข้อมูลผิดพลาดหรือกรอกข้อมูลไม่ครบ')}
            onFinish={props.onFinish}
          >
            <TitleAffix title="ขั้นที่ 1 ข้อมูลผู้ป่วย" subTitle="ข้อมูลผู้ป่วย" container={container}>
              <Row className="" justify="center">
                {props.onEdit === true ? (
                  <Col md={24}>
                    <Form.Item
                      label="โปรไฟล์ผู้ใช้"
                      name="avatar"
                      valuePropName="fileList"
                      // getValueFromEvent={normFile}
                      // rules={[
                      //   { required: true, message: "กรุณาใส่โปรไฟล์ผู้ใช้" },
                      // ]}
                    >
                      <ImageUpload valueImage={props.profileImg || '/images/ProfileDefualt.svg'} />
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}

                <Col md={24}>
                  <Row gutter={[16, 16]}>
                    <Col md={12}>
                      <Form.Item
                        label="Number HN"
                        name="hn"
                        // rules={[
                        //   { required: true, message: "กรุณาใส่หมายเลข HN!" },
                        // ]}
                      >
                        <Input placeholder="หมายเลข HN" disabled={!props.onEdit} />
                      </Form.Item>
                    </Col>
                    {/* <Col md={12}>
                      <Form.Item
                        label={<span className="color-primary">Number SN</span>}
                        name="sn"
                        rules={[
                          { required: true, message: "กรุณาใส่หมายเลข SN!" },
                        ]}
                      >
                        <Input
                          placeholder="หมายเลข SN"
                          className="color-primary"
                        />
                      </Form.Item>
                    </Col> */}
                    <Col md={12}>
                      <Form.Item
                        label="ชื่อ-สกุล"
                        name="fullName"
                        rules={[{ required: true, message: 'กรุณาใส่ ชื่อ - สกุล!' }]}
                      >
                        <Input placeholder="ชื่อ - สกุล" disabled={!props.onEdit} />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="เลขบัตรประชาชน"
                        name="idCard"
                        rules={[
                          {
                            required: true,
                            message: 'กรุณาใส่เลขบัตรประชาชน!',
                          },
                        ]}
                      >
                        <InputMask mask="9 9999 99999 99 9" disabled={!props.onEdit}>
                          <Input
                            placeholder="เลขบัตรประชาชน"
                            // disabled={!props.onEdit}
                          />
                        </InputMask>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="วัน-เดือน-ปี เกิด"
                        name="birthday"
                        rules={[
                          {
                            required: true,
                            message: 'กรุณาใส่ วัน-เดือน-ปี เกิด!',
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          format="DD-MM-YYYY"
                          placeholder="วัน-เดือน-ปี เกิด"
                          disabled={!props.onEdit}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="เบอร์โทรศัพท์"
                        name="phone"
                        rules={[
                          { required: true, message: 'กรุณาใส่เบอร์โทรศัพท์!' },
                          // {
                          //   message: "เลขเบอร์โทรศัพท์ไม่ครบ",
                          //   pattern: new RegExp("([a-zA-Z0-9]{11,11}\\s*)+"),
                          // },
                        ]}
                      >
                        <InputMask mask="099-9999-999" disabled={!props.onEdit}>
                          <Input
                            placeholder="เบอร์โทรศัพท์"
                            // disabled={!props.onEdit}
                          />
                        </InputMask>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="อีเมล์"
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: 'กรุณาใส่อีเมล์ให้ถูกต้อง',
                          },
                          {
                            required: true,
                            message: 'กรุณาใส่อีเมล์',
                          },
                        ]}
                      >
                        <Input placeholder="อีเมล์" disabled={!props.onEdit} />
                      </Form.Item>
                    </Col>
                    <Col md={24}>
                      <Form.Item
                        label="ที่อยู่"
                        name="address"
                        rules={[{ required: true, message: 'กรุณาใส่ที่อยู่' }]}
                      >
                        <Input placeholder="ที่อยู่" disabled={!props.onEdit} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TitleAffix>

            {/* ------- questions form --------- */}
            <Skeleton active loading={isLoading}>
              {/* {!!mocQuestion &&
                mocQuestion.map(
                  (item, ind) => (
                    <TitleAffix
                      title={item.title}
                      subTitle={item.subTitle}
                      container={container}
                      key={ind}
                    >
                      <CreateFormItem
                        values={item.questions}
                        form={props.form}
                        disabled={!props.onEdit}
                      />
                    </TitleAffix>
                  )
                  // {
                  // }
                )} */}

              {!!questionFormPatient &&
                questionFormPatient.map((item, ind) => (
                  <TitleAffix title={item.title} subTitle={item.subTitle} container={container} key={ind}>
                    <CreateFormItem values={item.questions} form={props.form} disabled={!props.onEdit} />
                  </TitleAffix>
                ))}
            </Skeleton>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default FormPatient;
