/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { rowClassName, _cleanObject } from "@Utils/utils";

import { useAuthContextState } from "@AuthContext/store";
import { useAuthContextDispatch } from "@AuthContext/store";
import { PaginationExcludeTotal } from "@Interface/service";
import {
  Button,
  Col,
  message,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";

import PageHeader from "@Components/PageHeader";
import FormSearch, { SearchFailed } from "@Components/form/FormSearch";

import "../styles/index.less";
import { DeleteUserByIdAPI, GetUserListApi } from "@Services/api/user";
import { _RES_validateError } from "@Utils/validate";
import { getRoleAPI } from "@Services/api/role";
import { Role } from "@Components/DrawerProfile";
import { RESGetUserService, UserItem } from "@Interface/user";

type Props = {};

const UserManagementList = (props: Props) => {
  const navigate = useNavigate();
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalPatientList, setTotalPatientList] = useState<number>(0); //200
  const [patientList, setPatientList] = useState<UserItem[] | []>([]);
  const [pagination, setPagination] = useState<PaginationExcludeTotal>({
    page: 1,
    perpage: 20,
  });
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [role, setRole] = useState<Role>();
  const [clean, setClean] = useState<object>();

  useEffect(() => {
    setClean(clean);
    getApi();
    return () => {
      setClean({});
    };
  }, []);

  const getApi = () => {
    getUsertList();
    getRoledata();
  };

  const getUsertList = async (search?: SearchFailed) => {
    setIsLoading(true);
    const keyOnLoad = "loginPatientList";
    const params = {
      page: pagination.page,
      limit: pagination.perpage,
      ...search,
    };
    const res = await GetUserListApi<RESGetUserService>(
      params,
      token["accessToken"]
    );

    if (res.status === "fail") {
      setIsLoading(false);
      message.error({ content: res.message, keyOnLoad, duration: 2 });
      return false;
    }
    if (res.code === 401) {
      setIsLoading(false);
      // message.error({ content: res.message, keyOnLoad, duration: 2 });
      _signOut();
      return false;
    }
    if (res.code === 200) {
      setPatientList(res.result[0]["data"]);
      setTotalPatientList(res.result[0]["total"]);
      setIsLoading(false);
      return true;
    } else {
      setIsLoading(false);
      message.error({ content: res.message, keyOnLoad, duration: 2 });
      return false;
    }
  };

  const getRoledata = async () => {
    try {
      const res = await getRoleAPI(token.accessToken);
      setRole(res.result[0].data);
    } catch (error) { }
  };

  const onFinish = (value: SearchFailed) => {
    getUsertList(_cleanObject.obj(value));
  };
  const onCancel = () => {
    getUsertList();
  };

  const removePatientById = async (visitId: number) => {
    try {
      const keyOnLoad = "removePatientById";
      setIsLoadingConfirm(true);
      const res = await DeleteUserByIdAPI(visitId, token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "ลบข้อมูลรายชื่อผู้ป่วยสำเร็จ",
          runOption: () => {
            setIsLoading(false);
            setIsLoadingConfirm(false);
            setPatientList((pervVisitPendingList) =>
              pervVisitPendingList.filter((p) => p.id !== visitId)
            );
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setIsLoadingConfirm(false);
    }
  };

  const columns = [
    {
      title: "#",
      key: "id",
      width: 50,
      render: (text: string, record: UserItem, rowIndex: number) => (
        <span>{rowIndex + 1}</span>
      ),
    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "อีเมล์",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "บทบาท",
      dataIndex: "role",
      key: "role",
      render: (text: string, record: UserItem) => {
        return record.role.name
      }
    },
    {
      title: "จัดการ",
      key: "action",
      width: 250,
      align: "center" as const,
      render: (text: string, record: UserItem, rowIndex: number) => (
        <Space>
          <Button
            type="ghost"
            className="flex-center btn-action"
            onClick={() => viewUserByVisitId(record.uuid)}
          >
            <EyeOutlined />
          </Button>
          <Popconfirm
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            title="ยืนยันการลบข้อมูลผู้ป่วย？"
            // placement="bottomRight"
            onConfirm={() => removePatientById(record.id)}
            okButtonProps={{ loading: isLoadingConfirm }}
          >
            <Button type="ghost" className="flex-center btn-action">
              <MdDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const viewUserByVisitId = (id: string | undefined) => {
    navigate("/user-management/view-info/" + id);
  };

  return (
    <Row>
      <Col span={24}>
        <PageHeader
          title="จัดการผู้ใช้"
          subTitle="จัดการผู้ใช้ทั้งหมด"
          action={
            <Row justify="end">
              <Button
                type="primary"
                size="large"
                onClick={() => navigate("/user-management/create")}
                style={{ paddingLeft: 30, paddingRight: 30 }}
              >
                <PlusOutlined /> เพิ่มผู้ใช้
              </Button>
            </Row>
          }
        />
      </Col>
      <Col span={24} className="content">
        <FormSearch
          onFinish={onFinish}
          onCancel={onCancel}
          type={"user"}
          data={role}
        />
        <Table
          size="small"
          rowKey="id"
          loading={isLoading}
          columns={columns}
          className="abus-table"
          dataSource={patientList}
          pagination={false}
          rowClassName={rowClassName}
          scroll={{ x: 1500 }}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                viewUserByVisitId(record.uuid);
              },
            };
          }}
        />
        <Row justify="end" style={{ marginTop: 20 }}>
          <Col>
            {totalPatientList / pagination?.perpage > 1 && (
              <Pagination
                className="abus-pagination"
                total={totalPatientList}
                onChange={(page: number, pageSize: number) => {
                  setPagination({
                    ...pagination,
                    page: page,
                    perpage: pageSize,
                  });
                }}
                defaultCurrent={pagination.page}
                defaultPageSize={pagination.perpage}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserManagementList;
