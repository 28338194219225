import { ColProps, InputProps, RowProps, SelectProps } from 'antd';

export enum FormItemType {
  CHOICE = 'choice',
  SELECT = 'select',
  TEXT_AREA = 'textArea',
  INPUT_TEXT = 'input',
  INPUT_LINK = 'inputLink',
  IMAGE_MARKER = 'imageMarker',
  INPUT_NUMBER = 'inputNumber',
  UPLOAD_FILES = 'uploadFiles',
  INPUT_CHANNEL = 'inputChannel',
  INPUT_DECIMAL_NUMBER = 'inputDecimalNumber',
  MEDIA = 'media',

  ROW = 'row',
  COL = 'column',
  FORM_LIST_RelativeName = 'formListRelativeName',
  FORM_LIST_RelativeName_Question2 = 'formListRelativeName2',
  FORM_LIST_RelativeDoctor = 'formListRelativeDoctor',
  FORM_LIST_RelativeDoctorMass = 'formListRalativeDoctorMass',
}
export type AttachmentType = {
  [FormItemType.INPUT_LINK]: FormItemType.INPUT_LINK;
  [FormItemType.UPLOAD_FILES]: FormItemType.UPLOAD_FILES;
};
export interface _CustomItemOptionQuestion {
  id: number;
  value: boolean | string | number;
  title: string;
}
export interface _CustomItemOptionalOption extends _CustomItemOptionQuestion {
  children: _CustomItemQuestionItem[];
}

export interface _CustomItemQuestionItem {
  type: string;
  title: string;
  title2?: string;
  keyName: string;

  colSpan?: ColProps;
  propsRow?: RowProps;
  propsInput?: InputProps;
  propsSelect?: SelectProps;
  propsInputNumber?: {
    min?: number;
    max?: number;
    style?: React.CSSProperties;
  };
  propsTextArea?: {
    rows?: number;
    showCount?: boolean;
    placeholder?: string;
  };

  imageMarkerOption?: { src?: string };
  option?: _CustomItemOptionQuestion[];
  optionalOption?: _CustomItemOptionalOption;
  required?: boolean;
  children?: _CustomItemQuestionItem[];
  description?: string;
  children2?: _CustomItemQuestionItem[];

  [Key: string]: any;
}
