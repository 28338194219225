import React, { Component } from 'react';

export let longdo;
export let map;
export let search;
export let suggest;

export class LongdoMap extends Component {

  constructor(props) {
    super(props);
    this.mapCallback = this.mapCallback.bind(this);
  }

  mapCallback() {
    longdo = window.longdo
    map = new window.longdo.Map({
      placeholder: document.getElementById(this.props.id),
      language: 'th'
    });
    search =  document.getElementById('search')
    map.Search.placeholder(
      document.getElementById('result')
    )
    // search.oninput = function() {
    //   if (search.value.length < 3) {
    //     suggest.style.display = 'none';
    //     return;
    //   }
      
    //   map.Search.suggest(search.value, {
    //     area: 10
    //   });
    // };
    // suggest = document.getElementById('suggest');
   
    
  }

  componentDidMount() {
    const existingScript = document.getElementById('longdoMapScript');
    const callback = this.props.callback

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://api.longdo.com/map/?key=${this.props.mapKey}`;
      script.id = 'longdoMapScript';
      document.body.appendChild(script);

      script.onload = () => {
        this.mapCallback();
        if (callback) callback();
      };
    }

    if (existingScript) this.mapCallback();
    if (existingScript && callback) callback();
  }

  render() {
    return (
        <div id={this.props.id} style={{width:'100%',height:'100%'}}>
        
        </div>
    );
  }

}