import { Col, Collapse, Empty, Modal, Row, Typography } from "antd";
interface Props {
  visible: boolean;
  onOk: VoidFunction;
  onCancel: VoidFunction;
  values?: IResultReport3D[];
}
export interface IResultReport3D {
  name: string;
  lesion: number;
  score: [string, number][];
}
const ModalReport3D = ({ visible, values, onCancel }: Props) => {
  return (
    <Modal
      title="Report 3D ABUS"
      centered
      visible={visible}
      onCancel={onCancel}
      footer={false}
      width={1000}
    >
      {!!values?.length ? (
        values?.map((item, index) => (
          <Collapse
            // collapsible="header"
            expandIconPosition="right"
            style={{ marginBottom: 10 }}
          >
            <Collapse.Panel
              header={<div style={{ width: "100%" }}>{item.name}</div>}
              key={index}
            >
              <Row gutter={[16, 16]}>
                <Col span={16}>
                  <Typography.Text>
                    จำนวน Lesion : {item.lesion}
                  </Typography.Text>
                  <div style={{ overflow: "hidden", borderRadius: 6 }}>
                    <Row
                      style={{
                        background: "#0051ff",
                        color: "#fff",
                        textAlign: "center",
                      }}
                      gutter={[16, 16]}
                    >
                      <Col span={1} style={{ padding: "10px" }}></Col>
                      <Col span={8} style={{ padding: "10px" }}>
                        Lestion
                      </Col>
                      <Col span={8} style={{ padding: "10px" }}>
                        Average classification score
                      </Col>
                      <Col span={7} style={{ padding: "10px" }}>
                        Malignant
                      </Col>
                    </Row>
                    {item?.score?.map((itemScore, index) => (
                      <ReportItem
                        values={[
                          itemScore[0],
                          itemScore[1],
                          Math.round(itemScore[1] * 100),
                        ]}
                      />
                    ))}
                  </div>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        ))
      ) : (
        <Empty />
      )}
    </Modal>
  );
};

export default ModalReport3D;

interface IReportItem {
  values: any[];
}
const ReportItem = ({ values }: IReportItem) => {
  const getColor = (value: number): string => {
    return value > 50 ? "#FFD000" : value > 80 ? "#FF1E00" : "#00FF51";
  };
  return (
    <Row
      style={{ background: "#0051ff", textAlign: "center" }}
      gutter={[16, 16]}
    >
      <Col
        span={1}
        style={{ background: getColor(values[2]), padding: "10px" }}
      ></Col>
      <Col span={8} style={{ background: "#EFEFEF", padding: "10px" }}>
        {values[0]}
      </Col>
      <Col span={8} style={{ background: "#F8F5F5", padding: "10px" }}>
        {values[1]}
      </Col>
      <Col span={7} style={{ background: "#EFEFEF", padding: "10px" }}>
        {values[2]}%
      </Col>
    </Row>
  );
};
