// ------------------------- Layout ------------------------------- //
import ABusDashboardLayout from "@Layout/ABusDashboardLayout";
import DefaultLayout from "@Layout/DefaultLayout";
// ------------------------- Pages ------------------------------- //
import Page404 from "@Views/pages/error/Page404";
import Page500 from "@Views/pages/error/Page500";
import LoginPage from "@Views/pages/LoginPage";
// ------------------------- page ------------------------------- //
import Report from "@Views/Report";
import Reportinfo from "@Views/Report/FormDoctor";
import ReportList from "@Views/Report/List";

import Doctor from "@Views/Doctor";
import Doctorinfo from "@Views/Doctor/FormDoctor";
import DoctorList from "@Views/Doctor/List";

import Patient from "@Views/Patient";
import CreatePatient from "@Views/Patient/CreatePatient";
import HistoryList from "@Views/Patient/History";
import ViewsPatientAll from "@Views/Patient/List";
import ViewEditPatient from "@Views/Patient/ViewEditPatient";

import Dashboard from "@Views/Dashboard";

import Diagnostics from "@Views/Diagnostics";
import FormDiagnostics from "@Views/Diagnostics/FormDiagnostics";
import DiagnosticsList from "@Views/Diagnostics/List";

import UserManagement from "@Views/UserManagement";
import CreateUser from "@Views/UserManagement/CreateUser";
import viewsUserManagementId from "@Views/UserManagement/EditUser";
import ViewsUserManagementAll from "@Views/UserManagement/List";

import UploadImageData from "@Views/UploadImageData";
import FormPatientInfoByVisitId from "@Views/UploadImageData/FormPatientInfoByVisitId";
import ViewsUploadImageDataAll from "@Views/UploadImageData/List";

import Program from "@Views/Program";
import ViewsListProgram from "@Views/Program/List";
import PaymentProgram from "@Views/Program/Payment";
import ReceiptProgram from "@Views/Program/Receipt";
import ViewsProgramAll from "@Views/Program/ViewProgram";

// ------------------------- Settings ------------------------------- //
import Settings from "@Views/Settings";
import User from "@Views/Settings/User";
import FormUser from "@Views/Settings/User/FormUser";
// ------------------------- Interface ------------------------------- //
import { RouteCustom } from "@Routes/route.interface";

export const _routesDefault: RouteCustom[] = [
  {
    path: "/error",
    element: DefaultLayout,
    children: [
      { path: "/error/404", element: Page404 },
      { path: "/error/500", element: Page500 },
      { path: "*", navigateElement: { to: "/error/404" } },
      { index: true, navigateElement: { to: "/error/500" } },
    ],
  },
  { path: "/login", element: LoginPage, requireAuth: false },
  { path: "*", navigateElement: { to: "/error/404" }, requireAuth: false },
];
export const _requirePermission: RouteCustom[] = [
  {
    path: "/",
    element: ABusDashboardLayout,
    children: [
      { index: true, navigateElement: { to: "/dashboard" } },
      {
        path: "/dashboard",
        keyName: "dashboard",
        requireAuth: true,
        element: Dashboard,
      },
      {
        path: "/patient",
        keyName: "patient",
        requireAuth: true,
        element: Patient,
        children: [
          { index: true, navigateElement: { to: "/patient/view-list" } },
          {
            path: "/patient/view-list",
            keyName: "listPatient",
            element: ViewsPatientAll,
          },
          {
            path: "/patient/create",
            keyName: "createPatient",
            element: CreatePatient,
          },
          {
            path: "/patient/view-edit/:id",
            keyName: "viewEditPatient",
            element: ViewEditPatient,
          },
          {
            path: "/patient/history/:id",
            keyName: "historyList",
            element: HistoryList,
          },
        ],
      },
      {
        path: "/upload",
        keyName: "upload_data",
        requireAuth: true,
        element: UploadImageData,
        children: [
          { index: true, navigateElement: { to: "/upload/view-list" } },
          {
            path: "/upload/view-list",
            keyName: "uploadImageDataList",
            element: ViewsUploadImageDataAll,
          },
          {
            path: "/upload/patient-info/:visitId",
            keyName: "formPatientInfo",
            element: FormPatientInfoByVisitId,
          },
        ],
      },
      {
        path: "/diagnostics",
        keyName: "awaiting_diagnose",
        requireAuth: true,
        element: Diagnostics,
        children: [
          { index: true, navigateElement: { to: "/diagnostics/view-list" } },
          {
            path: "/diagnostics/view-list",
            keyName: "diagnosticsList",
            element: DiagnosticsList,
          },
          {
            path: "/diagnostics/view-info/:visitId",
            keyName: "FormDiagnostics",
            element: FormDiagnostics,
          },
        ],
      },
      {
        path: "/doctor",
        keyName: "diagnose",
        requireAuth: true,
        element: Doctor,
        children: [
          { index: true, navigateElement: { to: "/doctor/view-list" } },
          {
            path: "/doctor/view-list",
            keyName: "doctorList",
            element: DoctorList,
          },
          {
            path: "/doctor/view-info/:visitId",
            keyName: "doctorinfo",
            element: Doctorinfo,
          },
          {
            path: "/doctor/view-askopinion/:visitId",
            keyName: "doctoraskopinion",
            element: Doctorinfo,
          },
        ],
      },
      {
        path: "/user-management",
        keyName: "user_management",
        requireAuth: true,
        element: UserManagement,
        children: [
          {
            index: true,
            navigateElement: { to: "/user-management/view-list" },
          },
          {
            path: "/user-management/view-list",
            keyName: "viewsUserManagementAll",
            element: ViewsUserManagementAll,
          },
          {
            path: "/user-management/view-info/:visitId",
            keyName: "viewsUserManagementId",
            element: viewsUserManagementId,
          },
          {
            path: "/user-management/create",
            keyName: "createUser",
            element: CreateUser,
          },
        ],
      },
      {
        path: "/report",
        keyName: "report",
        requireAuth: true,
        element: Report,
        children: [
          { index: true, navigateElement: { to: "/report/view-list" } },
          {
            path: "/report/view-list",
            keyName: "reportList",
            element: ReportList,
          },
          {
            path: "/report/view-info/:visitId",
            keyName: "reportinfo",
            element: Reportinfo,
          },
        ],
      },
      {
        path: "/settings",
        element: Settings,
        keyName: "settings",
        requireAuth: true,
        children: [
          { index: true, navigateElement: { to: "/settings/user" } },
          {
            path: "/settings/user",
            element: User,
            children: [
              // { index: true, element: Settings },
              { path: "/settings/user/create", element: FormUser },
              { path: "/settings/user/edit/:userId", element: FormUser },
              {
                path: "*",
                keyName: "error",
                navigateElement: { to: "/error/404" },
              },
            ],
          },
          {
            path: "*",
            keyName: "error",
            navigateElement: { to: "/error/404" },
          },
        ],
      },
      {
        path: "/program",
        keyName: "program",
        requireAuth: true,
        element: Program,
        children: [
          { index: true, navigateElement: { to: "/program/view-list" } },
          {
            path: "/program/view-list",
            keyName: "programlist",
            element: ViewsListProgram,
          },
          {
            path: "/program/view-program/:id",
            keyName: "viewprogram",
            element: ViewsProgramAll,
          },
          {
            path: "/program/payment-program/:id",
            keyName: "paymentprogram",
            element: PaymentProgram,
          },
          {
            path: "/program/receipt-program/:id",
            keyName: "receiptprogram",
            element: ReceiptProgram,
          },
        ],
      },
      { path: "*", keyName: "error", navigateElement: { to: "/error/404" } },
    ],
  },
];

export const routes: RouteCustom[] = [..._requirePermission, ..._routesDefault];
