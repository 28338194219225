import { Params } from "@Interface/service";
import { Fetch, FetchPDF } from "@Utils/fetch/fetch";

export const GetReportAllAPI = async <T = any>(
  params: Params,
  token: string
) => {
  const res = await Fetch<T>({
    params,
    token: token,
    method: "GET",
    path: `/v1/visit/report`,
  });
  return res;
};

export const DeleteReportByVisitIdAPI = async <T = any>(
  patientId: number,
  token: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "DELETE",
    path: `/v1/patient/${patientId}`,
  });
  return res;
};

export const GetExcelAPI = async (params: Params, token: string) => {
  const res = await FetchPDF({
    params,
    token: token,
    method: "GET",
    path: `/v1/visit/excel`,
  });
  return res;
};

export const _ReportService = {
  GetReportAllAPI,
  DeleteReportByVisitIdAPI,
  GetExcelAPI,
};
export default _ReportService;
