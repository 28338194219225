/* eslint-disable react-hooks/exhaustive-deps */
import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import ABUS from "@Components/form/DoctorDiagnose/abus";
import { ITypeQuestionProp } from "@Interface/patient";
import type { VisitField, VisitFiles } from "@Interface/visit";
import { getDiagnosisFormAPI } from "@Services/api/diagnostics";
import { _RES_validateError } from "@Utils/validate";
import { Col, FormInstance, Input, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import IndicationForm from "./indicationForm";
import MainForm from "./mainForm";
import Mammogram from "./mammogram";
import NoteForm from "./noteForm";
import "./styles/index.less";

type Props = {
  dataForm?: FormInstance<any> | any;
  visitFiles?: { [key: string]: VisitFiles[] } | null;
  studyId?: string | null;
  visitInfo?: VisitField | null;
};

const DoctorDiagnose = (props: Props) => {
  const { _signOut } = useAuthContextDispatch();
  const { token } = useAuthContextState();
  const [questionsForm, setQuestionsForm] = useState<ITypeQuestionProp | null>(
    null
  );

  useEffect(() => {
    getDiagnosisForm();
    return () => {
      setQuestionsForm(null);
    };
  }, []);

  const getDiagnosisForm = async () => {
    try {
      const keyOnLoad = "getDiagnosisForm";
      const res = await getDiagnosisFormAPI(token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setQuestionsForm(res.result[0]);
            return true;
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Row gutter={[16, 16]} className="doctor-diagnose">
      <Col span={24}>
        <IndicationForm
          dataForm={props.dataForm}
          questionsForm={questionsForm}
        />
      </Col>
      <Col span={24}>
        <Tabs type="card" size="middle">
          <Tabs.TabPane tab="ABUS" key="item-1">
            <ABUS
              dataForm={props.dataForm}
              questionsForm={questionsForm}
              fileList={props?.visitFiles?.["A_BUS"] || []}
              studyId={props.studyId}
              visitInfo={props.visitInfo}
              reportFileList={props.visitFiles?.["REPORT_A_BUS"]}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Mammogram" key="item-2">
            <Mammogram
              dataForm={props.dataForm}
              questionsForm={questionsForm}
              fileList={props?.visitFiles?.["MAMMOGRAM"] || []}
              studyId={props.studyId}
              visitInfo={props.visitInfo}
            />
          </Tabs.TabPane>
        </Tabs>
      </Col>
      <Col span={24}>
        <MainForm dataForm={props.dataForm} questionsForm={questionsForm} />
      </Col>
      <Col span={24}>
        <NoteForm dataForm={props.dataForm} questionsForm={questionsForm} />
      </Col>
      <Col span={24}>
        <div className="box container-box">
          <div className="card">
            <div className="card-header">Radiologist Name</div>
            <div className="card-body">
              {props.visitInfo?.diagnose?.map((item: any, index) => {
                return (
                  <div key={index}>
                    <div>
                      <span> Name: </span>
                      <span>
                        <Input value={item?.doctor?.fullName || ""} disabled />
                      </span>
                    </div>
                    <div>
                      <span> Holding medical license no:</span>
                      <span>
                        <Input
                          value={item?.doctor?.medicalLicense || ""}
                          disabled
                        />
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DoctorDiagnose;
