import { Fetch } from "@Utils/fetch/fetch";

export const getProgramAPI = async <T = any>(token: string) => {
    const res = await Fetch<T>({
        token: token,
        method: "GET",
        path: "/v1/program",
    });
    return res;
};

export const CreateNewProgramAPI = async <T = any>(
    data: any,
    token: string
) => {
    const res = await Fetch<T>(
        {
            token: token,
            method: "POST",
            path: "/v1/program",
            data,
        },
    );
    return res;
};
