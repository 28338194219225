import { useAuthContextDispatch, useAuthContextState } from '@AuthContext/store';
import { RESLoginService } from '@Interface/login';
import { LoginApi } from '@Services/api/auth';
import { _RES_validateError } from '@Utils/validate';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Image, Input, message, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './styles/index.less';

const { Title } = Typography;

const LoginPage = () => {
  const { auth } = useAuthContextState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { _signIn, _signOut } = useAuthContextDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (auth) {
      return navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const onFinish = async (values: any) => {
    try {
      const keyOnLoad = 'isLogin';
      setIsLoading(true);
      message.loading({ content: 'Loading...', keyOnLoad });
      const res = await LoginApi<RESLoginService>({
        email: values.email,
        password: values.password,
      });
      _RES_validateError(res, keyOnLoad, {
        Code_401: {
          runOption: _signOut,
        },
        Code_200: {
          message: 'login is success.',
          runOption: () => {
            _signIn({
              token: { accessToken: res.result[0]['accessToken'] },
              userInfo: {
                fullName: res.result[0]['fullName'],
                image: res.result[0]['image'],
                role: res.result[0]['role'],
                permission: res.result[0]['permission'],
              },
            });
            setIsLoading(false);
            return navigate('/');
          },
        },
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row justify="end" align="middle" className="login-container">
      <Col md={12} lg={8}>
        <Card id="components-form-normal-login" bordered={false} className="justify-content-center">
          <Form
            layout="vertical"
            name="login"
            className="login-form"
            requiredMark="optional"
            autoComplete="off"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
          >
            <Row justify="start">
              <Col>
                <Image src={'/images/logo.png'} preview={false} className="logo" />
              </Col>
            </Row>
            <Row justify="start">
              <Col>
                <div className="flex-start flex-column welcome-container">
                  <Title level={4} className="welcome-title">
                    <Trans i18nKey="login.title">welcome</Trans>
                  </Title>
                  <Trans i18nKey="login.description">Login to your account to continue</Trans>
                </div>
              </Col>
            </Row>

            <Form.Item
              label={<Trans i18nKey="login.email">email</Trans>}
              name="email"
              rules={[
                {
                  required: true,
                  message: <Trans i18nKey="login.error.input.email">Please input your email!</Trans>,
                },
              ]}
            >
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t('login.email')}
              />
            </Form.Item>
            <Form.Item
              label={<Trans i18nKey="login.password">Password</Trans>}
              name="password"
              rules={[
                {
                  required: true,
                  message: <Trans i18nKey="login.error.input.password">Please input your Password!</Trans>,
                },
              ]}
            >
              <Input.Password
                size="large"
                type="password"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={t('login.password')}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={isLoading}>
                <Trans i18nKey="login.submit">Submit</Trans>
              </Button>
            </Form.Item>
            <Row justify="center">
              <Col>
                <span className="copyright">
                  <Trans i18nKey="copyright">
                    <code>&copy;</code> Copyright <code>&copy;</code> 2022 AICEDA 3D-ABUS, All rights reserved.
                  </Trans>
                </span>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      {/* <div>
        <button type="submit" style={{ fontWeight: i18n.language === 'th' ? 'bold' : 'normal' }} onClick={() => i18n.changeLanguage('th')}>
          Thailand
        </button>
        <button type="submit" style={{ fontWeight: i18n.language === 'en' ? 'bold' : 'normal' }} onClick={() => i18n.changeLanguage('en')}>
          English
        </button>
      </div> */}
    </Row>
  );
};

export default LoginPage;
