import { Suspense } from "react";
import { HashRouter } from "react-router-dom";
import { AuthContextProvider } from "@AuthContext/store";
import "dayjs/locale/th";
import RenderRoute from "@Routes/_RenderRoute";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
function App() {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <AuthContextProvider>
          <RenderRoute />
        </AuthContextProvider>
      </Suspense>
    </HashRouter>
  );
}

export default App;
