import { Params } from "@Interface/service";
import { Fetch, FetchFormData } from "@Utils/fetch/fetch";

export const GetPatientByIdAPI = async <T = any>(
  patientId: string,
  token: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "GET",
    path: `/v1/patient/${patientId}`,
  });
  return res;
};

export const DeletePatientByIdAPI = async <T = any>(
  patientId: number,
  token: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "DELETE",
    path: `/v1/patient/${patientId}`,
  });
  return res;
};

export const CreateNewPatientAPI = async <T = any>(
  data: any,
  token: string
) => {
  const res = await Fetch<T>({
    data,
    token: token,
    method: "POST",
    path: "/v1/patient",
  });
  return res;
};

export const UpdateNewPatientAPI = async <T = any>(
  patientId: string,
  data: any,
  token: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "PUT",
    path: `/v1/patient/${patientId}`,
    data,
  });
  return res;
};

export const GetPatientHistoryListByIDApi = async <T = any>(
  patientId: string,
  params: Params,
  token: string
) => {
  const res = await Fetch<T>({
    params: params,
    token: token,
    method: "GET",
    path: `/v1/patient/${patientId}/history`,
  });
  return res;
};

export const GetPatientListApi = async <T = any>(
  params: Params,
  token: string
) => {
  const res = await Fetch<T>({
    params,
    token: token,
    method: "GET",
    path: "/v1/patient",
  });
  return res;
};

export const GetPatientQuestionFormApi = async <T = any>(
  // params: Params,
  token: string
) => {
  const res = await Fetch<T>({
    // params: params,
    token: token,
    method: "GET",
    path: "/v1/questionnaire",
  });
  return res;
};
