import { Types } from "./type";
import { initialValue } from "./initialState";
import { InitialState } from "./interface/initialType";
import { DispatchType } from "./interface/context";
// import { userInfo } from "os";

const reducerState = (
  state = initialValue,
  action: DispatchType
): InitialState => {
  switch (action.type) {
    case Types.SetWidth:
      return {
        ...state,
        width: action.payload,
      };
    case Types.SetCollapsed:
      return { ...state, collapsed: action.payload };
    default:
      return state;
  }
};

export default reducerState;
