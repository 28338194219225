import { Types } from "./type";
import { Dispatch } from "react";
import { DispatchType } from "./interface/context";
import { Payload } from "./interface/initialType";

export const setWidth =
  (dispatch: Dispatch<DispatchType>) =>
  async (payload: Payload[Types.SetWidth]) => {
    dispatch({
      type: Types.SetWidth,
      payload: payload,
    });
  };

export const setCollapsed =
  (dispatch: Dispatch<DispatchType>) =>
  async (payload: Payload[Types.SetCollapsed]) => {
    dispatch({
      type: Types.SetCollapsed,
      payload: payload,
    });
  };
