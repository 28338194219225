import { useAuthContextState } from "@AuthContext/store";
import { VisitProgram, invoiceDetail } from "@Interface/invoice";
import { Receipt } from "@Interface/receipt";
import { getInvoiceByIdAPI } from "@Services/api/invoice";
import { CreateNewMediaAPI } from "@Services/api/media";
import { rowClassName } from "@Utils/utils";
import {
  Card,
  Col,
  Divider,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";

type Props = {
  onChange: (value: Receipt) => void;
};
// const { Title } = Typography;

const FormPayment = (props: Props) => {
  const [invoice, setInvoice] = useState<invoiceDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const params = useParams() as { id: string };
  const locate = useLocation();
  const [discountType, setDiscountType] = useState<"BATH" | "PERCENT">("BATH");
  const [discount, setDiscount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [uploadImage, setUploadImage] = useState<number>();

  useEffect(() => {
    getInvoiceAPI(String(locate.state));
    return () => {};
  }, []);

  const { token } = useAuthContextState();

  const getInvoiceAPI = async (id: string) => {
    console.log(id);
    try {
      setIsLoading(true);
      const res = await getInvoiceByIdAPI(Number(id), token.accessToken);
      setInvoice(res.result?.[0]);
      props.onChange({
        total: invoice?.total || "0",
        net: invoice?.net || "0",
        discount: invoice?.discount || "0",
        paymentType: "CASH" || "0",
        discountType: invoice?.discountType || "BATH",
        invoiceId: res.result?.[0].id || 0,
        slipId: uploadImage || 0,
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      return false;
    }
  };
  const onChange = (value: number) => {
    if (discountType === "BATH") setDiscount(value);

    if (discountType === "PERCENT")
      setDiscount((Number(invoice?.total) * value) / 100);
  };
  const handleChange = (value: "BATH" | "PERCENT") => {
    setDiscountType(value);
  };
  const columns = [
    {
      title: "#",
      key: "id",
      width: 50,
      align: "center" as const,
      render: (text: string, record: VisitProgram, rowIndex: number) => (
        <span>{rowIndex + 1}</span>
      ),
    },
    {
      title: "ชื่อโปรแกรม",
      dataIndex: "idCard",
      key: "idCard",

      render: (text: string, record: VisitProgram, rowIndex: number) => {
        return <span>{record.program.name}</span>;
      },
    },
    {
      title: "จำนวน",
      dataIndex: "hn",
      key: "hn",
      render: (text: string, record: VisitProgram, rowIndex: number) => {
        return <span>{record.qty}</span>;
      },
    },
    {
      title: "ราคาต่อหน่วย",
      dataIndex: "createAt",
      key: "createAt",
      render: (text: string, record: VisitProgram, rowIndex: number) => {
        return <span>{record.program.price}</span>;
      },
    },
  ];

  const onChangeUpload = async (info: any) => {
    const formData = new FormData();
    formData.append("file", info.file.originFileObj);
    const res = await CreateNewMediaAPI(formData, token.accessToken);
    setUploadImage(res.result[0].id);
  };

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <Card title="ใบแจ้งหนี้">
            <Row>
              {/* <Col>
                                <span>โปรแกรมที่เลือก</span>
                            </Col> */}
              <Col span={24}>
                <Table
                  size="small"
                  rowKey="id"
                  dataSource={invoice?.visit?.visitProgram || []}
                  columns={columns}
                  className="abus-table"
                  pagination={false}
                  rowClassName={rowClassName}
                  // scroll={{
                  //     x: "auto"
                  // }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="รายการชำระ" style={{ width: 300 }}>
            <Row className="mt-05" gutter={[16, 16]}>
              <Col span={24}>
                <span>ยอดรวมทั้งหมด: {invoice?.total} บาท</span>
              </Col>
              <Col span={24}>
                <span>ส่วนลด:</span>
                <InputNumber
                  value={Number(invoice?.discount) || 0}
                  min={0}
                  style={{ width: 60 }}
                  onChange={() => onChange}
                />
                <Select
                  value={discountType}
                  style={{ width: 120 }}
                  onChange={handleChange}
                  options={[
                    { value: "PERCENT", label: "เปอร์เซ็นต์" },
                    { value: "BATH", label: "บาท" },
                  ]}
                />
              </Col>
              <Col span={24}>
                <span>ยอดคงค้าง: {discount || 0.0} บาท </span>
              </Col>
              {/* <Col>
                                <span>{""} บาท</span>
                            </Col> */}
              <Divider />
              <Col span={24}>
                <span>
                  ยอดเงินชำระทั้งหมด {Number(invoice?.total || "0") - discount}{" "}
                  บาท
                </span>
              </Col>
              {/* <Col>
                                <span>{""} บาท</span>
                            </Col> */}
            </Row>
          </Card>
        </Col>
      </Row>
      <Card>
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <span>การชำระ</span>
          </Col>
          <Col span={12} offset={1}>
            <span> วิธีการชำระ </span>
          </Col>
          <Col span={12} offset={1}>
            <FormItem
              name={"paymentType"}
              rules={[{ required: true, message: "กรุณากรอกข้อมูล" }]}
            >
              <Radio.Group size="small">
                <Space direction="vertical">
                  <Radio value={"CASH"}>CASH</Radio>
                </Space>
              </Radio.Group>
            </FormItem>
          </Col>
        </Row>
        {/* <Row gutter={[16, 24]}>
          <Col span={24}>
            <span>อัปโหลด</span>
          </Col>
          <Col span={12} offset={1}>
            <Card style={{ width: "auto" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Upload onChange={onChangeUpload}>
                  <Button icon={<UploadOutlined />}>อัปโหลดไฟล์</Button>
                </Upload>
                <span>รองรับไฟล์ .jpg และ .png</span>
              </div>
            </Card>
          </Col>
        </Row> */}
      </Card>
    </React.Fragment>
  );
};
export default FormPayment;
