import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import PageHeader from "@Components/PageHeader";
import PagePaper from "@Components/PagePaper";
import PatientStatus from "@Components/form/PatientStatus";
import { Receipt } from "@Interface/receipt";
import { RESGetVisitInfoService, VisitField } from "@Interface/visit";
import { CreateNewReceiptAPI } from "@Services/api/receipt";
import _VisitService from "@Services/api/visit";
import { _RES_validateError } from "@Utils/validate";
import { Button, Col, Divider, Form, Row, Skeleton } from "antd";
import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import FormPayment from "../FormPayment";

type Props = {};

const PaymentProgram = (props: Props) => {
  const params = useParams() as { id: string };
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [form] = Form.useForm();
  const [visitInfo, setVisitInfo] = useState<VisitField | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imgProfile, setImageProfile] = useState<any>();
  const [receiptInfo, setReceiptInfo] = useState<Receipt>();

  useState<boolean>(false);
  const [id, setId] = useState<number>();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    !!params.id && getVisitInfo(params.id);
    return () => {};
  }, [params]);

  const getVisitInfo = async (visitId: string) => {
    try {
      const keyOnLoad = "getVisitInfo";
      setIsLoading(true);
      const res =
        await _VisitService.GetVisitInfoByIdAPI<RESGetVisitInfoService>(
          visitId,
          token.accessToken
        );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setVisitInfo(res.result?.[0]);
            setImageProfile(res.result?.[0]?.patient?.imageUrl);
            setId(res.result?.[0]?.id);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      return false;
    }
  };

  const onFinish = async () => {
    try {
      setIsLoading(true);
      const res = await CreateNewReceiptAPI(
        { ...receiptInfo, visitId: Number(params.id) },
        token.accessToken
      );

      navigate("/program/receipt-program/" + params.id, {
        state: res.result[0].invoiceId,
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="">
        <PageHeader
          title={"เลือกโปรแกรม"}
          subTitle="รายการโปรแกรมรักษา"
          action={
            <Row gutter={16} justify="end">
              <Col>
                <Button
                  type="default"
                  size="middle"
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                >
                  กลับ
                </Button>
              </Col>
              {/* <Col>
                <Button
                  type="default"
                  size="middle"
                  onClick={() => {}}
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                >
                  พิมพ์ใบแจ้งหนี้
                </Button>
              </Col> */}
              <Col>
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => onFinish()}
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                >
                  ยืนยันการชำระ
                </Button>
              </Col>
            </Row>
          }
        />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <PagePaper>
              <Skeleton active loading={isLoading}>
                <PatientStatus
                  visitInfo={visitInfo}
                  imgProfile={imgProfile}
                  id={id}
                />
                <Divider />
              </Skeleton>
            </PagePaper>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form
              form={form}
              size="large"
              layout="vertical"
              className="FormUpload"
            >
              <FormPayment
                onChange={(value: Receipt) => {
                  setReceiptInfo(value);
                }}
              />
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default PaymentProgram;
