import { useMediaQuery } from 'react-responsive'
const sizeScreen = {
  Mobile: {
    minWidth: 0,
    maxWidth: 767
  },
  Tablet: {
    minWidth: 768,
    maxWidth: 991
  },
  FullHD: {
    minWidth: 992,
    maxWidth: 1199
  },
  Desktop: {
    minWidth: 1200,
    maxWidth: 1599
  },
  LargeDesktop: {
    minWidth: 1600,
    maxWidth: 2400
  },
};

const useCheckMediaQuery = () => {
  const isMobile = useMediaQuery(sizeScreen.Mobile)
  const isTablet = useMediaQuery(sizeScreen.Tablet)
  const isFullHD = useMediaQuery(sizeScreen.FullHD)
  const isDesktop = useMediaQuery(sizeScreen.Desktop)
  const isLargeDesktop = useMediaQuery(sizeScreen.LargeDesktop)

  return { isMobile, isTablet, isFullHD, isDesktop, isLargeDesktop }
}

export default useCheckMediaQuery