import { EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, FormInstance, Row } from "antd";
import { useRef, useState } from "react";
import ModalViewer from "./viewer";

import { VisitFiles } from "@Interface/visit";
import { _CustomItemQuestionItem } from "../../interface";
import CardImage from "./CardImage";
import "./styles/index.less";

interface Props {
  visitInfo: any;
  params: any;
  openModal?: () => void;
  form?: FormInstance<any>;
  disabled?: boolean;
  cusname?: any;
  value: _CustomItemQuestionItem;
  fileList?: VisitFiles[];
}

const DicomMedia = ({ value, ...props }: Props) => {
  return (
    <Form.Item
      // name={[props.cusname, value['keyName']]}
      name={
        props?.cusname ? [props?.cusname, value["keyName"]] : value["keyName"]
      }
      rules={[
        {
          required: !!value.required,
          message: `กรุณาใส่ข้อมูล ${value["title"]}`,
        },
      ]}
      className="w-100"
    >
      <FormDicomMedia {...props} />
    </Form.Item>
  );
};

interface FormDicomMediaProps {
  visitInfo: any;
  params: any;
  openModal?: () => void;
  form?: FormInstance<any>;
  disabled?: boolean;
  isFormList?: string | number;
  value?: any[];
  onChange?: any;
  fileList?: VisitFiles[];
}

const FormDicomMedia = ({
  visitInfo,
  params,
  value,
  onChange,
  fileList,
  ...props
}: FormDicomMediaProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [visibleViewDICOM, setVisibleViewDICOM] = useState(false);

  const toggle = () => {
    setVisibleViewDICOM(!visibleViewDICOM);
  };

  const dataURLtoFile = (dataURL: string, filename: string) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/);
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime?.[1] });
  };

  const captureImage = (currentImg: any) => {
    const file = dataURLtoFile(
      currentImg,
      `abus-ultrasound-capture-${(value?.length || 0) + 1}.png`
    );

    const newValues = [...(value || []), { file, fileUrl: currentImg }];

    onChange?.(newValues);
    toggle();
  };

  const onDelete = (index: number) => {
    const newValues = value?.filter((_, i) => i !== index);
    onChange?.(newValues);
  };

  const openFilePickerDialog = () => {
    ref.current?.click();
  };

  const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) return;

    const fileArr = Object.values(files).map((e) => e);

    const image = await Promise.all(
      fileArr.map(async (e) => {
        return { file: e, fileUrl: await fileToBase64(e) };
      })
    );

    const newValues = [...(value || []), ...image];
    onChange?.(newValues);
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const onClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  return (
    <div>
      <Row>
        <Button
          type="default"
          size="large"
          onClick={toggle}
          icon={<EyeOutlined />}
          style={{ backgroundColor: "#FFFFFF" }}
        >
          DICOM VIEWER
        </Button>

        <Button
          type="default"
          size="large"
          onClick={openFilePickerDialog}
          icon={<UploadOutlined />}
          style={{ backgroundColor: "#FFFFFF", marginLeft: 10 }}
        >
          UPLOAD
        </Button>

        <input
          accept=".png,.jpg,.jpeg"
          multiple={true}
          type="file"
          ref={ref}
          style={{ display: "none" }}
          onChange={onFileSelect}
          onClick={onClick}
        />

        <Col span={24} style={{ paddingTop: 10 }}>
          <CardImage values={value || []} onDelete={onDelete} />
        </Col>
      </Row>

      <ModalViewer
        visible={visibleViewDICOM}
        onCancel={toggle}
        urls={fileList || []}
        visitId={params.visitId}
        captureImage={captureImage}
      />
    </div>
  );
};

export default DicomMedia;
