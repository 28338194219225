import React from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, FormInstance, Input, Row, InputNumber } from "antd";
import { _CustomItemQuestionItem } from "../../../interface";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  form?: FormInstance<any>;
  values: _CustomItemQuestionItem;
  disabled?: boolean;
  isFormList?: string | number;
}
const FormItemListRelative = ({ values, disabled, isFormList }: Props) => {
  const html = values["description"] || ``;
  return (
    <>
      <Form.List
        name={values["keyName"]}
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  new Error("กรุณาเพิ่มข้อมูล 1 รายชื่อเป็นอย่างน้อย")
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Row align="middle" justify="space-between" gutter={[16, 16]}>
                  <Col flex={1}>
                    <Form.Item
                      {...restField}
                      name={[name, "name"]}
                      label="ชื่อญาติ"
                      rules={[
                        { required: true, message: "Missing first name" },
                      ]}
                    >
                      <Input
                        placeholder="ใส่ข้อมูล ชื่อญาติ"
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item
                      {...restField}
                      label="ความสัมพันธ์"
                      name={[name, "relationship"]}
                      rules={[
                        { required: true, message: "ใส่ข้อมูล ความสัมพันธ์" },
                      ]}
                    >
                      <Input placeholder="ความสัมพันธ์" disabled={disabled} />
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item
                      {...restField}
                      label="อายุ"
                      name={[name, "age"]}
                      rules={[{ required: true, message: "ใส่ข้อมูลอายุ" }]}
                    >
                      <InputNumber<number>
                        min={0}
                        max={200}
                        disabled={disabled}
                        addonAfter={"ปี"}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      style={{ color: "red" }}
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <Form.Item style={{ marginTop: -20 }}>
              <Button
                type="link"
                size="small"
                onClick={() => add()}
                icon={<PlusCircleOutlined />}
                style={{ padding: 0 }}
              >
                เพิ่มข้อมูล
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <span dangerouslySetInnerHTML={{ __html: html }} />
    </>
  );
};

export default FormItemListRelative;
