import React from "react";
import { Image } from "antd";

type Props = {
  src: { sm: string; lg: string };
  collapsed: boolean;
};

const RenderLogo = (props: Props) => {
  return (
    <div className="logo ">
      <Image
        src={props.collapsed ? props.src.sm : props.src.lg}
        preview={false}
        alt="logo"
      />
    </div>
  );
};

export default RenderLogo;
