import { rowClassName } from "@Utils/utils";
import { Row, Col, Card, Checkbox, Typography, InputNumber, Select, Table, Divider, Upload, Button } from "antd";
import ImageUpload from "@Components/ImageUpload";
import { UploadOutlined } from "@ant-design/icons";
import PagePaper from "@Components/PagePaper";
import { useAuthContextState } from "@AuthContext/store";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { VisitProgram, invoiceDetail } from "@Interface/invoice";
import { getReceiptbyAPI } from "@Services/api/receipt";
import { Receipt } from "@Interface/receipt";
import { getInvoiceByIdAPI } from "@Services/api/invoice";
type Props = {

    onChange: (value: Receipt) => void
}
const { Title } = Typography;




const FormReceipt = (props: Props) => {
    const [receipt, setReceipt] = useState<Receipt>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [invoice, setInvoice] = useState<invoiceDetail>();
    const [discountType, setDiscountType] = useState<"BATH" | "PERCENT">("BATH");
    const [discount, setDiscount] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const params = useParams() as { id: string };
    const locate = useLocation()


    useEffect(() => {
        getInvoiceAPI(String(locate.state))
        return () => { };
    }, []
    );

    const { token } = useAuthContextState();

    const getInvoiceAPI = async (id: string) => {
        try {

            setIsLoading(true);
            const res =
                await getInvoiceByIdAPI(
                    Number(id),
                    token.accessToken
                );
            setInvoice(res.result?.[0])
            setDiscount(res.result?.[0].discount)
            // props.onChange({
            //     total: invoice?.total || "0 ",
            //     net: invoice?.net || "0",
            //     discount: invoice?.discount || "0",
            //     paymentType: "QR_CODE" || "0",
            //     discountType: invoice?.discountType || "BATH",
            //     invoiceId: res.result?.[0].id || 0,
            //     slipId: uploadImage || 0,
            // })

        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
            return false;
        }
    }

    const columns = [
        {
            title: "#",
            key: "id",
            width: 50,
            align: "center" as const,
            render: (text: string, record: VisitProgram, rowIndex: number) => (
                <span>{rowIndex + 1}</span>
            ),
        },
        {
            title: "ชื่อโปรแกรม",
            dataIndex: "idCard",
            key: "idCard",
            render: (text: string, record: VisitProgram, rowIndex: number) => {
                return (
                    <span>{record.program.name}</span>
                )
            },
        },
        {
            title: "จำนวน",
            dataIndex: "hn",
            key: "hn",
            render: (text: string, record: VisitProgram, rowIndex: number) => {

                return (
                    <span>{record.qty}</span>
                )
            },
        },
        {
            title: "ราคาต่อหน่วย",
            dataIndex: "createAt",
            key: "createAt",
            render: (text: string, record: VisitProgram, rowIndex: number) => {
                return (
                    <span>{record.program.price}</span>
                )
            },
        },
    ];

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={16}>
                    <Card title="ใบแจ้งหนี้">
                        <Row>
                            {/* <Col>
                                <span>โปรแกรมที่เลือก</span>
                            </Col> */}
                            <Col span={24}>
                                <Table
                                    size="small"
                                    rowKey="id"
                                    dataSource={invoice?.visit?.visitProgram || []}
                                    columns={columns}
                                    className="abus-table"
                                    pagination={false}
                                    rowClassName={rowClassName}

                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="รายการชำระ" style={{ width: 300 }}>
                        <Row className="mt-05">
                            <Col span={24}>
                                <span>ยอดรวมทั้งหมด: {invoice?.total} บาท</span>
                            </Col>

                            <Col span={24} >
                                <span>ส่วนลด:</span>
                                <span style={{ width: 60 }}> {Number(invoice?.discount) || 0} บาท</span>
                            </Col>
                            <Col span={24}>
                                <span>ยอดคงค้าง: {Number(invoice?.total || "0") - discount}  บาท </span>
                            </Col>
                            <Col span={24}>
                                <span>ยอดเงินที่ต้องชำระทั้งหมด: {Number(invoice?.total || "0")} บาท </span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div >
    );
};
export default FormReceipt



