import { CloseOutlined } from "@ant-design/icons";
import { Col, Tooltip } from "antd";
import { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

import ImageMarker, { Marker, MarkerComponentProps } from "react-image-marker";

import "./styles/index.less";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  src?: string;
  value?: Marker[] | [];
  onChange?: (e: any) => void;
}

interface CustomMarkerProps extends React.HTMLAttributes<HTMLDivElement> {
  markers: Marker[] | [];
  value: MarkerComponentProps;
  onChange?: (e: any) => void;
}
const CustomMarker = (props: CustomMarkerProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const toggleHover = () => {
    setIsHovered(!isHovered);
  };
  const onRemove = () => {
    const newData = props.markers.filter(
      (marker) =>
        marker.top !== props.value.top || marker.left !== props.value.left
    );
    props.onChange && props.onChange(newData);
  };
  return (
    <Tooltip
      title={
        <span>
          <CloseOutlined />
          <span style={{ fontSize: "0.9em" }}>
            Delete Marker {Number(props.value.itemNumber) + 1}
          </span>
        </span>
      }
      placement="bottom"
    >
      <div
        className="hotspot "
        onClick={onRemove}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        <div className="button-wrap">
          <button className="pulse-button">
            {isHovered ? (
              <span style={{ fontSize: "1rem" }}>
                <RiDeleteBin6Line />
              </span>
            ) : (
              Number(props.value.itemNumber) + 1
            )}
          </button>
        </div>
      </div>
    </Tooltip>
  );
};

const MarkerImage = (props: Props) => {
  const getMarkers = (newMarkers: Marker[]) => {
    props.onChange && props.onChange(newMarkers);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          justifyContent: "space-between",
          paddingTop: "10px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <Col span={12} style={{ textAlign: "center" }}>
          R
        </Col>
        <Col span={12} style={{ textAlign: "center" }}>
          L
        </Col>
      </div>
      <ImageMarker
        src={props.src || ""}
        markers={props.value || []}
        markerComponent={(data) => (
          <CustomMarker
            value={data}
            markers={props?.value || []}
            onChange={props.onChange}
          />
        )}
        onAddMarker={(marker: Marker) =>
          getMarkers([...(props?.value || []), marker])
        }
      />
    </div>
  );
};

export default MarkerImage;
