import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, EyeOutlined, SyncOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Tag } from 'antd';
import React from 'react'

interface ICustomCard {
  title: string;
  status?: IStatus[]
  subTitle?: React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
  onDownload?: VoidFunction;
  onViewDICOM?: VoidFunction;
  extraAction?: React.ReactNode[];
}
export interface IStatus { status: string; name: string }
const CustomCard = ({ children, title, subTitle, status, onDownload, onViewDICOM, extraAction }: ICustomCard) => {
  const _renderTagStatus = (item: IStatus) => {
    switch (item.status) {
      case "success": return <Tag icon={<CheckCircleOutlined />} color="#87d068"> {item.name}</Tag>
      case "processing": return <Tag icon={<SyncOutlined spin />} color="#108ee9"> {item.name}</Tag>
      case "error": return <Tag icon={<CloseCircleOutlined />} color="#f50"> {item.name}</Tag>
      case "warning": return <Tag icon={<ExclamationCircleOutlined />} color="warning"> {item.name}</Tag>
      default:
        break;
    }
  }

  return (
    <div className="card custom-card">
      <div className="card-header">
        <Row justify="space-between" align="middle" >
          <Col>
            <Row gutter={[10, 10]}>
              <Col>{title}</Col>
              {
                subTitle?.map((item, ind) => (
                  <Col key={ind} style={{ borderLeft: ind === 0 ? "1px solid #e7e7e7" : "none" }}> {item}</Col>
                ))
              }
              {
                status?.map((item, ind) => (
                  <Col key={ind} style={{ borderLeft: ind === 0 ? "1px solid #e7e7e7" : "none" }}>
                    {_renderTagStatus(item)}
                  </Col>
                ))}
            </Row>
          </Col>
          <Col>
            <Space>
              {onDownload && <Button icon={<VerticalAlignBottomOutlined />} size="middle" onClick={onDownload} />}
              {onViewDICOM && <Button icon={<EyeOutlined />} size="middle" onClick={onViewDICOM}>DICOM VIEWER</Button>}
              {extraAction && extraAction?.map((action, ind) => (
                <React.Fragment key={ind} >{action}</React.Fragment>
              ))}
            </Space>
          </Col>
        </Row>
      </div>
      <div className="card-body">
        {children}
      </div>
    </div>
  )
}
export default CustomCard