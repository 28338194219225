import { Params } from "@Interface/service";
import { Fetch } from "@Utils/fetch/fetch";
export const GetDiagnosticsAllAPI = async <T = any>(
  params: Params,
  token: string
) => {
  const res = await Fetch<T>({
    params,
    token: token,
    method: "GET",
    path: `/v1/visit/waiting`,
  });
  return res;
};

export const DeleteDiagnosticsByIdAPI = async <T = any>(
  patientId: number,
  token: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "PUT",
    path: `/v1/visit/${patientId}/delete-waiting`,
  });
  return res;
};

export const getDiagnosisFormAPI = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    token: token,
    method: "GET",
    path: `/v1/diagnosis-form`,
  });
  return res;
};

export const saveDiagnosisFormAPI = async <T = any>(
  data: Object,
  visitId: string,
  token: string
) => {
  const res = await Fetch<T>({
    data: data,
    token: token,
    method: "PUT",
    path: `/v1/visit/${visitId}/save-diagnose-form`,
  });
  return res;
};

export const saveVisitDoneAPI = async <T = any>(
  data: Object,
  visitId: string,
  token: string
) => {
  const res = await Fetch<T>({
    data: data,
    token: token,
    method: "PUT",
    path: `/v1/visit/${visitId}/done`,
  });
  return res;
};

export const _DiagnosticsService = {
  GetDiagnosticsAllAPI,
  DeleteDiagnosticsByIdAPI,
  getDiagnosisFormAPI,
  saveDiagnosisFormAPI,
  saveVisitDoneAPI,
};
export default _DiagnosticsService;
