import { useAuthContextDispatch, useAuthContextState } from '@AuthContext/store';
import PageHeader from '@Components/PageHeader';
import PagePaper from '@Components/PagePaper';
import PatientStatus from '@Components/form/PatientStatus';
import { Receipt } from '@Interface/receipt';
import { RESGetVisitInfoService, VisitField } from '@Interface/visit';
import _VisitService from '@Services/api/visit';
import { _RES_validateError } from '@Utils/validate';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Row, Skeleton } from 'antd';
import { useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import FormReceipt from '../FormReceipt';

type Props = {};

const ReceiptProgram = (props: Props) => {
  const params = useParams() as { id: string };
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [form] = Form.useForm();
  const [visitInfo, setVisitInfo] = useState<VisitField | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imgProfile, setImageProfile] = useState<any>();
  useState<boolean>(false);
  const navigate = useNavigate();
  const [receiptInfo, setReceptInfo] = useState<Receipt>();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [id, setId] = useState<number>();

  useLayoutEffect(() => {
    !!params.id && getVisitInfo(params.id);
    return () => {};
  }, [params]);

  const getVisitInfo = async (visitId: string) => {
    try {
      const keyOnLoad = 'getVisitInfo';
      setIsLoading(true);
      const res = await _VisitService.GetVisitInfoByIdAPI<RESGetVisitInfoService>(visitId, token.accessToken);

      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setVisitInfo(res.result?.[0]);
            setImageProfile(res.result?.[0]?.patient?.imageUrl);
            setId(res.result?.[0]?.patient?.id);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
      return false;
    }
  };

  const onFinish = async (visitId: string) => {
    try {
      setIsLoading(true);
      const res = await _VisitService.SendVisitToUpload(visitId, token.accessToken);
      navigate('/program/view-list');
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  };

  return (
    <>
      <div className="">
        <PageHeader
          title={'เลือกโปรแกรม'}
          subTitle="รายการโปรแกรมรักษา"
          action={
            <Row gutter={[16, 16]} justify="end">
              <Col>
                <Button
                  type="default"
                  size="middle"
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                >
                  กลับ
                </Button>
              </Col>
              {/* <Col >
                                <Button
                                    type="default"
                                    size="middle"
                                    onClick={() => {
                                    }}
                                    style={{ paddingLeft: 20, paddingRight: 20 }}
                                >
                                    พิมพ์ใบเสร็จ
                                </Button>
                            </Col> */}
              <Col>
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => onFinish(params.id)}
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                >
                  ส่งอัปโหลดไฟล์
                </Button>
              </Col>
            </Row>
          }
        />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <PagePaper>
              <Skeleton active loading={isLoading}>
                <Form form={form} size="large" layout="vertical" className="FormUpload">
                  <PatientStatus visitInfo={visitInfo} imgProfile={imgProfile} id={id} />
                  <Divider />
                </Form>
              </Skeleton>
            </PagePaper>
          </Col>
        </Row>
        <PagePaper>
          <Row>
            <Col span={24}>
              <FormReceipt
                onChange={(value: Receipt) => {
                  setReceptInfo(value);
                }}
              />
            </Col>
          </Row>
        </PagePaper>
      </div>
    </>
  );
};
export default ReceiptProgram;
