import { Form, Input } from "antd";
import { _CustomItemQuestionItem } from "../../interface";

const { TextArea } = Input;

export interface Props {
  values: _CustomItemQuestionItem;
  disabled?: boolean;
  isFormList?: string | number;
}

const TextAreaComponent = ({ values, disabled, isFormList }: Props) => {
  const html = values["description"] || ``;
  const propsTextArea = values.propsTextArea
    ? values.propsTextArea
    : { rows: 1, showCount: true, placeholder: "" };
  return (
    <div>
      <Form.Item
        name={isFormList ? [isFormList, values["keyName"]] : values["keyName"]}
        label={values["title"] || "ใส่ข้อมูลตัวเลข"}
        rules={[
          {
            required: !!values.required,
            message: `กรุณาใส่ข้อมูล ${values["title"]}`,
          },
        ]}
      >
        <TextArea
          disabled={disabled}
          placeholder={propsTextArea.placeholder}
          rows={propsTextArea.rows}
          showCount={propsTextArea.showCount}
        />
      </Form.Item>
      <span dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default TextAreaComponent;

// ex.
// {
//   title: "ประวัติ",
//   keyName: "question12",
//   type: "textArea",
//   propsTextAreaProps: { rows: 1 ,showCount: true ,placeholder: "ประวัติการรักษา",},
// },
