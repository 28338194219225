import { VisitField, VisitFiles } from '@Interface/visit';
import { FormInstance } from 'antd';
import React from 'react';
import CreateLayoutFormItem from './CreateLayoutFormItem';
import { _CustomItemQuestionItem } from './interface';

interface Props {
  form: FormInstance<any>;
  values: _CustomItemQuestionItem[];
  disabled?: boolean;
  keyLoop?: any;
  cusname?: any;
  isFormList?: string | number;
  openModal?: () => void;
  visitInfo?: VisitField | null;
  fileList?: VisitFiles[];
}

const _RenderFormItem = (props: Props) => {
  return (
    <React.Fragment>
      {props.values.map((item, ind) => (
        <CreateLayoutFormItem
          values={item}
          key={ind}
          form={props.form}
          disabled={props.disabled}
          keyLoop={props.keyLoop}
          cusname={props.cusname}
          isFormList={props.isFormList}
          openModal={props.openModal}
          visitInfo={props.visitInfo}
          fileList={props?.fileList}
        />
      ))}
    </React.Fragment>
  );
};

export default _RenderFormItem;
