import { Icons } from "@Assets/images/icons";

export const routerMenu = [
  {
    keyName: "dashboard",
    title: { th: "ภาพรวม", en: "Dashboard" },
    icon: {
      src: "/images/icons/dashboard.svg",
      components: Icons["dashboard"],
    },
    path: "/dashboard",
  },
  {
    keyName: "patient",
    title: { th: "ผู้ป่วย", en: "Patient" },
    icon: {
      src: "/images/icons/patient.svg",
      components: Icons["patient"],
    },
    path: "/patient",
    children: [],
  },
  {
    keyName: "program",
    title: { th: "โปรแกรม", en: "program" },
    icon: {
      src: "/images/icons/report.svg",
      components: Icons["report"]
    },
    path: "/program"
  },
  {
    keyName: "upload_data",
    title: { th: "อัพโหลดไฟล์", en: "Upload" },
    icon: { src: "/images/icons/upload.svg", components: Icons["upload"] },
    path: "/upload",
  },
  {
    keyName: "awaiting_diagnose",
    title: { th: "รอวินิจฉัย", en: "Diagnostics" },
    icon: {
      src: "/images/icons/diagnostics.svg",
      components: Icons["diagnostics"],
    },
    path: "/diagnostics",
  },
  {
    keyName: "diagnose",
    title: { th: "ห้องแพทย์", en: "Doctor" },
    icon: { src: "/images/icons/doctor.svg", components: Icons["doctor"] },
    path: "/doctor",
  },
  {
    keyName: "user_management",
    title: { th: "จัดการผู้ใช้", en: "User management" },
    icon: {
      src: "/images/icons/userManagement.svg",
      components: Icons["userManagement"],
    },
    path: "/user-management",
  },
  {
    keyName: "report",
    title: { th: "รายงาน", en: "Report" },
    icon: { src: "/images/icons/report.svg", components: Icons["report"] },
    path: "/report",
  }
];
