/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Table,
  Tabs,
} from "antd";
import PageHeader from "@Components/PageHeader";
import FormSearch from "@Components/form/FormSearch";
import { useEffect, useState } from "react";
import { PatientItem } from "@Interface/patient";
import dayjs from "dayjs";
import { EyeOutlined } from "@ant-design/icons";
import { ImDownload3 } from "react-icons/im";
import { MdDelete } from "react-icons/md";
import { PaginationExcludeTotal } from "@Interface/service";
import { _RES_validateError } from "@Utils/validate";
import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import { rowClassName, _cleanObject } from "@Utils/utils";
import _VisitService from "@Services/api/visit";
import "../styles/index.less";
import { useNavigate } from "react-router-dom";
import useZipDownload2 from "@Views/Diagnostics/FormDiagnostics/fileDownload";

type Props = {};

interface SearchFailed {
  dateRange?: Date[];
  doctorname?: string;
}

const ListDoctor = (props: Props) => {
  // const [doctor, setDoctorList] = useState<PatientItem[] | []>([]);
  const { TabPane } = Tabs;
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [totalDiagnosticsList, setTotalDiagnosticsList] = useState<number>(0);

  const [askOpinionData, setAskOpinionData] = useState<PatientItem[] | []>([]);
  const [doctor, setDoctor] = useState<PatientItem[] | []>([]);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationExcludeTotal>({
    page: 1,
    perpage: 10,
    startIndex: 1,
  });
  const [downloadAndZip] = useZipDownload2();

  useEffect(() => {
    getDiagnosticsList();
    getAskOpinionList();

    return () => {};
  }, [pagination]);

  const getDiagnosticsList = async (search?: SearchFailed) => {
    try {
      setIsLoading(true);
      const keyOnLoad = "getVisitRoomList";
      const params = {
        page: pagination.page,
        limit: pagination.perpage,
        ...search,
      };
      const res = await _VisitService.GetVisitRoomAPI(
        params,
        token["accessToken"]
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setDoctor(res.result[0]["data"]);
            setTotalDiagnosticsList(res.result[0]["total"]);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const getAskOpinionList = async (search?: SearchFailed) => {
    try {
      setIsLoading(true);
      const keyOnLoad = "getAskOpinionList";
      const params = {
        page: pagination.page,
        limit: pagination.perpage,
        ...search,
      };
      const res = await _VisitService.GetVisitAskOpinionAPI(
        params,
        token["accessToken"]
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setAskOpinionData(res.result[0]["data"]);
            setTotalDiagnosticsList(res.result[0]["total"]);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    getDiagnosticsList();
  };
  const onFinish = (value: SearchFailed) => {
    getDiagnosticsList(_cleanObject.obj(value));
  };
  const removeVisitById = async (visitId: number) => {
    try {
      const keyOnLoad = "removeVisitById";
      setIsLoadingConfirm(true);
      const res = await _VisitService.DeleteReserveAPI(
        visitId.toString(),
        token.accessToken
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "ลบข้อมูลรายชื่อผู้ป่วยสำเร็จ",
          runOption: () => {
            setIsLoading(false);
            setIsLoadingConfirm(false);
            setDoctor((pervDiagnostics) =>
              pervDiagnostics.filter((p) => p.id !== visitId)
            );
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setIsLoadingConfirm(false);
    }
  };
  const viewDiagnosticsByVisitId = (id: number) => {
    navigate("/doctor/view-info/" + id);
  };
  const viewAskOpinionByVisitId = (id: number) => {
    navigate("/doctor/view-askopinion/" + id);
  };

  // const onClickDowload = async (id: number) => {
  //   const res = await _VisitService.visitDownloadFiles(id, token.accessToken);
  //   const data = res.result;
  //   data.map((data: any) => {
  //     window.open(data.url);
  //   });
  // };
  const onClickDowload = async (visitId: number) => {
    const id = Number(visitId);
    const res = await _VisitService.visitDownloadFiles(id, token.accessToken);
    const data = res.result;
    downloadAndZip(data);
  };
  const columns1 = [
    {
      title: "#",
      key: "id",
      width: 50,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{rowIndex + 1}</span>
      ),
    },
    {
      title: "เลขบัตรประชาชน",
      dataIndex: "idCard",
      key: "idCard",
      // width: 200,
    },
    {
      title: "เลข HN",
      dataIndex: "hn",
      key: "hn",
      // width: 200,
    },
    {
      title: "วันที่",
      dataIndex: "createAt",
      key: "createAt",
      // width: 150,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{dayjs(text).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "fullName",
      key: "fullName",
      // width: 300,
    },
    {
      title: "ที่ตั้งของรถตรวจ",
      dataIndex: "location",
      key: "location",
      width: 220,
      render: (data: any, record: PatientItem, rowIndex: number) => (
        <span className="data-webkitline"> {data}</span>
      ),
    },
    {
      title: "รายชื่อหมอ",
      dataIndex: "doctor",
      key: "doctor",
      // width: 300,
      render: (data: any, record: PatientItem, rowIndex: number) => (
        <span className="Doctor-data-import"> {data}</span>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      // width: 180,
      render: (data: any, record: PatientItem, rowIndex: number) => (
        <div className="Doctor-status-content">
          {data === "RESERVED" && "Reserved" ? (
            <>
              <span className="Doctor-status-point01"></span>
              <span className="Doctor-status-data01">
                {data.replace("_", " ")}
              </span>
            </>
          ) : (
            <>
              <span className="Doctor-status-point02"></span>
              <span className="Doctor-status-data02">
                {data.replace("_", " ")}
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      title: "จัดการ",
      key: "action",
      width: 250,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <Space>
          <Button
            type="ghost"
            className="flex-center btn-action"
            onClick={() => viewDiagnosticsByVisitId(record.id)}
          >
            <EyeOutlined />
          </Button>
          <span> </span>
          <Button
            type="link"
            className="flex-center btn-action"
            onClick={() => onClickDowload(record.id)}
            style={{
              color: "#0980FD",
              background: "#fff",
              border: "1px solid #0980FD",
              width: 42,
              height: 42,
              borderRadius: "50%",
            }}
          >
            <ImDownload3 />
          </Button>
          <span> </span>
          <Popconfirm
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            title="ยืนยันการลบข้อมูลผู้ป่วย？"
            onConfirm={() => removeVisitById(record.id)}
            okButtonProps={{ loading: isLoadingConfirm }}
          >
            <Button type="ghost" className="flex-center btn-action">
              <MdDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const columns = [
    {
      title: "#",
      key: "id",
      width: 50,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{rowIndex + 1}</span>
      ),
    },
    {
      title: "เลขบัตรประชาชน",
      dataIndex: "idCard",
      key: "idCard",
      // width: 200,
    },
    {
      title: "เลข HN",
      dataIndex: "hn",
      key: "hn",
      // width: 200,
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "createAt",
      key: "createAt",
      // width: 150,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{dayjs(text).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "fullName",
      key: "fullName",
      // width: 300,
    },
    {
      title: "ที่ตั้งของรถตรวจ",
      dataIndex: "location",
      key: "location",
      width: 220,
      render: (data: any, record: PatientItem, rowIndex: number) => (
        <span className="data-webkitline"> {data}</span>
      ),
    },
    {
      title: "รายชื่อหมอ",
      dataIndex: "doctor",
      key: "doctor",
      render: (data: any, record: PatientItem, rowIndex: number) => (
        <span className="Doctor-data-import"> {data}</span>
      ),
    },
    {
      title: "จัดการ",
      key: "action",
      width: 250,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <Space>
          <Button
            type="ghost"
            className="flex-center btn-action"
            onClick={() => viewAskOpinionByVisitId(record.id)}
          >
            <EyeOutlined />
          </Button>
          <Button
            type="link"
            className="flex-center btn-action"
            onClick={() => onClickDowload(record.id)}
            style={{
              color: "#0980FD",
              background: "#fff",
              border: "1px solid #0980FD",
              width: 42,
              height: 42,
              borderRadius: "50%",
            }}
          >
            <ImDownload3 />
          </Button>
          {/* <span> </span>
          <Popconfirm
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            title="ยืนยันการลบข้อมูลผู้ป่วย？"
            onConfirm={() => removeVisitById(record.id)}
            okButtonProps={{ loading: isLoadingConfirm }}
          >
            <Button type="ghost" className="flex-center btn-action">
              <MdDelete />
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <PageHeader
          title="ข้อมูลวินิจฉัย"
          subTitle="ข้อมูลวินิจฉัยทั้งหมด"
          // action={
          //   <Row justify="end">
          //     <Col>
          //       {tab === "1" ? (
          //         <Button
          //           type="primary"
          //           size="large"
          //           onClick={() => ""}
          //           style={{ paddingLeft: 30, paddingRight: 30 }}
          //         >
          //           รายงาน
          //         </Button>
          //       ) : (
          //         <></>
          //       )}
          //     </Col>
          //   </Row>
          // }
        />
      </Col>

      <Col span={24} className="content">
        <Tabs
          type="card"
          onChange={(key) => console.log(key)}
          className="tabDoctor"
        >
          <TabPane
            tab={
              <Row style={{ padding: "0px 20px" }}>
                <span className="font-tabs">วินิจฉัยที่เลือก</span>
              </Row>
            }
            key="1"
            style={{ marginTop: "-16px" }}
            className="tabPaneDoctor"
          >
            <FormSearch
              onFinish={onFinish}
              onCancel={onCancel}
              type="default"
            />
            <Table
              size="small"
              rowKey="id"
              loading={isLoading}
              columns={columns1}
              className="abus-table"
              dataSource={doctor}
              pagination={false}
              rowClassName={rowClassName}
              scroll={{ x: 1500 }}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: (event) => {
                    viewDiagnosticsByVisitId(record.id);
                  },
                };
              }}
            />
          </TabPane>

          <TabPane
            tab={
              <Row gutter={[16, 16]} style={{ padding: "0px 20px" }}>
                <Col className="font-tabs">ร้องขอความเห็นเพิ่มเติม </Col>
                <Col
                  className="font-lenght"
                  style={{
                    borderColor: "red",
                    borderRadius: "100%",
                    // backgroundColor: "red",
                    width: "25px",
                    height: "25px",
                    // color: "white",
                    textAlign: "center",
                  }}
                >
                  {!!askOpinionData && askOpinionData.length}
                </Col>
              </Row>
            }
            key="2"
            style={{ marginTop: "-16px" }}
          >
            <FormSearch
              onFinish={onFinish}
              onCancel={onCancel}
              type="default"
            />
            <Table
              size="small"
              rowKey="id"
              loading={isLoading}
              columns={columns}
              className="abus-table"
              dataSource={askOpinionData}
              pagination={false}
              rowClassName={rowClassName}
              scroll={{ x: 1200 }}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: (event) => {
                    viewAskOpinionByVisitId(record.id);
                  },
                };
              }}
            />
          </TabPane>
        </Tabs>
        <Row justify="end" style={{ marginTop: 20 }}>
          <Col>
            {totalDiagnosticsList / pagination.perpage > 1 && (
              <Pagination
                className="abus-pagination"
                total={totalDiagnosticsList}
                onChange={(page: number, pageSize: number) => {
                  setPagination({
                    ...pagination,
                    page: page,
                    perpage: pageSize,
                    startIndex: page * pageSize,
                  });
                }}
                defaultCurrent={pagination.page}
                defaultPageSize={pagination.perpage}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ListDoctor;
