import { Params } from "@Interface/service";
import { REQ_VisitField, TypeFile } from "@Interface/visit";
import { Fetch, FetchFormData } from "@Utils/fetch/fetch";
import axios from "@Utils/fetch/http-common";
import { AxiosRequestConfig } from "axios";

export const CreateVisitByPatientAPI = async <T = any>(
  data: REQ_VisitField,
  token: string
) => {
  const res = await Fetch<T>({
    data,
    token: token,
    method: "POST",
    path: `/v1/visit`,
  });
  return res;
};
export const GetVisitPendingAPI = async <T = any>(
  params: Params,
  token: string
) => {
  const res = await Fetch<T>({
    params,
    token: token,
    method: "GET",
    path: `/v1/visit/pending`,
  });
  return res;
};
export const GetVisitSelectProgramAPI = async <T = any>(
  params: Params,
  token: string
) => {
  const res = await Fetch<T>({
    params,
    token: token,
    method: "GET",
    path: `/v1/visit/program`,
  });
  return res;
};
export const GetVisitInfoByIdAPI = async <T = any>(
  visitId: string,
  token: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "GET",
    path: `/v1/visit/${visitId}`,
  });
  return res;
};
export const DeleteVisitByIdAPI = async <T = any>(
  visitId: number,
  token: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "DELETE",
    path: `/v1/visit/${visitId}`,
  });
  return res;
};

export const DeleteVisitFileAPI = async <T = any>(
  idFile: number,
  token: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "DELETE",
    path: `/v1/visit/${idFile}/delete`,
  });
  return res;
};

export const SendMailAPI = async <T = any>(visitId: string, token: string) => {
  const res = await Fetch<T>({
    token: token,
    method: "GET",
    path: `/v1/visit/${visitId}/send-email`,
  });
  return res;
};
export const AddWaitingDiagnosisAIP = async <T = any>(
  visitId: string,
  token: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "PUT",
    path: `/v1/visit/${visitId}/waiting`,
  });
  return res;
};
export const AddDetectionsAPI = async <T = any>(
  visitId: string,
  token: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "PUT",
    path: `/v1/visit/detection/${visitId}`,
  });
  return res;
};

export const UploadAPI = async (
  formData: FormData,
  token: string,
  optional?: AxiosRequestConfig<FormData>
) => {
  const optionals = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    ...optional,
  };
  return axios.put(`/v1/visit/uploads`, formData, optionals);
};

export const UploadsFile = async (token: string, formData: FormData) => {
  const optionals = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await axios.put(`/v1/visit/uploads`, formData, optionals);
  return res.data.result[0];
};

export const GetVisitNoteEmail = async <T = any>(
  visitId: string,
  token?: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "GET",
    path: `/v1/visit/${visitId}/note-email`,
  });
  return res;
};
export const GetVisitRoomAPI = async <T = any>(
  params: Params,
  token: string
) => {
  const res = await Fetch<T>({
    params,
    token: token,
    method: "GET",
    path: `/v1/visit/room`,
  });
  return res;
};

export const GetVisitAskOpinionAPI = async <T = any>(
  params: Params,
  token: string
) => {
  const res = await Fetch<T>({
    params,
    token: token,
    method: "GET",
    path: `/v1/visit/ask-opinion`,
  });
  return res;
};

export const DeleteReserveAPI = async <T = any>(
  visitId: string,
  token: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "PUT",
    path: `/v1/visit/${visitId}/delete-reserve`,
  });
  return res;
};

export const SendVisitToReserve = async <T = any>(
  visitId: string,
  token?: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "PUT",
    path: `/v1/visit/${visitId}/reserve`,
  });
  return res;
};
export const SendVisitToUpload = async <T = any>(
  visitId: string,
  token?: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "PUT",
    path: `/v1/visit/${visitId}/pending`,
  });
  return res;
};

export const visitDownloadFiles = async <T = any>(
  visitId: number,
  token?: string,
  type?: TypeFile
) => {
  const res = await Fetch<T>({
    token: token,
    method: "GET",
    path: `/v1/visit/${visitId}/download-files/${type}`,
  });
  return res;
};

export const visitAskOpinion = async <T = any>(
  visitId: string,
  token?: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "PUT",
    path: `/v1/visit/${visitId}/ask-opinion`,
  });
  return res;
};
export const visitResultDetection = async <T = any>(
  visitId: string,
  token?: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "GET",
    path: `/v1/visit/result-detection/${visitId}`,
  });
  return res;
};

export const visitFormDoctorDone = async <T = any>(
  data: FormData,
  token: string,
  visitId: string
) => {
  const res = await FetchFormData<T>(
    {
      token: token,
      method: "PUT",
      path: `/v1/visit/${visitId}/done`,
    },
    data
  );
  return res;
};

export const visitDetectionAI = async <T = any>(
  token: string,
  visitId: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "GET",
    path: `/v1/visit/result-detection/${visitId}`,
  });
  return res;
};

export const visitBackToReserve = async <T = any>(
  visitId: string,
  token?: string
) => {
  const res = await Fetch<T>({
    token: token,
    method: "PUT",
    path: `/v1/visit/${visitId}/back-to-reserve`,
  });
  return res;
};

export const _VisitService = {
  UploadAPI,
  UploadsFile,
  GetVisitPendingAPI,
  DeleteVisitByIdAPI,
  GetVisitInfoByIdAPI,
  AddWaitingDiagnosisAIP,
  CreateVisitByPatientAPI,
  GetVisitNoteEmail,
  SendMailAPI,
  SendVisitToReserve,
  GetVisitRoomAPI,
  DeleteReserveAPI,
  visitDownloadFiles,
  visitAskOpinion,
  visitFormDoctorDone,
  GetVisitAskOpinionAPI,
  visitDetectionAI,
  visitBackToReserve,
  AddDetectionsAPI,
  SendVisitToUpload,
  DeleteVisitFileAPI,
};

export default _VisitService;
