/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { rowClassName, _cleanObject } from "@Utils/utils";
import { DeletePatientByIdAPI, GetPatientListApi } from "@Services/api/patient";
import { useAuthContextState } from "@AuthContext/store";
import { useAuthContextDispatch } from "@AuthContext/store";
import { PaginationExcludeTotal } from "@Interface/service";
import { PlusOutlined, EyeOutlined } from "@ant-design/icons";
import { PatientItem } from "@Interface/patient";

import { _RES_validateError } from "@Utils/validate";

import { Popconfirm, Space } from "antd";
import { Button, Col, Pagination, Row, Table } from "antd";

import PageHeader from "@Components/PageHeader";
import FormSearch, { SearchFailed } from "@Components/form/FormSearch";

import "../styles/index.less";

type Props = {};

const Patient = (props: Props) => {
  const navigate = useNavigate();
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [totalPatientList, setTotalPatientList] = useState<number>(0); //200
  const [patientList, setPatientList] = useState<PatientItem[] | []>([]);
  const [pagination, setPagination] = useState<PaginationExcludeTotal>({
    page: 1,
    perpage: 10,
    startIndex: 1,
  });
  useEffect(() => {
    getPatientList();

    return () => { };
  }, [pagination]);

  const getPatientList = async (search?: SearchFailed) => {
    try {
      setIsLoading(true);
      const keyOnLoad = "getPatientList";
      const dataSearch = {
        page: pagination.page,
        limit: pagination.perpage,
        ...search,
      };
      const res = await GetPatientListApi(dataSearch, token["accessToken"]);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setPatientList(res.result[0]["data"]);
            setTotalPatientList(res.result[0]["total"]);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const onCancel = () => {
    getPatientList();
  };
  const onFinish = (value: SearchFailed) => {
    getPatientList(_cleanObject.obj(value));
  };

  const removePatientById = async (patientId: number) => {
    try {
      const keyOnLoad = "removePatientById";
      setIsLoadingConfirm(true);
      const res = await DeletePatientByIdAPI(patientId, token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "ลบข้อมูลรายชื่อผู้ป่วยสำเร็จ",
          runOption: () => {
            setIsLoading(false);
            setIsLoadingConfirm(false);
            setPatientList((pervPatientList) =>
              pervPatientList.filter((p) => p.id !== patientId)
            );
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setIsLoadingConfirm(false);
    }
  };
  const viewPatientById = (id: number) => {
    navigate("/patient/view-edit/" + id);
  };

  const columns = [
    {
      title: "#",
      key: "id",
      width: 50,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span key={rowIndex}>{rowIndex + (pagination.startIndex || 0)}</span>
      ),
    },
    {
      title: "เลขบัตรประชาชน",
      dataIndex: "idCard",
      key: "idCard",
      // width: 150,
    },
    {
      title: "เลข HN",
      dataIndex: "hn",
      key: "hn",
      // width: 150,
    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "fullName",
      key: "fullName",
      // width: 150,
    },
    {
      title: "อายุ",
      dataIndex: "age",
      key: "age",
      // width: 150,
    },
    {
      title: "จัดการ",
      key: "action",
      width: 250,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <Space>
          <Button
            type="ghost"
            className="flex-center btn-action"
            onClick={() => viewPatientById(record.id)}
          >
            <EyeOutlined />
          </Button>
          <Popconfirm
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            title="ยืนยันการลบข้อมูลผู้ป่วย？"
            // placement="bottomRight"
            onConfirm={() => removePatientById(record.id)}
            okButtonProps={{ loading: isLoadingConfirm }}
          >
            <Button type="ghost" className="flex-center btn-action">
              <MdDelete />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <PageHeader
          title="ผู้ป่วย"
          subTitle="ผู้ป่วยทั้งหมด"
          action={
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => navigate("/patient/create")}
                  style={{ paddingLeft: 30, paddingRight: 30 }}
                >
                  <PlusOutlined /> เพิ่มผู้ป่วย
                </Button>
              </Col>
            </Row>
          }
        />
      </Col>
      <Col span={24} className="content">
        <FormSearch onFinish={onFinish} onCancel={onCancel} type={"default"} />
        <Table
          size="small"
          rowKey="id"
          loading={isLoading}
          columns={columns}
          className="abus-table"
          dataSource={patientList}
          pagination={false}
          rowClassName={rowClassName}
          scroll={{ x: 1300 }}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                viewPatientById(record.id);
              },
            };
          }}
        />
        <Row justify="end" style={{ marginTop: 20 }}>
          <Col>
            {totalPatientList / pagination.perpage > 1 && (
              <Pagination
                className="abus-pagination"
                total={totalPatientList}
                onChange={(page: number, pageSize: number) => {
                  setPagination({
                    ...pagination,
                    page: page,
                    perpage: pageSize,
                    startIndex: page * pageSize,
                  });
                }}
                defaultCurrent={pagination.page}
                defaultPageSize={pagination.perpage}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Patient;
