/* eslint-disable react-hooks/exhaustive-deps */
import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import { PatientItem } from "@Interface/patient";
import { PaginationExcludeTotal } from "@Interface/service";
import { GetPatientHistoryListByIDApi } from "@Services/api/patient";
import { _cleanObject, rowClassName } from "@Utils/utils";
import { _RES_validateError } from "@Utils/validate";
import { EyeOutlined } from "@ant-design/icons";
import { Button, Col, Form, Pagination, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Breadcumb from "@Components/Breadcumb";
import PageHeader from "@Components/PageHeader";
import FormHeader from "@Components/form/FormHeader";
import { SearchFailed } from "@Components/form/FormSearch";
import dayjs from "dayjs";
import DrawerCreateVisit from "../CreateVisit";
import "../styles/index.less";

type Props = {};

const History = (props: Props) => {
  const params = useParams() as { id: string };
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [totalPatientHistoryList, setTotalPatientHistoryList] =
    useState<number>(0);
  const [patientHistoryList, setPatientHistoryList] = useState<
    PatientItem[] | []
  >([]);
  const [patientInfo, setPatientInfo] = useState<PatientItem | null>(null);
  const [pagination, setPagination] = useState<PaginationExcludeTotal>({
    page: 1,
    perpage: 10,
    startIndex: 1,
  });
  const [patientImg, setPatientImg] = useState<string>("");
  const [onEdit, setOnEdit] = useState<boolean>(false);
  useEffect(() => {
    params.id && getPatientHistoryList();

    return () => {};
  }, []);

  const getPatientHistoryList = async (search?: SearchFailed) => {
    try {
      const keyOnLoad = "getPatientHistoryList";
      const data = {
        page: pagination.page,
        limit: pagination.perpage,
        ...search,
      };
      const res = await GetPatientHistoryListByIDApi(
        params.id,
        data,
        token["accessToken"]
      );
      if (res) {
        _RES_validateError(res, keyOnLoad, {
          Code_401: { runOption: _signOut },
          Code_200: {
            runOption: () => {
              console.log("RES History", res);
              setPatientInfo(res.result[0]["patientInfo"]);
              setPatientHistoryList(res.result[0]["data"]);
              setTotalPatientHistoryList(res.result[0]["total"]);
              setIsLoading(false);
              setPatientImg(res.result[0].patientInfo.imageUrl || "");
              return true;
            },
          },
        });
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const onFinish = (value: SearchFailed) => {
    const searchValue = {
      doctor: value.doctorname,
      start_date:
        !!value?.dateRange?.length && dayjs(value?.dateRange[0]).valueOf(),
      end_date:
        !!value?.dateRange?.length && dayjs(value?.dateRange[1]).valueOf(),
    };
    getPatientHistoryList(_cleanObject.obj(searchValue));
  };

  const viewHistoryById = (id: number) => {
    navigate("/doctor/view-info/" + id);
  };
  const onCloseModalCreateVisit = () => {
    setIsModalVisible(false);
  };

  const viewHistoryByVisitId = (id: number) => {
    navigate("/doctor/view-info/" + id);
  };

  const columns = [
    {
      title: "#",
      key: "id",
      width: 100,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{rowIndex + (pagination.startIndex || 0)}</span>
      ),
    },
    {
      title: "เลขบัตรประชาชน",
      dataIndex: "idCard",
      key: "idCard",
      width: 250,
    },
    {
      title: "เลข HN",
      dataIndex: "hn",
      key: "hn",
      width: 250,
    },
    {
      title: "วันที่",
      dataIndex: "createAt",
      key: "createAt",
      width: 250,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{dayjs(text).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "fullName",
      key: "fullName",
      width: 300,
    },
    {
      title: "ที่ตั้งของรถตรวจ",
      dataIndex: "location",
      key: "location",
      width: 200,
    },
    {
      title: "รายชื่อหมอ",
      dataIndex: "doctor",
      key: "doctor",
      width: 200,
    },
    {
      title: "จัดการ",
      key: "action",
      width: 250,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <Space>
          <Button
            type="ghost"
            className="flex-center btn-action"
            onClick={() => viewHistoryByVisitId(record.id)}
          >
            <EyeOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <DrawerCreateVisit
          isModalVisible={isModalVisible}
          onClose={onCloseModalCreateVisit}
          uuid={patientInfo?.uuid}
          onSuccess={onCloseModalCreateVisit}
          setIsModalVisible={setIsModalVisible}
        />
        <PageHeader
          title="ประวัติผู้ป่วย"
          breadcumb={
            <Breadcumb
              value={[
                { title: "ผู้ป่วย", path: "/patient/view-list" },
                {
                  title: "ข้อมูลผู้ป่วย",
                  path: `/patient/view-edit/${params.id}`,
                },
                { title: "ประวัติผู้ป่วย" },
              ]}
            />
          }
          action={
            <>
              {onEdit ? (
                <>
                  <Row justify="end" gutter={[16, 16]}>
                    <Col md={10}>
                      <Button
                        onClick={() => setOnEdit(false)}
                        size="large"
                        className="btn-update"
                        style={{
                          // paddingLeft: 50,
                          // paddingRight: 50,
                          width: "100%",
                        }}
                      >
                        ยกเลิก
                      </Button>
                    </Col>
                    <Col md={10}>
                      <Button
                        // onClick={() => form.submit()}
                        type="primary"
                        size="large"
                        style={{ width: "100%" }}
                        loading={isLoading}
                      >
                        บันทึก
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  {/* <Space size={"middle"}> */}
                  {/* <Row justify="end" align="middle" gutter={[16, 16]}>
                    <Col md={4}>
                      <Popconfirm
                        okText="ยืนยัน"
                        cancelText="ยกเลิก"
                        title="ยืนยันการลบข้อมูลผู้ป่วย？"
                        placement="bottom"
                        onConfirm={() => removePatientById(params.id)}
                        okButtonProps={{ loading: isLoadingConfirmLoading }}
                      >
                        <Button
                          type="ghost"
                          className="flex-center btn-action"
                          style={{ background: "#fff" }}
                        >
                          <MdDelete />
                        </Button>
                      </Popconfirm>
                    </Col>
                    <Col md={9}>
                      <Button
                        // onClick={() => onCloseEdit()}
                        size="large"
                        className="btn-update"
                        style={{
                          paddingLeft: 50,
                          paddingRight: 50,
                          width: "100%",
                        }}
                      >
                        แก้ไข
                      </Button>
                    </Col>
                    <Col md={9}>
                      <Button
                        onClick={() => setIsModalVisible(true)}
                        type="primary"
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        loading={isLoading}
                      >
                        <PlusCircleOutlined /> สร้าง Visit
                      </Button>
                    </Col>
                  </Row> */}
                  {/* </Space> */}
                </>
              )}
            </>
          }
        />
      </Col>
      <Col span={24} className="content">
        <Form form={form} onFinish={(e) => onFinish(e)}>
          <FormHeader patientInfo={patientInfo} patientImg={patientImg} />
        </Form>
        <Table
          size="small"
          rowKey="id"
          loading={isLoading}
          columns={columns}
          className="abus-table"
          dataSource={patientHistoryList}
          pagination={false}
          rowClassName={rowClassName}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                viewHistoryById(record.id);
              },
            };
          }}
        />
        <Row justify="end" style={{ marginTop: 20 }}>
          <Col>
            {totalPatientHistoryList / pagination.perpage > 1 && (
              <Pagination
                className="abus-pagination"
                total={totalPatientHistoryList}
                onChange={(page: number, pageSize: number) => {
                  setPagination({
                    ...pagination,
                    page: page,
                    perpage: pageSize,
                    startIndex: page * pageSize,
                  });

                  params.id && getPatientHistoryList();
                }}
                defaultCurrent={pagination.page}
                defaultPageSize={pagination.perpage}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default History;
