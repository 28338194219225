import { Layout } from "antd";
import { useMediaQuery } from "react-responsive";
import {
  useCollapsedContextState,
  useCollapsedContextDispatch,
} from "@Layout/plugin/SidebarCollapsed/context/store";
import RenderLogo from "./_RenderLogo";
import RenderMenu from "./_RenderMenu";

const { Sider } = Layout;

const RenderSideBar = () => {
  const { width, collapsed } = useCollapsedContextState();
  const { _setCollapsed } = useCollapsedContextDispatch();

  useMediaQuery({ maxWidth: 767 }, undefined, () => {
    _setCollapsed(false);
  });
  useMediaQuery({ minWidth: 768, maxWidth: 991 }, undefined, () => {
    _setCollapsed(true);
  });
  useMediaQuery({ minWidth: 992 }, undefined, () => {
    _setCollapsed(false);
  });
  // const isScreenSizeMobile = useMediaQuery({ maxWidth: 767 }, undefined, (matches: boolean) => { _setCollapsed(false) })
  // const isScreenSizeTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 }, undefined, (matches: boolean) => { _setCollapsed(true) })
  // const isScreenSizeDesktop = useMediaQuery({ minWidth: 992 }, undefined, (matches: boolean) => { _setCollapsed(false) })

  return (
    <Sider
      collapsible
      theme="light"
      width={width}
      trigger={null}
      collapsed={collapsed}
      className="site-layout-menu-background"
      style={{
        overflow: "hidden",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        // transition: "1s",
      }}
    >
      <RenderLogo
        collapsed={collapsed}
        src={{
          sm: "/images/logo-dashboard-sm.png",
          lg: "/images/logo-dashboard.png",
        }}
      />
      <RenderMenu />
    </Sider>
  );
};

export default RenderSideBar;
