import { Col, Form, FormInstance, Row } from "antd";
import { _CustomItemQuestionItem } from "../../interface";
import Marker from "./Marker";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  form?: FormInstance<any>;
  values: _CustomItemQuestionItem;
  disabled?: boolean;
  cusname?: any;
  isFormList?: string | number;
}
const MarkerImage = ({ values, disabled, isFormList, ...props }: Props) => {
  return (
    <div>
      <Row gutter={[6, 6]} align="middle">
        <Col span={18}>
          <Form.Item
            label={values["title"] || "ใส่ข้อมูลตัวเลข"}
            // name={[props.cusname, values["keyName"]]}
            name={
              props.cusname
                ? [props.cusname, values["keyName"]]
                : values["keyName"]
            }
            rules={[
              {
                required: !!values.required,
                message: `กรุณาใส่ข้อมูล ${values["title"]}`,
              },
            ]}
          >
            <Marker src={values.imageMarkerOption?.src} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default MarkerImage;
