import { Form, FormInstance, Select } from "antd";
import { useState } from "react";
import { _CustomItemQuestionItem } from "../../interface";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  form?: FormInstance<any>;
  values: _CustomItemQuestionItem;
  disabled?: boolean;
  isFormList?: string | number;
}

const { Option } = Select;

const Choice = ({ values, disabled, form, isFormList, ...props }: Props) => {
  const html = values["description"] || ``;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onSelect = (valueSelect: string, option: any) => {
    if (!!values.optionalOption) {
      if (values?.optionalOption?.value === valueSelect) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  return (
    <div>
      <Form.Item
        label={values["title"] || "เลือกคำตอบ"}
        name={isFormList ? [isFormList, values["keyName"]] : values["keyName"]}
        rules={[
          {
            required: !!values.required,
            message: `กรุณาใส่ข้อมูล ${values["title"]}`,
          },
        ]}
      >
        <Select
          {...values.propsSelect}
          disabled={disabled}
          onBlur={() => {
            ("");
          }}
          onSelect={onSelect}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          {values["option"]?.map((item, ind) => (
            <Option value={item.value} key={ind}>
              {item.title}
            </Option>
          ))}
          {!!values["optionalOption"] && (
            <Option value={values["optionalOption"].value}>
              {values["optionalOption"].title}
            </Option>
          )}
        </Select>
      </Form.Item>
      <span dangerouslySetInnerHTML={{ __html: html }} />
      {isVisible && props.children}
    </div>
  );
};

export default Choice;

// ex.

// {
//   title: "การศึกษา",
//   keyName: "question15",
//   type: "select",
//   propsSelect: {
//     showSearch: true,
//     placeholder: "เลือกการศึกษา",
//   },
//   option: [
//     {
//       id: 1,
//       value: "ประถมศึกษา",
//       title: "ประถมศึกษา",
//     },
//     {
//       id: 2,
//       value: "มัธยมศึกษา",
//       title: "มัธยมศึกษา",
//     },
//     {
//       id: 3,
//       value: "ปวช.",
//       title: "ปวช.",
//     },
//     {
//       id: 4,
//       value: "ปวส.",
//       title: "ปวส.",
//     },
//     {
//       id: 5,
//       value: "อนุปริญญา",
//       title: "อนุปริญญา",
//     },
//     {
//       id: 6,
//       value: "ปริญญาตรี",
//       title: "ปริญญาตรี",
//     },
//     {
//       id: 7,
//       value: "ปริญญาโท",
//       title: "ปริญญาโท",
//     },
//     {
//       id: 8,
//       value: "ปริญญาเอก",
//       title: "ปริญญาเอก",
//     },
//   ],
// optionalOption: {
//   id: 0,
//   value: "อื่นๆ",
//   title: "อื่นๆ",
//   children: [
//     {
//       title: "อื่นๆ ระบุ",
//       keyName: "question7",
//       propsTextArea: { rows: 5, showCount: false },
//       type: "textArea",
//     },
//   ],
// },
// },
