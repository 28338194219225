import { useAuthContextState } from '@AuthContext/store';
import { VisitFile } from '@Interface/visit';
import { Col, Empty, Layout, Modal, Row } from 'antd';
import { useState } from 'react';
import DicomViewer from './dicom-viewer';
import Sidebar from './sidebar';

interface Props {
  visible: boolean;
  onCancel: () => void;
  urls: VisitFile[];
  visitId: string;
  haveCapture?: boolean;
  captureImage?: (currentImg: any) => void;
}
interface Series {
  id: string;
  imageUrl: string;
  maxFrames?: number;
  arrayBuffer?: ArrayBuffer;
}
const ModalViewer = ({ visible, onCancel, urls, visitId, haveCapture = true, captureImage }: Props) => {
  const [selectedSeries, setSelectedSeries] = useState<any | null>(null);
  const [currentFrame, setCurrentFrame] = useState<number>(0);
  const { token } = useAuthContextState();

  const series = urls?.map((item, index) => {
    return {
      id: (index + 1).toString(),
      imageUrl: item.fileUrl,
    };
  });
  const handleSelectSeries = (series: any) => {
    setSelectedSeries(series);
  };

  return (
    <Modal
      title="View DICOM"
      visible={visible}
      onCancel={onCancel}
      style={{ padding: 0, height: '70vh' }}
      width="70vw"
      footer={false}
    >
      {!urls.length ? (
        <div>
          <Row justify="center" className="w-100">
            <Col span={24}>
              <Empty />
            </Col>
          </Row>
        </div>
      ) : (
        <Layout style={{}}>
          <Sidebar series={series} onSelectSeries={handleSelectSeries} visible={visible} />
          <Layout
            style={{
              height: '100%',
              marginInlineStart: 0,
              backgroundColor: '#000',
            }}
          >
            <div style={{ height: '100%' }}>
              {selectedSeries && (
                <div style={{ height: '100%' }}>
                  <div style={{}}>
                    <DicomViewer
                      token={token}
                      imageId={selectedSeries?.imageId || ''}
                      maxFrames={selectedSeries?.maxFrames || 1}
                      setCurrentFrame={setCurrentFrame}
                      currentFrame={currentFrame}
                      visitId={visitId}
                      haveCapture={haveCapture}
                      captureImage={captureImage}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    <span style={{ color: 'white' }}> Series: {selectedSeries.id}</span>
                    <span style={{ textAlign: 'right', color: 'white' }}>
                      Frame: {currentFrame + 1} / {selectedSeries?.maxFrames || 1}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </Layout>
        </Layout>
      )}
    </Modal>
  );
};
export default ModalViewer;
