import React from "react";
import { Avatar, Col, Divider, Form, Image, Row, Space, Tag } from "antd";
import { Button, DatePicker } from "antd";
import PagePaper from "@Components/PagePaper";
import { PatientItem } from "@Interface/patient";
const { RangePicker } = DatePicker;
type Props = {
  type?: "report";
  patientInfo: PatientItem | null;
  patientImg: string;
};
export interface SearchFailed {
  hn?: string;
  idCard?: string;
  fullName?: string;
  dateRange?: Date[];
  doctorname?: string;
}

const FormHeader = (props: Props) => {
  // const [form] = Form.useForm();

  return (
    <div
      className="form-search"
      // style={{ padding: "24px 16px", paddingBottom: 5, background: "#fff" }}
    >
      <div className="w-100 " style={{ flex: 1 }}>
        <Row className="h-100">
          <Col span={24}>
            <PagePaper>
              <Row justify="space-between" align="middle">
                <Col md={8}>
                  <Space size="middle">
                    <div>
                      <Avatar
                        src={
                          <Image
                            border-radius="50%"
                            src={
                              props.patientImg || "/images/ProfileDefualt.svg"
                            }
                            preview={false}
                            alt=""
                          />
                        }
                      />
                    </div>
                    <div>
                      <div>{props.patientInfo?.fullName}</div>
                      <Tag color="magenta">
                        <span>HN:{props.patientInfo?.hn}</span>
                      </Tag>
                    </div>
                  </Space>
                </Col>
                <Col md={12}>
                  <Row justify="end" align="middle" gutter={[16, 16]}>
                    <Col md={12}>
                      <Form.Item
                        className="label-date"
                        label="วันที่ :"
                        name="dateRange"
                        rules={[
                          {
                            required: false,
                            message: "กรุณาใส่เลขบัตรประชาชน",
                          },
                        ]}
                        style={{ marginTop: "10px" }}
                      >
                        <RangePicker size="large" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        style={{ marginTop: "-50px" }}
                      >
                        ค้นหา
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider />
              {/* <FormPatient
                form={form}
                onFinish={onFinish}
                isLoading={isLoading}
                initialValues={patientInfo}
                // key={form ? "notLoadedYet" : "loaded"}
              /> */}
            </PagePaper>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FormHeader;
