/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { RiFileExcel2Line } from "react-icons/ri";

import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import { PaginationExcludeTotal } from "@Interface/service";

import { PatientItem, RESGetPatientService } from "@Interface/patient";
import { _cleanObject, rowClassName } from "@Utils/utils";
import { _RES_validateError } from "@Utils/validate";
import { Button, Col, Pagination, Row, Space, Table } from "antd";

import PageHeader from "@Components/PageHeader";
import FormSearch from "@Components/form/FormSearch";
import _ReportService, { GetExcelAPI } from "@Services/api/report";
import _VisitService from "@Services/api/visit";
import useZipDownload2 from "@Views/Diagnostics/FormDiagnostics/fileDownload";
import { EyeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { ImDownload3 } from "react-icons/im";
import { useNavigate } from "react-router";
import "../styles/index.less";

type Props = {};
interface SearchFailed {
  dateRange?: Date[];
  doctorname?: string;
}

const ReportListComponent = (props: Props) => {
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalReportList, setTotalReportList] = useState<number>(0); //200
  const [reportList, setReportList] = useState<PatientItem[] | []>([]);
  const [search, setSearch] = useState<any>();
  const [pagination, setPagination] = useState<PaginationExcludeTotal>({
    page: 1,
    perpage: 10,
    startIndex: 1,
  });
  const [downloadAndZip] = useZipDownload2();
  const navigate = useNavigate();

  useEffect(() => {
    getReportList();

    return () => {};
  }, [pagination]);

  const getReportList = async (search?: SearchFailed) => {
    try {
      setIsLoading(true);
      const keyOnLoad = "loginReportList";
      const params = {
        page: pagination.page,
        limit: pagination.perpage,

        ...search,
      };
      const res = await _ReportService.GetReportAllAPI<RESGetPatientService>(
        params,
        token["accessToken"]
      );
      setSearch(search);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setReportList(res.result[0]["data"]);
            setTotalReportList(res.result[0]["total"]);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    getReportList();
  };
  const onFinish = (value: SearchFailed) => {
    const searchValue = {
      doctor: value.doctorname,
      startDate:
        !!value?.dateRange?.length && dayjs(value?.dateRange[0]).valueOf(),
      endDate:
        !!value?.dateRange?.length && dayjs(value?.dateRange[1]).valueOf(),
    };
    getReportList(_cleanObject.obj(searchValue));
  };

  const onExportExcel = async () => {
    // try {
    const params = {
      page: pagination.page,
      limit: pagination.perpage,
      ...search,
    };
    const res = await GetExcelAPI(params, token["accessToken"]);
    const data = res.data;
    downloadFile(data);
    // } catch (error) {}
  };

  const downloadFile = (blobFile: any) => {
    const url = window.URL.createObjectURL(new Blob([blobFile]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const onClickDowload = async (visitId: number) => {
    const id = Number(visitId);
    const res = await _VisitService.visitDownloadFiles(id, token.accessToken);
    const data = res.result;
    downloadAndZip(data);
  };

  const viewDiagnosticsByVisitId = (id: number) => {
    navigate("/report/view-info/" + id);
  };

  const columns = [
    {
      title: "#",
      key: "id",
      width: 50,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{rowIndex + (pagination.startIndex || 0)}</span>
      ),
    },
    {
      title: "เลขบัตรประชาชน",
      dataIndex: "idCard",
      key: "idCard",
      // width: 200,
    },
    {
      title: "เลข HN",
      dataIndex: "hn",
      key: "hn",
      // width: 200,
    },
    {
      title: "วันที่",
      dataIndex: "createAt",
      key: "createAt",
      // width: 150,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <span>{dayjs(text).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "fullName",
      key: "fullName",
      // width: 300,
    },
    {
      title: "ที่ตั้งของรถตรวจ",
      dataIndex: "location",
      key: "location",
      width: 220,
      render: (data: any, record: PatientItem, rowIndex: number) => (
        <span className="data-webkitline"> {data}</span>
      ),
    },
    {
      title: "รายชื่อหมอ",
      dataIndex: "doctor",
      key: "doctor",
      // width: 50,
      render: (data: any, record: PatientItem, rowIndex: number) => (
        <span className="Doctor-data-import"> {data}</span>
      ),
    },
    // {
    //   title: "สถานะ",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (data: any, record: PatientItem, rowIndex: number) => (
    //     <div className="Doctor-status-content">
    //       {data === "LOW" ? (
    //         <>
    //           <span className="list-status-low"></span>
    //           <span className="Doctor-status-data01">Low risk</span>
    //         </>
    //       ) : data === "MED" ? (
    //         <>
    //           <span className="list-status-med"></span>
    //           <span className="Doctor-status-data02">Med risk</span>
    //         </>
    //       ) : data === "HIGH" ? (
    //         <>
    //           <span className="list-status-high"></span>
    //           <span className="Doctor-status-data02">High risk</span>
    //         </>
    //       ) : (
    //         <>
    //           <span className="list-status-not"></span>
    //           <span className="Doctor-status-data02">ไม่พบข้อมูล</span>
    //         </>
    //       )}
    //     </div>
    //   ),
    //   // width: 300,
    // },
    // {
    //   title: "จัดการ",
    //   key: "action",
    //   width: 130,
    //   align: "center" as const,
    //   render: (text: string, record: PatientItem, rowIndex: number) => (
    //     <Space>
    //       <Popconfirm
    //         okText="ยืนยัน"
    //         cancelText="ยกเลิก"
    //         title="ยืนยันการลบข้อมูลผู้ป่วย？"
    //         onConfirm={() => removeReportByVisitId(record.id)}
    //         okButtonProps={{ loading: isLoadingConfirm }}
    //       >
    //         <Button type="ghost" className="flex-center btn-action">
    //           <MdDelete />
    //         </Button>
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
    {
      title: "จัดการ",
      key: "action",
      width: 250,
      align: "center" as const,
      render: (text: string, record: PatientItem, rowIndex: number) => (
        <Space>
          <Button
            type="ghost"
            className="flex-center btn-action"
            onClick={() => viewDiagnosticsByVisitId(record.id)}
          >
            <EyeOutlined />
          </Button>
          <span> </span>
          <Button
            type="link"
            className="flex-center btn-action"
            onClick={() => onClickDowload(record.id)}
            style={{
              color: "#0980FD",
              background: "#fff",
              border: "1px solid #0980FD",
              width: 42,
              height: 42,
              borderRadius: "50%",
            }}
          >
            <ImDownload3 />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <PageHeader
          title="รายงาน"
          subTitle="รายงานทั้งหมด"
          action={
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => onExportExcel()}
                  // style={{ paddingLeft: 30, paddingRight: 30 }}
                  style={{ width: "100%" }}
                  className="flex-center"
                >
                  <RiFileExcel2Line />
                  <span> Export Excel</span>
                </Button>
              </Col>
            </Row>
          }
        />
      </Col>
      <Col span={24} className="content">
        <FormSearch onFinish={onFinish} onCancel={onCancel} type="report" />
        <Table
          size="large"
          rowKey="id"
          loading={isLoading}
          columns={columns}
          className="abus-table"
          dataSource={reportList}
          scroll={{ x: 1500 }}
          pagination={false}
          rowClassName={rowClassName}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onDoubleClick: (event) => {
          //       viewPatientById(record.id);
          //     },
          //   };
          // }}
        />
        <Row justify="end" style={{ marginTop: 20 }}>
          <Col>
            {totalReportList / pagination.perpage > 1 && (
              <Pagination
                className="abus-pagination"
                total={totalReportList}
                onChange={(page: number, pageSize: number) => {
                  setPagination({
                    ...pagination,
                    page: page,
                    perpage: pageSize,
                    startIndex: page * pageSize,
                  });
                }}
                defaultCurrent={pagination.page}
                defaultPageSize={pagination.perpage}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ReportListComponent;
