import { Fetch } from "@Utils/fetch/fetch";

export const getInvoiceByIdAPI = async <T = any>(
    id: number,
    token: string
) => {
    const res = await Fetch<T>({
        token: token,
        method: "GET",
        path: `/v1/invoice/${id}`,
    });
    return res;
};

export const CreateNewInvoiceAPI = async <T = any>(
    data: any,
    token: string
) => {
    const res = await Fetch<T>(
        {
            token: token,
            method: "POST",
            path: "/v1/invoice",
            data
        },
    );
    return res;
};
