import { useAuthContextState } from "@AuthContext/store";
import CustomCard from "@Components/CCard/CustomCard";
import ViewFileInfo from "@Components/CFilesInfo/ViewFileInfo";
import CreateFormItem from "@Components/form/CreateFormItem/_RenderFormItem";
import type { ITypeQuestionProp } from "@Interface/patient";
import { VisitField, VisitFiles } from "@Interface/visit";
import _VisitService from "@Services/api/visit";
import useZipDownload2 from "@Views/Diagnostics/FormDiagnostics/fileDownload";
import { EyeOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd";
import { Button, Col, Row, Tabs } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ModalViewImg from "./modalViewImg";
interface Props {
  dataForm?: FormInstance<any> | any;
  questionsForm?: ITypeQuestionProp | null;
  fileList?: VisitFiles[];
  studyId?: string | null;
  visitInfo?: VisitField | null;
}

const Mammogram = ({
  questionsForm,
  dataForm,
  fileList,
  studyId,
  visitInfo,
}: Props) => {
  const { token } = useAuthContextState();
  const params = useParams() as { visitId: string };
  const [downloadAndZip] = useZipDownload2();
  const [visible, setVisible] = useState<boolean>(false);
  const handleDownload = async () => {
    const id = Number(params.visitId);
    console.log("id", id);
    const res = await _VisitService.visitDownloadFiles(
      id,
      token.accessToken,
      "A_BUS"
    );
    const data = res.result;
    downloadAndZip(data);
  };
  const handleViewDICOM = () => {
    window.location.replace(
      `http://127.0.0.1:8080/viewer?StudyInstanceUIDs=${studyId}`
    );
  };

  const geStatus = (status: string) => {
    switch (status) {
      case "รอประมวลผล AI":
        return { status: "processing", name: "รอประมวลผล AI" };
      case "ยังไม่ประมวลผล AI":
        return { status: "warning", name: "ยังไม่ประมวลผล AI" };
      case "ประมวลผล AI เสร็จสิ้น":
        return { status: "success", name: "ประมวณผล AI เสร็จสิ้น" };
      default:
        return { status: "warning", name: "ยังไม่ประมวลผล AI" };
    }
  };

  return (
    <Row className="container-box tabs-content" gutter={[10, 10]}>
      <Col span={24}>
        <ViewFileInfo
          title="ข้อมูลภาพ Mammogram"
          onDownload={handleDownload}
          onViewDICOM={!!studyId ? handleViewDICOM : undefined}
          extraAction={[
            <Button
              icon={<EyeOutlined />}
              size="middle"
              onClick={() => {
                setVisible(true);
              }}
            >
              VIEWER
            </Button>,
          ]}
          status={[geStatus(visitInfo?.statusAi || "")]}
          fileList={fileList || []}
        />
      </Col>
      <Col span={24}>
        <CustomCard title="Mammogram Form">
          <Tabs type="card" size="middle" style={{ background: "#fff" }}>
            {!!questionsForm?.mammogram &&
              questionsForm?.mammogram.map((item: any, ind: number) => (
                <Tabs.TabPane
                  tab={
                    <span style={{ textTransform: "capitalize" }}>
                      {item.name}
                    </span>
                  }
                  key={item.name}
                  style={{ background: "#fff" }}
                >
                  <div className="box">
                    <CreateFormItem
                      key={ind}
                      values={item.questions}
                      form={dataForm}
                      fileList={fileList || []}
                      visitInfo={visitInfo}
                    />
                  </div>
                </Tabs.TabPane>
              ))}
          </Tabs>
        </CustomCard>
        <ModalViewImg visible={visible} onCancel={() => setVisible(false)} />
      </Col>
    </Row>
  );
};

export default Mammogram;
