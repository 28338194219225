import { useAuthContextState } from '@AuthContext/store';
import {} from '@Routes/mocRoleAuth';
import { _findObjectOfArrayByKeyName } from '@Utils/utils';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { routerMenu } from './_menu';

const RenderMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const language = i18n.language as 'th' | 'en';
  const { userInfo } = useAuthContextState();

  const handleClickMenu = (path: string) => {
    navigate(path);
    return path;
  };

  const getActiveMenu = () => {
    const path = location.pathname.split('/');
    const menu = _findObjectOfArrayByKeyName(routerMenu, `/${path[1]}`, 'path');

    return menu ? [menu.keyName] : undefined;
  };

  const activeMenu = getActiveMenu();

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={activeMenu}
      selectedKeys={activeMenu}
      className="site-layout-menu"
    >
      {routerMenu
        .filter((menu) => !!_findObjectOfArrayByKeyName(userInfo.permission, menu.keyName, 'keyName'))
        .map((menu) => (
          <Menu.Item key={menu.keyName} icon={<menu.icon.components />} onClick={() => handleClickMenu(menu.path)}>
            {menu.title[language]}
          </Menu.Item>
        ))}
    </Menu>
  );
};

export default RenderMenu;
