import { FetchMaps } from "@Utils/fetch/fetch";

//   export const testLongdoMapAPI = async <T = any>(
//     data:any
//   ) => {
//     const res = await TestMaps<T>({
//       method: "GET",
//     },data);
//     return res;
//   };

export const testLongdoMapAPI = async <T = any>(params: any) => {
  const res = await FetchMaps<T>({
    method: "GET",
    lat: params.lat,
    lon: params.lon,
  });
  return res;
};

export const _MapLongdo = {
  testLongdoMapAPI,
};

export default _MapLongdo;
