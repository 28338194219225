import { Form, Input } from "antd";
import { _CustomItemQuestionItem } from "../../interface";

export interface Props {
  values: _CustomItemQuestionItem;
  disabled?: boolean;
  keyLoop?: any;
  isFormList?: string | number;
}
const inputChannel = ({ values, disabled, isFormList, keyLoop }: Props) => {
  const html = values["description"] || ``;
  return (
    <div>
      <Form.Item
        label={values["title"] || "ใส่ข้อความ"}
        // name={values["keyName"]}
        name={isFormList ? [isFormList, values["keyName"]] : values["keyName"]}
        rules={[
          {
            required: !!values.required,
            message: `กรุณาใส่ข้อมูล ${values["title"]}`,
          },
        ]}
      >
        <Input {...values.propsInput} size="large" disabled={disabled} />
      </Form.Item>
      <span dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default inputChannel;
