import { CheckOutlined } from '@ant-design/icons';
import PageHeader from '@Components/PageHeader';
import { Avatar, Card, Col, DatePicker, Row, Select, Spin } from 'antd';
// import { useTranslation } from "react-i18next";
import { useAuthContextDispatch, useAuthContextState } from '@AuthContext/store';
import { GetDashboardAllAPI, GetYeatboardAllAPI } from '@Services/api/dashboard';
import { _RES_validateError } from '@Utils/validate';
import Chart from 'chart.js/auto';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { FaDiagnoses } from 'react-icons/fa';
import { MdDriveFolderUpload, MdPendingActions } from 'react-icons/md';
import './styles/index.less';

interface DataDashboardAll {
  visit: {
    all: string;
    waiting: string;
    pending: string;
    reserved: string;
    second_opinion: string;
    done: string;
    all_diagnose: string;
  };
  chart: { label: string; month: number; value: number }[];
  doctor: number;
  patient: number;
  diagnose: { low: string; med: string; high: string };
}

const Dashboard = () => {
  // const { i18n } = useTranslation();
  // const language = i18n.language as "th" | "en";
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataChart, setDataChart] = useState<DataDashboardAll>();
  const [yearChart, setYearChart] = useState<any>();
  const [showmount, setShowMount] = useState<string>(`${dayjs().year()}`);

  useEffect(() => {
    let clean = true;
    if (clean) {
      getDataDashboard();
      getYearDashboardAPI();
      canvasCallback();
    }
    return () => {
      clean = false;
      setYearChart({});
    };
    //eslint-disable-next-line
  }, []);

  const showlindata = [
    {
      id: 'showlindata',
      afterDraw: (chart: any, ctx: any) => {
        if (chart.tooltip?._active?.length) {
          let x = chart.tooltip._active[0].element.x;
          let yAxis = chart.scales.y;
          let ctx = chart.ctx;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, yAxis.top);
          ctx.lineTo(x, yAxis.bottom);
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#1C5AE8';
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

  const getDataDashboard = async (value?: number) => {
    try {
      setIsLoading(true);
      const keyOnLoad = 'getDiagnosticsList';
      const chartyear = {
        chartYear: value === undefined ? dayjs().year() : value,
      };
      const res = await GetDashboardAllAPI(token['accessToken'], chartyear);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setDataChart(res.result[0]);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  const getYearDashboardAPI = async () => {
    try {
      setIsLoading(true);
      const keyOnLoad = 'getDiagnosticsList';
      const res = await GetYeatboardAllAPI(token.accessToken);
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            setYearChart(res.result);
            setIsLoading(false);
            return true;
          },
        },
      });
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  const data = {
    labels: [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม ',
      'เมษายน',
      'พฤษภาคม ',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ],
    datasets: [
      {
        borderWidth: 2,
        tension: 0.6,
        pointRadius: 0,
        backgroundColor: 'rgb(28, 90, 232, 1)',
        borderColor: '#ff748e',

        data: [
          Number(dataChart?.chart[0].value),
          Number(dataChart?.chart[1].value),
          Number(dataChart?.chart[2].value),
          Number(dataChart?.chart[3].value),
          Number(dataChart?.chart[4].value),
          Number(dataChart?.chart[5].value),
          Number(dataChart?.chart[6].value),
          Number(dataChart?.chart[7].value),
          Number(dataChart?.chart[8].value),
          Number(dataChart?.chart[9].value),
          Number(dataChart?.chart[10].value),
          Number(dataChart?.chart[11].value),
        ],
      },
    ],
  };
  const showdatayear = (tooltipItems: any[]) => {
    return tooltipItems[0].label + ' ' + showmount;
  };

  const options = {
    responsive: true,
    pointHoverRadius: 0,
    pointHitRadius: 100,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: '#757783',
          padding: 20,
          font: {
            family: 'Sukhumvit Set',
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          drawBorder: false,
          color: '#E6E9F4',
          borderColor: '#E6E9F4',
        },
        ticks: {
          color: '#7E84A3',
          font: {
            family: 'Sukhumvit Set',
            size: 14,
          },
        },
      },
    },
    plugins: {
      tooltip: {
        usePointStyle: true,
        backgroundColor: '#FFFFFF',
        titleColor: '#4B4B4B',
        titleFont: {
          family: 'normal normal normal 13px/20px Sukhumvit Set',
        },
        bodyColor: '#4B4B4B',
        bodyFont: {
          family: 'normal normal normal 13px/20px Sukhumvit Set',
        },
        borderColor: '#05081D',
        borderWidth: 0.06,
        callbacks: {
          title: showdatayear,
        },
      },
      legend: {
        display: false,
      },
    },
  };
  const chartRef = useRef<Chart | null>(null);
  const canvasCallback = (canvas?: HTMLCanvasElement | null) => {
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      chartRef.current = new Chart(ctx, {
        type: 'line',
        data: data,
        options: options,
      });
    }
  };
  const { Option } = Select;

  return (
    <Spin spinning={isLoading}>
      <div>
        <Row>
          <Col span={16}>
            <PageHeader title="ภาพรวม" subTitle="ภาพรวม" />
          </Col>
          <Col span={8}>
            <div className="">
              <span>วันที่ </span>
              <DatePicker format={'YYYY-MM-DD'} placeholder="วันที่" style={{ width: '60%' }} />
            </div>
          </Col>
        </Row>
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={24}>
              <Row gutter={[16, 16]}>
                <Col xs={6} xl={6}>
                  <div className="Card-Diagnose">
                    <Card
                      extra={
                        <span className="avatar-boder">
                          <Avatar
                            size={40}
                            style={{ color: '#ff748e' }}
                            icon={<MdDriveFolderUpload />} //
                          />
                        </span>
                      }
                      title={
                        <div className="font-Quantity">
                          <span>รอวินิจฉัย</span>
                        </div>
                      }
                      bordered={false}
                    >
                      {dataChart?.visit.pending} <span className="font-size">เคส</span>
                    </Card>
                  </div>
                </Col>
                <Col xs={6} xl={6}>
                  <div className="Card-Diagnose">
                    <Card
                      extra={
                        <span className="avatar-boder">
                          <Avatar
                            size={40}
                            style={{ color: '#ff748e' }}
                            icon={<MdPendingActions />} //
                          />
                        </span>
                      }
                      title={
                        <div className="font-Quantity">
                          <span>วินิจฉัย</span>
                        </div>
                      }
                      bordered={false}
                    >
                      {dataChart?.visit.waiting} <span className="font-size">เคส</span>
                    </Card>
                  </div>
                </Col>
                <Col xs={6} xl={6}>
                  <div className="Card-Diagnose">
                    <Card
                      extra={
                        <span className="avatar-boder">
                          <Avatar size={40} style={{ color: '#ff748e' }} icon={<FaDiagnoses />} />
                        </span>
                      }
                      title={
                        <div className="font-Quantity">
                          <span>วินิจฉัยร่วม</span>
                        </div>
                      }
                      bordered={false}
                    >
                      {dataChart?.visit.all_diagnose} <span className="font-size">เคส</span>
                    </Card>
                  </div>
                </Col>
                <Col xs={6} xl={6}>
                  <div className="Card-Diagnose">
                    <Card
                      extra={
                        <span className="avatar-boder">
                          <Avatar size={40} style={{ color: '#ff748e' }} icon={<CheckOutlined />} />
                        </span>
                      }
                      title={
                        <div className="font-Quantity">
                          <span>เสร็จสิ้น</span>{' '}
                        </div>
                      }
                      bordered={false}
                    >
                      {dataChart?.visit.done} <span className="font-size">เคส</span>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} xl={24}>
              <div>
                <Card>
                  <div className="Text-Patients">
                    <p className="Box-text-Patients">จำนวนผู้ป่วยรายเดือน</p>
                    <Select
                      className="Font-years"
                      defaultValue={'ปี ' + dayjs().year()}
                      style={{ width: 120 }}
                      //onChange={handleChange}
                      onChange={(value) => {
                        setShowMount(value);
                        getDataDashboard(Number(value));
                      }}
                    >
                      {yearChart?.length &&
                        yearChart?.map((year: any, ind: number) => {
                          return (
                            <Option key={ind} value={year.year}>
                              ปี {year.year}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                  <div>
                    {/* <canvas
                id="myChart"
                width="600"
                height="150"
                //ref={canvasCallback}
              ></canvas> */}
                    <Line data={data} options={options} plugins={showlindata} width="600" height="150" />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}></Row>
          <p></p>

          <Row gutter={[16, 16]}>
            <Col md={24} xl={12}>
              <Card>
                <div className="flex-box">
                  <div className="flex-quantity">
                    <p className="Fornt-Card">จำนวนหมอ</p>
                    <div className="Textbox-number">
                      {dataChart?.doctor} <span>คน</span>
                    </div>
                  </div>
                  <div className="flex-img-quantity">
                    <img className="" src="/images/doctor.svg" width={'81%'} alt=""></img>
                  </div>
                </div>
              </Card>
            </Col>
            <Col md={24} xl={12}>
              <Card>
                <div className="flex-box">
                  <div className="flex-quantity">
                    <p className="Fornt-Card">จำนวนคนไข้</p>
                    <div className="Textbox-number">
                      {dataChart?.patient} <span>คน</span>
                    </div>
                  </div>
                  <div className="flex-img-quantity">
                    <img className="" src="/images/patient.svg" width={'77%'} alt=""></img>
                  </div>
                </div>
              </Card>
            </Col>

            {/* <Col md={24} xl={10}>
              <Card>
                <Row className="Font-risk">
                  <Col>จำนวนความเสี่ยง</Col>
                </Row>
                <Row justify="center" gutter={16}>
                  <Col md={7}>
                    <Row justify="center">
                      <Col span={14} style={{ textAlign: "center" }}>
                        <Avatar
                          className="avatar-boder-risk-1"
                          size={55}
                          style={{ color: "#ff748e" }}
                        >
                          <span className="Number-risk">
                            {dataChart?.diagnose.low}
                          </span>
                        </Avatar>
                      </Col>
                      <Col
                        span={10}
                        className="box-boder-line"
                        style={{ marginTop: 8 }}
                      >
                        <p className="Font-risk-level" style={{ margin: 0 }}>
                          Low
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={10}>
                    <Row justify="center">
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Avatar
                          className="avatar-boder-risk-1"
                          size={55}
                          style={{ color: "#ff748e" }}
                        >
                          <span className="Number-risk">
                            {dataChart?.diagnose.med}
                          </span>
                        </Avatar>
                      </Col>
                      <Col
                        span={12}
                        className="box-boder-line"
                        style={{ marginTop: 8 }}
                      >
                        <p className="Font-risk-level" style={{ margin: 0 }}>
                          Meduim
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={7}>
                    <Row justify="center">
                      <Col span={14} style={{ textAlign: "center" }}>
                        <Avatar
                          className="avatar-boder-risk-1"
                          size={55}
                          style={{ color: "#ff748e" }}
                        >
                          <span className="Number-risk">
                            {dataChart?.diagnose.high}
                          </span>
                        </Avatar>
                      </Col>
                      <Col span={10} style={{ marginTop: 8 }}>
                        <p className="Font-risk-level" style={{ margin: 0 }}>
                          High
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col> */}
          </Row>
        </div>
      </div>
    </Spin>
  );
};

export default Dashboard;
