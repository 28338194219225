import { Role } from "@Components/DrawerProfile";
import ImageUpload from "@Components/ImageUpload";
import {
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  message,
} from "antd";
import { useLocation } from "react-router-dom";
import { UrlImage } from "../EditUser";
const { Option } = Select;

type Props = {
  form: FormInstance<any> | any;
  onSubmit?: (value: any) => void;
  role: Role[] | any;
  onEdit?: boolean;
  userImg?: UrlImage;
};

const FormUser = (props: Props) => {
  const location = useLocation();
  const disabled = props.onEdit === false;
  return (
    <Form
      scrollToFirstError
      size="large"
      form={props.form}
      layout="vertical"
      requiredMark="optional"
      onFinishFailed={() => message.error("ข้อมูลผิดพลาดหรือกรอกข้อมูลไม่ครบ")}
      onFinish={props.onSubmit}
      // initialValues={}
    >
      <Row className="" justify="center">
        <Col md={6}>
          <Form.Item
            label="โปรไฟล์ผู้ใช้"
            name="avatar"
            valuePropName="fileList"
          >
            <ImageUpload
              disbled={disabled}
              valueImage={props.userImg || "/images/ProfileDefualt.svg"}
            />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Row gutter={[16, 16]}>
            <Col md={12}>
              <Form.Item
                label="ชื่อ นามสกุล"
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่ชื่อและนามสกุล!",
                  },
                ]}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={<span className="">อีเมล์</span>}
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "กรุณาใส่อีเมล์ให้ถูกต้อง!",
                  },
                ]}
              >
                <Input placeholder="" className="" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="เบอร์โทรศัพท์"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่เบอร์โทรศัพท์!",
                  },
                ]}
              >
                <Input placeholder="" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="บทบาท"
                name="role"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่บทบาท!",
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder=""
                  optionFilterProp="children"
                  disabled={disabled}
                >
                  {props.role?.[0]?.data?.map((data: Role) => {
                    return (
                      <Option key={data.id} value={data.id}>
                        {data.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="หน่วยงาน"
                name="agency"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่หน่วยงาน!",
                  },
                ]}
              >
                <Input placeholder="" disabled={disabled} />
              </Form.Item>
            </Col>

            <Col md={24}>
              <Form.Item
                label="ความเชี่ยวชาญ"
                name="expertise"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่ความเชี่ยวชาญ!",
                  },
                ]}
              >
                <Input placeholder="" disabled={disabled} />
              </Form.Item>
            </Col>

            <Col md={24}>
              <Form.Item
                label="ใบอนุญาตประกอบวิชาชีพเวชกรรม เลขที่"
                name="medicalLicense"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่ความเชี่ยวชาญ!",
                  },
                ]}
              >
                <Input placeholder="" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      {location.pathname !== "/user-management/create" ? (
        <>
          {!props.onEdit ? (
            <></>
          ) : (
            <>
              <Row className="" justify="center" align="middle">
                <Col md={6} className="color-primary ">
                  เปลี่ยนรหัสผ่าน
                </Col>
                <Col md={12}>
                  <Row gutter={[16, 16]}>
                    <Col md={12}>
                      <Form.Item
                        label={<span className="">รหัสผ่านเก่า</span>}
                        name="currentPassword"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "กรุณาใส่รหัสผ่านเก่า!",
                        //   },
                        // ]}
                      >
                        <Input.Password placeholder="" className="" />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="รหัสผ่านใหม่"
                        name="password"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "กรุณาใส่รหัสผ่าน!",
                        //   },
                        // ]}
                      >
                        <Input.Password placeholder="" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <Row className="" justify="center" align="middle">
            <Col md={6} className="color-primary ">
              สร้างรหัสผ่าน
            </Col>
            <Col md={12}>
              <Row gutter={[16, 16]}>
                {/* <Col md={12}>
            <Form.Item
              label={<span className="">Username</span>}
              name="username"
              rules={[
                {
                  required: true,
                  message: "กรุณาใส่Username!",
                },
              ]}
            >
              <Input placeholder="" className="" />
            </Form.Item>
          </Col> */}
                <Col md={12}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาใส่รหัสผ่าน!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default FormUser;
