import { Fetch } from "@Utils/fetch/fetch";

export const LoginApi = async <T = any>(data: {
  email: string;
  password: string;
}) => {
  const res = await Fetch<T>({
    data,
    method: "POST",
    path: "/v1/auth/login",
  });
  return res;
};
