import React from "react";
import "./styles/index.less";
type Props = {
  children?: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
  className?: string;
};

const PagePaper = (props: Props) => {
  return (
    <div className={`PagePaper ${props.className}`} style={props.style}>
      {props.children || ""}
    </div>
  );
};

export default PagePaper;
