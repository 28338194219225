import React from "react";
import { Breadcrumb, Space } from "antd";
import { Link } from "react-router-dom";

type Props = {
  value: {
    path?: string;
    title: string;
    icon?: React.ReactNode;
  }[];
};

const Breadcumb = (props: Props) => {
  return (
    <Breadcrumb>
      {props.value.map((item, index) =>
        item.path ? (
          <Breadcrumb.Item key={index + item.title}>
            <Link to={item.path}>
              {item.icon}
              <Space>{item.title}</Space>{" "}
            </Link>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={index + item.title}>
            {item.icon}
            <span>{item.title}</span>
          </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  );
};

export default Breadcumb;
