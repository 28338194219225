import { Col, Form, Row } from "antd";
import { Input, InputNumber } from "antd";
import "./styles/index.less";
type Props = {
  location: boolean;
  viewer?: boolean;
};

const CreateVisit = (props: Props) => {
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col flex={1}>
          <Form.Item
            label={<span className="color-primary">ID Visit</span>}
            name="visitNo"
          >
            <Input className="color-primary" disabled />
          </Form.Item>
        </Col>
        {props.location && (
          <Col md={6}>
            <Form.Item label="Lat" name="lat">
              <Input disabled={!!props.viewer} />
            </Form.Item>
          </Col>
        )}
        {props.location && (
          <Col md={6}>
            <Form.Item label="Long" name="long">
              <Input disabled={!!props.viewer} />
            </Form.Item>
          </Col>
        )}
      </Row>
      <div>V/S information</div>
      <Row>
        <Col span={24} className="box">
          <Form.Item
            label="น้ำหนัก (กก.)"
            name="weight"
            rules={[{ required: true, message: "กรุณาใส่ข้อมูลน้ำหนัก!" }]}
          >
            {/* <Input placeholder="นำ้หนัก" /> */}
            <InputNumber<number>
              min={0}
              max={200}
              step={0.01}
              disabled={!!props.viewer}
              placeholder="น้ำหนัก (กก.)"
              className="w-100"
            />
          </Form.Item>
          <Form.Item
            label="ส่วนสูง (ซม.)"
            name="height"
            rules={[{ required: true, message: "กรุณาใส่ข้อมูลส่วนสูง!" }]}
          >
            <InputNumber<number>
              min={0}
              max={200}
              step={0.01}
              disabled={!!props.viewer}
              placeholder="ส่วนสูง (กก.)"
              className="w-100"
            />
          </Form.Item>
          <Form.Item
            label="BMI (Auto)"
            name="bmi"
            rules={[{ required: true, message: "กรุณาใส่ข้อมูล BMI!" }]}
          >
            <InputNumber<number>
              min={0}
              max={200}
              step={0.01}
              disabled={!!props.viewer}
              placeholder="BMI (Auto)"
              className="w-100"
            />
          </Form.Item>
          <Form.Item
            label="ความดัน (mm/Hg)"
            name="pressure"
            rules={[{ required: true, message: "กรุณาใส่ข้อมูลความดัน!" }]}
          >
            <Input placeholder="ความดัน (mm/Hg)" disabled={!!props.viewer} />
          </Form.Item>
          <Form.Item
            label="อุณหภูมิ (c)"
            name="temperature"
            rules={[{ required: true, message: "กรุณาใส่ข้อมูลอุณหภูมิ!" }]}
          >
            <InputNumber<number>
              min={0}
              max={500}
              step={0.01}
              disabled={!!props.viewer}
              placeholder="อุณหภูมิ (c)"
              className="w-100"
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CreateVisit;
