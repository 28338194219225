/* eslint-disable react-hooks/exhaustive-deps */
import {
  useAuthContextDispatch,
  useAuthContextState,
} from "@AuthContext/store";
import DoctorDiagnose from "@Components/form/DoctorDiagnose";
import PatientStatus from "@Components/form/PatientStatus";
import PageHeader from "@Components/PageHeader";
import PagePaper from "@Components/PagePaper";
import {
  RESGetVisitInfoService,
  VisitField,
  VisitFiles,
} from "@Interface/visit";
import _DiagnosticsService from "@Services/api/diagnostics";
import { _VisitService } from "@Services/api/visit";
import { _cleanObject, _findObjectOfArrayByKeyName } from "@Utils/utils";
import { _RES_validateError } from "@Utils/validate";
import useZipDownload2 from "@Views/Diagnostics/FormDiagnostics/fileDownload";
import CreateMail from "@Views/UploadImageData/CreateMail";
import { Button, Col, Divider, Form, Row, Skeleton } from "antd";
import pLimit from "p-limit";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export interface StatusDataForm {
  risk: string;
  description: string;
}

type Props = {};

const FormDoctor = (props: Props) => {
  const params = useParams() as { visitId: string };
  const { token } = useAuthContextState();
  const { _signOut } = useAuthContextDispatch();
  const [form] = Form.useForm();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const componentToPrint = useRef(null);
  const [visitFiles, setVisitFiles] = useState<{
    [key: string]: VisitFiles[];
  } | null>(null);
  const [visitInfo, setVisitInfo] = useState<VisitField | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [valueSubmit, setValueSubmit] = useState<boolean>(true);
  const [isDiagnosticsLoadind, setIsDiagnosticsLoadind] =
    useState<boolean>(false);
  const [studyId, setStudyId] = useState<string | null>();
  const [id, setId] = useState<number>();
  // const [image, setImage] = useState<any>();
  const [dataDoctor, setDataDoctor] = useState<any>();
  const navigate = useNavigate();
  const [downloadAndZip] = useZipDownload2();

  useEffect(() => {
    !!params.visitId && getVisitInfo(params.visitId);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVisitInfo = async (visitId: string) => {
    try {
      const keyOnLoad = "getVisitWaitingInfo";
      setIsLoading(true);
      const res =
        await _VisitService.GetVisitInfoByIdAPI<RESGetVisitInfoService>(
          visitId,
          token.accessToken
        );
      setVisitInfo(res.result[0]);
      setDataDoctor(res.result[0].diagnose);

      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          runOption: () => {
            if (res.result?.[0].diagnosisForm?.formData !== null) {
              form.setFieldsValue({
                ...res.result?.[0].diagnosisForm?.formData,
              });
            }

            const fileVisit = transformData(res.result[0]?.visitFiles);
            setVisitFiles(fileVisit);
            setStudyId(res.result[0].studyId);
            setVisitInfo(res.result[0]);
            setDataDoctor(res.result[0].diagnose);
            initVisitFilesUploads(res.result[0].visitFiles, dataSum);
            // setImage(res.result[0].patient.imageUrl);
            setId(res.result[0].patient.id);
            setIsLoading(false);
            return true;
          },
        },
      });
      const dataRes: any = res.result[0];
      const dataSet = {
        risk: dataRes?.risk,
        description: dataRes?.description,
      };
      const dataSet2 =
        dataRes?.diagnosis_form !== null && dataRes.diagnosis_form?.form_data;
      const dataSum = { ...dataSet, ...dataSet2 };
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      return false;
    }
  };

  const initVisitFilesUploads = (values: VisitFiles[] | [], dataSum: any) => {
    const data = {
      apLeft: _findObjectOfArrayByKeyName(values, "apLeft", "keyName"),
      ltLeft: _findObjectOfArrayByKeyName(values, "ltLeft", "keyName"),
      medLeft: _findObjectOfArrayByKeyName(values, "medLeft", "keyName"),
      apRight: _findObjectOfArrayByKeyName(values, "apRight", "keyName"),
      ltRight: _findObjectOfArrayByKeyName(values, "ltRight", "keyName"),
      medRight: _findObjectOfArrayByKeyName(values, "medRight", "keyName"),
      ...dataSum,
    };
    form.setFieldsValue(_cleanObject.obj(data));
  };

  const onFinish = async (value: any) => {
    try {
      if (visitInfo?.diagnosisForm?.formData) {
        value = {
          ...visitInfo?.diagnosisForm.formData,
          ...value,
        };
      }
      const keyOnLoad = "onSaveFormdoctor";
      setIsDiagnosticsLoadind(true);

      const [diagnosisFormGroup5_question1, mammogramFormGroup5_question1] =
        await Promise.all([
          value?.diagnosisFormGroup5_question1?.length > 0
            ? uploadFile("reportDicom", value?.diagnosisFormGroup5_question1)
            : undefined,
          value?.mammogramFormGroup5_question1?.length > 0
            ? uploadFile(
                "reportMammogram",
                value?.mammogramFormGroup5_question1
              )
            : undefined,
        ]);
      value.diagnosisFormGroup5_question1 = diagnosisFormGroup5_question1;
      value.mammogramFormGroup5_question1 = mammogramFormGroup5_question1;

      console.log("value", value);

      const dataAddFieldQuestionnaire = {
        diagnosisForm: JSON.stringify(value),
      };

      const res = await _DiagnosticsService.saveDiagnosisFormAPI(
        dataAddFieldQuestionnaire,
        params.visitId,
        token.accessToken
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_400: {
          runOption: () => {
            // message.error({});
          },
        },
        Code_200: {
          message: "บันทึกข้อมูลสำเร็จ",
          runOption: () => {
            setIsDiagnosticsLoadind(false);
            navigate("/doctor/view-list");
            return true;
          },
        },
      });
    } catch (error) {
      console.log(error);
      setIsDiagnosticsLoadind(false);
    }
  };

  const uploadFile = async (
    type: "reportDicom" | "reportMammogram",
    value?: any
  ) => {
    const limit = pLimit(2);
    const fileMedia = value?.filter((e: any) => {
      return e.id;
    });
    const file = value
      ?.filter((e: any) => {
        return e.file;
      })
      .map((e: any) => e.file);
    if (file.length === 0) return fileMedia;
    const uploadPromises = async (e: any) => {
      const formData = new FormData();
      formData.append("id", params.visitId);
      file.forEach((item: any) => formData.append(type, item));
      const res = await _VisitService.UploadAPI(formData, token.accessToken);
      return res?.data?.result?.[0]?.[type];
    };
    const [res] = await Promise.all(
      file.map((f: any) => limit(async () => uploadPromises(f)))
    );
    return [...fileMedia, ...res];
  };

  const onReserveDoctorForm = async (value: any) => {
    try {
      if (visitInfo?.diagnosisForm?.formData) {
        value = {
          ...visitInfo?.diagnosisForm.formData,
          ...value,
        };
      }
      const dataAddFieldQuestionnaire = {
        diagnosisForm: JSON.stringify(value),
      };

      const keyOnLoad = "onReserveDoctorForm";
      setIsDiagnosticsLoadind(true);
      const res = await _DiagnosticsService.saveVisitDoneAPI(
        dataAddFieldQuestionnaire,
        params.visitId,
        token.accessToken
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_400: {
          runOption: () => {},
        },
        Code_200: {
          message: "ส่งข้อมูลผู้ป่วยสำเร็จ",
          runOption: () => {
            setIsDiagnosticsLoadind(false);
            return true;
          },
        },
      });
      navigate("/doctor/view-list");
    } catch (error) {
      console.log("error", error);
      setIsDiagnosticsLoadind(false);
      return false;
    }
  };

  const onDownload = async (visitId: string) => {
    const id = Number(visitId);
    const res = await _VisitService.visitDownloadFiles(id, token.accessToken);
    const data = res.result;
    // data.map((data: any) => {
    //   window.open(data.url);
    // });
    downloadAndZip(data);
  };

  const handleCreateMail = () => {
    // send API
    setIsModalVisible(true);
  };

  const askOpinion = async () => {
    try {
      setIsLoading(true);
      const keyOnLoad = "onSendAskOpinion";
      setIsDiagnosticsLoadind(true);
      const res = await _VisitService.visitAskOpinion(
        params.visitId,
        token.accessToken
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_200: {
          message: "ส่งคำร้องขอสำเร็จ",
          runOption: () => {
            setIsDiagnosticsLoadind(false);
            setIsLoading(false);
            return true;
          },
        },
      });
      navigate("/doctor/view-list");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const visitReserve = async () => {
    try {
      const keyOnLoad = "sendVisitReserve";
      setIsDiagnosticsLoadind(true);
      const res = await _VisitService.SendVisitToReserve(
        params.visitId,
        token.accessToken
      );
      _RES_validateError(res, keyOnLoad, {
        Code_401: { runOption: _signOut },
        Code_400: {
          message: "บันทึกไม่สำเร็จ",
          runOption: () => {
            setIsDiagnosticsLoadind(false);
            return true;
          },
        },
        Code_200: {
          message: "ส่งผู้ป่วยสำเร็จ",
          runOption: () => {
            setIsDiagnosticsLoadind(false);
            return true;
          },
        },
      });
      navigate("/doctor/view-list");
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitForm = (e: number) => {
    if (e === 1) {
      form.submit();
      setValueSubmit(true);
    } else if (e === 2) {
      form.submit();
      setValueSubmit(false);
    }
  };
  const onCloseModalCreateVisit = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <CreateMail
        onClose={onCloseModalCreateVisit}
        isModalVisible={isModalVisible}
        componentToPrint={componentToPrint}
      />
      <PageHeader
        back={
          <Row gutter={[16, 16]} justify="end">
            <Col flex={4} md={12} lg={12}>
              <Button
                onClick={() => navigate(-1)}
                loading={isDiagnosticsLoadind}
                className="w-100"
                type="primary"
                size="large"
                style={{
                  background: "#475F7B",
                  color: "#fff",
                  borderColor: "#475F7B",
                }}
              >
                กลับ
              </Button>
            </Col>
          </Row>
        }
        status={visitInfo?.status}
        title={
          <>
            <Row justify="space-between">
              <Col md={12}>ข้อมูลวินิจฉัย</Col>
            </Row>
          </>
        }
        action={
          <>
            {location.pathname ===
            `/doctor/view-askopinion/${params.visitId}` ? (
              <Row gutter={[16, 16]} justify="end">
                <Col flex={2} md={12} lg={12}>
                  <Button
                    onClick={handleCreateMail}
                    type="primary"
                    size="large"
                    // loading
                    className="w-100"
                    style={{
                      backgroundColor: "#4887f2",
                      borderColor: "#4887f2",
                    }}
                  >
                    สร้างจดหมาย
                  </Button>
                </Col>
                <Col flex={2} md={12} lg={12}>
                  <Button
                    type="primary"
                    size="large"
                    className="w-100"
                    // loading={isLoadingPendingDiagnostics}
                    onClick={() => visitReserve()}
                    loading={isDiagnosticsLoadind}
                  >
                    Reserve
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row gutter={[16, 16]} justify="end">
                {visitInfo?.status !== "ASK_OPINION" &&
                  visitInfo?.status !== "SECOND_OPINION" && (
                    <Col flex={4} md={12} lg={12}>
                      <Button
                        onClick={() => askOpinion()}
                        loading={isDiagnosticsLoadind}
                        className="w-100"
                        type="primary"
                        size="large"
                        style={{
                          background: "#475F7B",
                          color: "#fff",
                          borderColor: "#475F7B",
                        }}
                      >
                        ร้องขอ
                      </Button>
                    </Col>
                  )}

                <Col flex={4} md={12} lg={12}>
                  <Button
                    type="primary"
                    size="large"
                    className="w-100"
                    onClick={() => onSubmitForm(1)}
                    loading={isDiagnosticsLoadind}
                  >
                    บันทึก
                  </Button>
                </Col>
                <Col flex={2} md={12} lg={12}>
                  {/* <Button
                    onClick={() => navigate("/doctor/view-list")}
                    type="ghost"
                    size="large"
                    // loading
                    className="w-100"
                    style={{ backgroundColor: "#fff" }}
                  >
                    ยกเลิก
                  </Button> */}
                  <Button
                    onClick={handleCreateMail}
                    type="primary"
                    size="large"
                    // loading
                    className="w-100"
                    style={{
                      backgroundColor: "#4887f2",
                      borderColor: "#4887f2",
                    }}
                  >
                    สร้างจดหมาย
                  </Button>
                </Col>
                <Col flex={2} md={12} lg={12}>
                  <Button
                    type="primary"
                    size="large"
                    className="w-100"
                    style={{
                      background: "#097EF8",
                      color: "#fff",
                      borderColor: "#097EF8",
                    }}
                    // loading={isLoadingPendingDiagnostics}
                    onClick={() => onSubmitForm(2)}
                    loading={isDiagnosticsLoadind}
                  >
                    ส่ง
                  </Button>
                </Col>
              </Row>
            )}
          </>
        }
      />
      <Row gutter={[16, 16]}>
        {/* <Col xs={24} lg={16} xxl={18}> */}
        <Col xs={24}>
          <PagePaper>
            <Skeleton active loading={isLoading}>
              <Form
                scrollToFirstError
                requiredMark="optional"
                form={form}
                size="middle"
                layout="vertical"
                className="FormUpload"
                // initialValues={}
                // onFieldsChange={(changedFields: any[], allFields: any[]) => {
                //   console.log("allFields", allFields);
                //   console.log("changedFields", changedFields);
                // }}
                onFinish={valueSubmit === true ? onFinish : onReserveDoctorForm}
              >
                <PatientStatus
                  visitInfo={visitInfo}
                  testprops={""}
                  onDownload={onDownload}
                  id={id}
                  doctorInfo={dataDoctor}
                />
                <Divider />
                <div className="FormDoctor">
                  {/* <UploadImageFormItem diagnostPage={true} /> */}
                  {/* <Status /> */}
                  {/* <ShowAI /> */}
                  <DoctorDiagnose
                    dataForm={form}
                    visitFiles={visitFiles}
                    studyId={studyId}
                    visitInfo={visitInfo}
                  />
                </div>
              </Form>
            </Skeleton>
          </PagePaper>
        </Col>
        {/* <Col xs={24} lg={8} xxl={6}>
          <PagePaper>
            <Skeleton active loading={isLoading}>
              <PatientStatus
                visitInfo={visitInfo}
                testprops={""}
                onDownload={onDownload}
                id={id}
                imgProfile={image}
                formdoctor={true}
              />
              <br />
              <VisitViewInfo initialValues={visitInfo} />
            </Skeleton>
          </PagePaper>
        </Col> */}
      </Row>
    </>
  );
};

export default FormDoctor;

interface DataItem {
  id: number;
  name: string;
  keyName: string;
  fileUrl: string;
}

interface TransformedData {
  [key: string]: DataItem[];
}

function transformData(data: DataItem[]): TransformedData {
  const result: TransformedData = {};

  data.forEach((item) => {
    const keyName = item.keyName;
    if (!result[keyName]) {
      result[keyName] = [];
    }

    const transformedItem = {
      id: item.id,
      name: item.name,
      keyName: item.keyName,
      fileUrl: item.fileUrl,
    };

    result[keyName].push(transformedItem);
  });

  return result;
}
