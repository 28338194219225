import { Fetch } from "@Utils/fetch/fetch";

export interface ChartYear {
  chartYear: number;
}
export const GetDashboardAllAPI = async <T = any>(
  token: string,
  chartyear: ChartYear
) => {
  const res = await Fetch<T>({
    params: chartyear,
    token: token,
    method: "GET",
    path: `/v1/dashboard`,
  });
  return res;
};

export const GetYeatboardAllAPI = async <T = any>(token: string) => {
  const res = await Fetch<T>({
    token: token,
    method: "GET",
    path: `/v1/dashboard/year`,
  });
  return res;
};
